import React, { useState } from 'react';

const UserPreferenceGroup = ({ 
  group, 
  groupIndex, 
  preferences, 
  onPreferenceValueSelect, 
  onSplitGroup, 
  onMergeGroup,
  totalGroups 
}) => {
  const [expandedPreference, setExpandedPreference] = useState(null);

  const handlePreferenceExpand = (uuid) => {
    setExpandedPreference(expandedPreference === uuid ? null : uuid);
  };

  const renderCost = (value) => {
    if (!value.hasAdditionalCharges) return null;
    return (
      <span className="text-orange-600 ml-2">
        +${parseFloat(value.additionalChargesAmount).toFixed(2)}
      </span>
    );
  };

  return (
    <div className="preference-group bg-gray-50 rounded-lg p-4 mb-4">
      <h3 className="text-lg font-semibold mb-4">Quantity: {group.quantity}</h3>
      
      {group.quantity > 1 || groupIndex < totalGroups - 1 ? (
        <div className="flex space-x-4 mb-6">
          {group.quantity > 1 && (
            <button onClick={() => onSplitGroup(groupIndex)} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
              Split Item
            </button>
          )}
          {groupIndex < totalGroups - 1 && (
            <button onClick={() => onMergeGroup(groupIndex)} className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600">
              Merge with Next
            </button>
          )}
        </div>
      ) : null}

      {preferences.map((item) => (
        <div key={item.uuid} className="preference-item border rounded-lg mb-3 overflow-hidden">
          <div 
            className="preference-header flex justify-between items-center p-4 bg-white cursor-pointer hover:bg-gray-50"
            onClick={() => handlePreferenceExpand(item.uuid)}
          >
            <span className="font-medium">{item.name}</span>
            <span>{expandedPreference === item.uuid ? '▲' : '▼'}</span>
          </div>
          
          {group.preferences[item.uuid] && (
            <div className="selected-value bg-blue-100 p-3">
              <span>Selected: {item.values.find(v => v.uuid === group.preferences[item.uuid])?.value}</span>
              {renderCost(item.values.find(v => v.uuid === group.preferences[item.uuid]))}
            </div>
          )}
          
          {expandedPreference === item.uuid && (
            <div className="preference-values p-2">
              {item.values.map(value => (
                <div 
                  key={value.uuid}
                  className={`flex justify-between items-center p-3 cursor-pointer hover:bg-gray-100 ${
                    group.preferences[item.uuid] === value.uuid ? 'bg-blue-50' : ''
                  }`}
                  onClick={() => onPreferenceValueSelect(groupIndex, item.uuid, value.uuid)}
                >
                  <div className="flex items-center">
                    <span>{value.value}</span>
                    {renderCost(value)}
                  </div>
                  {group.preferences[item.uuid] === value.uuid && (
                    <span className="text-green-600">✓</span>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default UserPreferenceGroup;