import React, { useState, useRef } from 'react';
import { Loader2, ArrowLeft, Mail} from 'lucide-react';
import { auth } from '../../../firebaseConfig';
import { createUserWithEmailAndPassword, PhoneAuthProvider, signInWithCredential } from "firebase/auth";
import { useAuth } from '../../../components/Common/InitialGears/AuthContext';
import { useNavigate } from 'react-router-dom';
import Config from '../../../config';
import { refreshAuthToken } from './authLoginUtils';
import ReferralRequestPanel from '../../WebPage/ReferralRequested';

const Register = ({ onClose, setLoginType }) => {
  const [step, setStep] = useState(1);
  const [showReferralRequest, setShowReferralRequest] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); 
  const [userType, setUserType] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();
  const otpInputRefs = useRef([]);

  const formatFirebaseError = (error) => {
    switch (error.code) {
      case 'auth/email-already-in-use':
        return 'This email is already registered. Please try signing in instead.';
      case 'auth/weak-password':
        return 'Password must be at least 6 characters long.';
      case 'auth/invalid-email':
        return 'Please enter a valid email address.';
      default:
        return error.message || 'An unexpected error occurred. Please try again.';
    }
  };

  const validateName = (name) => {
    const nameRegex = /^[A-Za-z\s-']+$/;
    return nameRegex.test(name);
  };

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    if (value === '' || validateName(value)) {
      setFirstName(value);
      setErrorMessage('');
    }
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    if (value === '' || validateName(value)) {
      setLastName(value);
      setErrorMessage('');
    }
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    if (!userType) {
      setErrorMessage("Please select an account type");
      return;
    }

    if (!validatePassword(password)) {
      setErrorMessage("Password must be at least 6 characters long");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      let idToken = await userCredential.user.getIdToken();
      const uid = userCredential.user.uid;
      const response = await registerNewUser(email, phone, idToken);
      const responseData = await response.json();

      idToken = await refreshAuthToken(userCredential.user);
      await setLoginParams(
        idToken,
        uid,
        email,
        responseData.isBacksAppStaff || false,
        responseData.isBacksAppManager || false,
        firstName,
        responseData.userVerified || false
      );

      navigate('/dashboard');
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setErrorMessage("Server error. Please try again later.");
      } else if (error.code && error.code.startsWith('auth/')) {
        setErrorMessage(formatFirebaseError(error));
      } else {
        setErrorMessage(error.message || "Failed to register. Please try again.");
      }
    }
  };

  const registerNewUser = async (email, phone, idToken) => {
    const body = JSON.stringify({
      phone_number: phone,
      country_code: "+1",
      first_name: firstName,
      last_name: lastName,
      email: email,
      referral_secret: referralCode,
      user_type: userType,  
    });

    const response = await fetch(`${Config.apiUrl}registerNewUser?loginEmail=true`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': idToken
      },
      body: body,
      mode: 'cors',
      credentials: 'omit'
    });

    if (!response.ok) {
      if (response.status === 400) {
        setLoading(true);
        setErrorMessage("Referral Code not valid. \nRedirecting to request a code");
        await new Promise(resolve => setTimeout(resolve, 5000)); 
        setLoading(false);
        setShowReferralRequest(true);
        return;
      }
      throw new Error(response.status === 404 ? 'Referral Code Not acceptable' : `HTTP error! status: ${response.status}`);
    }

    return response;
  };

  const setLoginParams = async (accessToken, uid, email, isBacksAppStaff, isBacksAppManager, firstName, userVerified) => {
    const success = await login(accessToken, uid, email, isBacksAppStaff, isBacksAppManager, firstName, userVerified);
    if (!success) {
      throw new Error("Failed to set login parameters");
    }
  };

  const renderStep = () => {
    const commonInputClasses = "w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:border-amber-500 focus:ring-1 focus:ring-amber-500 transition-colors";
    const radioGroupClasses = "flex flex-col gap-3 mt-2";
    const radioLabelClasses = "flex items-center p-4 border rounded-lg cursor-pointer transition-all hover:border-amber-500";
    const radioInputClasses = "w-4 h-4 text-amber-600 border-gray-300 focus:ring-amber-500";
    
    switch (step) {
      case 1:
        return (
          <div className="space-y-6">
            <div className="text-center">
              <p className="text-sm text-gray-600 mb-4">Enter Referral Code</p>
            </div>
            <button onClick={() => setShowReferralRequest(true)} className="text-sm text-amber-600 hover:text-amber-700 underline mb-4" >
                Don't have a referral code? Request one
            </button>
            <div className="flex justify-between gap-2">
              {[0, 1, 2, 3, 4, 5].map((index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={referralCode[index] || ''}
                  onChange={(e) => {
                    const newCode = referralCode.split('');
                    newCode[index] = e.target.value.toUpperCase();
                    setReferralCode(newCode.join(''));
                    if (e.target.value && index < 5) {
                      otpInputRefs.current[index + 1]?.focus();
                    }
                  }}
                  ref={(el) => (otpInputRefs.current[index] = el)}
                  className="w-12 h-12 text-center bg-white border border-gray-300 rounded-lg text-gray-900 focus:outline-none focus:border-amber-500 focus:ring-1 focus:ring-amber-500 transition-colors"
                />
              ))}
            </div>
            
            <button  onClick={() => setStep(2)}  disabled={referralCode.length !== 6} className={`w-full py-3 rounded-lg font-medium transition-colors ${referralCode.length === 6 ? 'bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] text-white hover:opacity-90' : 'bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] text-white hover:opacity-90 cursor-not-allowed'}`}>            
              Next
            </button>
          </div>
        );
      case 2:
        const validateStep2 = () => {
          if (!firstName.trim()) {
            setErrorMessage("Please enter your first name");
            return false;
          }
          if (!validateName(firstName)) {
            setErrorMessage("First name should only contain letters");
            return false;
          }
          if (!lastName.trim()) {
            setErrorMessage("Please enter your last name");
            return false;
          }
          if (!validateName(lastName)) {
            setErrorMessage("Last name should only contain letters");
            return false;
          }
          if (!userType) {
            setErrorMessage("Please select an account type");
            return false;
          }
          return true;
        };

        return (
          <div className="space-y-6">
            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                First Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={firstName}
                onChange={handleFirstNameChange}              
                placeholder="Enter your first name"
                className={`${commonInputClasses} ${!firstName.trim() && errorMessage ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : ''}`}
              />
            </div>
            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Last Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={lastName}
                onChange={handleLastNameChange}
                placeholder="Enter your last name"
                className={`${commonInputClasses} ${!lastName.trim() && errorMessage ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : ''}`}
              />
            </div>
            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Account Type <span className="text-red-500">*</span>
              </label>
              <div className={radioGroupClasses}>
                <label className={`${radioLabelClasses} ${userType === 'personal' ? 'border-amber-500 bg-amber-50' : 'border-gray-300'}`}>
                  <input
                    type="radio"
                    name="userType"
                    value="personal"
                    checked={userType === 'personal'}
                    onChange={(e) => {
                      setUserType(e.target.value);
                      setErrorMessage('');
                    }}
                    className={radioInputClasses}
                  />
                  <span className="ml-3">Personal Use</span>
                </label>
                <label className={`${radioLabelClasses} ${userType === 'business' ? 'border-amber-500 bg-amber-50' : 'border-gray-300'}`}>
                  <input
                    type="radio"
                    name="userType"
                    value="business"
                    checked={userType === 'business'}
                    onChange={(e) => setUserType(e.target.value)}
                    className={radioInputClasses}
                  />
                  <span className="ml-3">Business Use</span>
                </label> 
              </div>
            </div>
            {step > 1 && (
              <button 
                onClick={() => {
                  setStep(step - 1);
                  setErrorMessage('');
                }}
                className="flex items-center gap-2 text-gray-500 hover:text-gray-700 p-2 hover:bg-gray-100 rounded-lg transition-colors"
              >
                <ArrowLeft className="w-5 h-5" />
                <span>Go to Previous</span>
              </button>
            )}            
            <button 
              onClick={() => { 
                if (validateStep2()) { 
                  setStep(3); 
                  setErrorMessage(''); 
                } 
              }}  
              disabled={referralCode.length !== 6} 
              className={`w-full py-3 rounded-lg font-medium transition-colors ${referralCode.length === 6 ? 'bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] text-white hover:opacity-90' : 'bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] text-white hover:opacity-90 cursor-not-allowed'}`}
            >            
              Next
            </button>
          </div>
        );
      case 3:
        return (
          <div className="space-y-6">
            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className={commonInputClasses}
              />
            </div>
            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Create a password"
                className={commonInputClasses}
              />
            </div>
            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">Phone Number</label>
              <input 
                type="tel" 
                value={phone} 
                onChange={(e) => setPhone(e.target.value)} 
                placeholder="Enter your phone number" 
                className={commonInputClasses} 
              />
            </div>
            {step > 1 && (
              <button 
                onClick={() => {
                  setStep(step - 1); 
                  setErrorMessage('');  
                }} 
                className="flex items-center gap-2 text-gray-500 hover:text-gray-700 p-2 hover:bg-gray-100 rounded-lg transition-colors" >
                <ArrowLeft className="w-5 h-5" />
                <span>Go to Previous</span>
              </button>
            )}
            <button onClick={handleRegister} className={`w-full py-3 rounded-lg font-medium transition-colors ${referralCode.length === 6 ? 'bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] text-white hover:opacity-90' : 'bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] text-white hover:opacity-90 cursor-not-allowed'}`}>            
              Create Account
            </button>
          </div>
        );
        default:
        return null;
    }
  };

  return (
    <>    
      {showReferralRequest ? (
        <ReferralRequestPanel onClose={() => setShowReferralRequest(false)} />
      ) : (
        <div className="w-full h-full flex items-center justify-center px-4 py-8">
          <div className="bg-white rounded-xl shadow-lg p-8 w-full max-w-md relative">
            <div className="absolute top-4 right-4">
              <button onClick={() => setLoginType('login')} className="flex items-center gap-2 px-4 py-2 rounded-lg bg-white text-amber-700 hover:bg-amber-50 border border-amber-200 transition-all duration-300" >
                <Mail className="w-5 h-5" />
                <span>Sign In</span>
              </button>
            </div>

            <div className="mt-12">
              <div className="flex justify-between items-center mb-8">
                <div className="flex items-center gap-4">             
                  <h2 className="text-2xl font-bold text-gray-900">
                    Create Account
                    {loading && (
                      <div className="flex justify-center items-center">
                        <Loader2 className="h-8 w-8 animate-spin text-rose-900" />
                      </div>
                    )}
                  </h2>
                </div>
                <button onClick={onClose} className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-full transition-colors">
                  <ArrowLeft className="w-5 h-5" />
                </button>
              </div>

              {errorMessage && (
                <div className="bg-red-50 border border-red-200 text-red-600 text-sm rounded-lg p-4 mb-6 flex items-start">
                  <div className="shrink-0 w-5 h-5 text-red-600 mt-0.5">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">Registration Error</h3>
                    <div className="mt-1 text-sm text-red-700">
                      {errorMessage}
                    </div>
                  </div>
                </div>
              )}

              {renderStep()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Register;