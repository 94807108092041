import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../Common/InitialGears/AuthContext';
import * as style from "../../../GlobalStyles";
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
 

const parsePreferenceValue = (value) => {
  // Check if the value is a string containing JSON-like structure
  if (typeof value === 'string' && 
      (value.includes('hasAdditionalCharges') || 
       (value.startsWith('{') && value.endsWith('}')))) {
    try {
      // Replace single quotes with double quotes to ensure valid JSON
      const parsed = JSON.parse(value.replace(/'/g, '"'));
      return {
        value: parsed.value,
        hasAdditionalCharges: parsed.hasAdditionalCharges || false,
        additionalChargesAmount: parsed.additionalChargesAmount || ''
      };
    } catch (e) {
      // If parsing fails, return the original value as a basic preference
      return { 
        value: value, 
        hasAdditionalCharges: false, 
        additionalChargesAmount: '' 
      };
    }
  }
  // For simple string values
  return { 
    value: value, 
    hasAdditionalCharges: false, 
    additionalChargesAmount: '' 
  };
};

const DefinePreferences = ({ 
  isOpen, 
  onClose, 
  groupId, 
  existingPreferences = [], 
  onSubmit 
}) => {
  const { queryLoginParam, idTokenLoggedIn } = useAuth();
  const [standardPreferences, setStandardPreferences] = useState([]);
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const [newPreference, setNewPreference] = useState({ 
    name: '', 
    values: [''], 
    isMultiple: false,
    hasAdditionalCharges: false,
    additionalChargesAmount: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStandardPreference, setSelectedStandardPreference] = useState('');

  useEffect(() => {
    if (isOpen) {
      fetchStandardPreferences();
      if (existingPreferences.length > 0) {
        const formattedPreferences = existingPreferences.map(pref => {
          // Normalize values to include full preference info
          const normalizedValues = pref.values.map(value => {
            if (typeof value === 'string') {
              return {
                value: value,
                hasAdditionalCharges: false,
                additionalChargesAmount: ''
              };
            }
            return parsePreferenceValue(value.value);
          });

          return {
            name: pref.name,
            isMultiple: pref.is_multiple,
            isStandard: pref.is_standard,
            values: normalizedValues,
            hasAdditionalCharges: pref.hasAdditionalCharges || false,
            additionalChargesAmount: pref.additionalChargesAmount || ''
          };
        });
        setSelectedPreferences(formattedPreferences);
      }
    }
  }, [isOpen, existingPreferences]);

  const fetchStandardPreferences = async () => {
    setIsLoading(true);
    try {
      const standardPreferencesUrl = buildUrl(`getGroupsExistingPreferences/${groupId}/`, queryLoginParam);
      const response = await getApi(standardPreferencesUrl);
      
      const data = response.data; 
  
      // Format standard preferences for dropdown
      const formattedStandardPreferences = Array.isArray(data.standard_preferences) 
        ? data.standard_preferences.map(pref => ({
            uuid: pref.uuid,
            name: pref.name,
            values: pref.values.map(v => ({
              value: v.value,
              hasAdditionalCharges: false,
              additionalChargesAmount: '',
              uuid: v.uuid
            })),
            isMultiple: pref.values.length > 1,
            isStandard: true,
            is_product_specific: pref.is_product_specific || false
          }))
        : [];
  
      // Format existing preferences
      const formattedExistingPreferences = Array.isArray(data.preferences_defined) 
        ? data.preferences_defined.map(pref => {
            // Normalize values to include full preference info
            const normalizedValues = pref.values.map(v => ({
              value: v.value,
              hasAdditionalCharges: v.hasAdditionalCharges || false,
              additionalChargesAmount: v.additionalChargesAmount || '',
              uuid: v.uuid
            }));
  
            return {
              uuid: pref.uuid,
              name: pref.name,
              values: normalizedValues,
              isMultiple: pref.isMultiple || pref.values.length > 1,
              isStandard: true,
              is_product_specific: pref.is_product_specific || false
            };
          })
        : [];
      
      // Set standard preferences for dropdown and existing preferences
      setStandardPreferences(formattedStandardPreferences);
      setSelectedPreferences(formattedExistingPreferences);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStandardPreferenceSelect = (e) => {
    const selectedValue = e.target.value;
    setSelectedStandardPreference(selectedValue);
    
    if (selectedValue) {
      const preference = JSON.parse(selectedValue);
      const formattedPreference = {
        ...preference,
        values: preference.values.map(v => ({
          value: v.value,
          hasAdditionalCharges: false,
          additionalChargesAmount: ''
        })),
        uuid: preference.uuid
      };
      setSelectedPreferences(prev => [...prev, formattedPreference]);
    }
  };

  const handleRemovePreference = (index) => {
    setSelectedPreferences(prev => prev.filter((_, i) => i !== index));
  };

  const handleUpdatePreferenceValue = (prefIndex, valueIndex, field, value) => {
    setSelectedPreferences(prev => {
      const updated = [...prev];
      const updatedValues = [...updated[prefIndex].values];
      
      // If unchecking additional charges, reset the amount
      if (field === 'hasAdditionalCharges' && !value) {
        updatedValues[valueIndex] = {
          ...updatedValues[valueIndex],
          [field]: value,
          additionalChargesAmount: ''
        };
      } else {
        updatedValues[valueIndex] = {
          ...updatedValues[valueIndex],
          [field]: value
        };
      }
      
      updated[prefIndex] = { ...updated[prefIndex], values: updatedValues };
      return updated;
    });
  };

  const handleSubmit = async () => {
    try {
      const formattedPreferences = selectedPreferences.map(pref => {
        // Create a new array to store the formatted values
        const formattedValues = [];
  
        // Process each value
        pref.values.forEach(v => {
          // Create base value object
          const baseValue = {
            value: v.value,
            description: ''
          };
  
          // If the value has additional charges, add charge information
          if (v.hasAdditionalCharges) {
            baseValue.hasAdditionalCharges = true;
            baseValue.additionalChargesAmount = v.additionalChargesAmount;
          }
  
          formattedValues.push(baseValue);
        });
  
        // Return preference object with formatted values
        return {
          name: pref.name,
          values: formattedValues,
          isMultiple: pref.isMultiple,
          isStandard: pref.isStandard,
          is_product_specific: pref.is_product_specific || false
        };
      });
  
      const setPreferencesUrl = buildUrl('setGroupPreferences', queryLoginParam);
      const response = await postApi(setPreferencesUrl, {
        groupId: groupId,
        preferences: formattedPreferences
      });
  
      if (response && response.data) {
        onSubmit(response.data.message);
        onClose();
      }
    } catch (error) {
      setError(error.message || 'An error occurred while saving preferences');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title">Define Group Preferences</h1>
          <button onClick={onClose} className="close-button">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        {isLoading ? (
          <div className="loading">Loading...</div>
        ) : error ? (
          <div className="error">{error}</div>
        ) : (
          <>
          <div className="preferences-container">
            <h3>Standard Preferences</h3>
            {standardPreferences.length > 0 ? (
              <select 
                onChange={handleStandardPreferenceSelect} 
                value={selectedStandardPreference}
              >
                <option value="">Select a standard preference</option>
                {standardPreferences.map((pref, index) => (
                  <option key={index} value={JSON.stringify(pref)}>
                    {pref.name}
                  </option>
                ))}
              </select>
            ) : (
              <p>No standard preferences available.</p>
            )}
          </div>
          <div className="preferences-container">
            <h2>Existing Preferences</h2>
            {selectedPreferences.map((pref, prefIndex) => (
              <div key={prefIndex} className="selected-preference">
                <div className="preference-header">
                  <span className="preference-name">
                    {pref.name} ({pref.isMultiple ? 'Multiple' : 'Single'} Select)
                  </span>
                  <button 
                    onClick={() => handleRemovePreference(prefIndex)} 
                    className="remove-button"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
                <div className="preference-values">
                  {pref.values.map((valueObj, valueIndex) => (
                    <div 
                      key={valueObj.uuid || valueIndex} 
                      className="preference-value-row"
                    >
                      <div className="preference-section">
                        <div className="preference-value">
                          <span className="value-label">{valueObj.value}</span>
                        </div>
                      </div>
                      <div className="value-charges">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            checked={valueObj.hasAdditionalCharges}
                            onChange={(e) => handleUpdatePreferenceValue(
                              prefIndex, 
                              valueIndex, 
                              'hasAdditionalCharges', 
                              e.target.checked
                            )}
                          />
                          Additional Charges
                        </label>
                        {valueObj.hasAdditionalCharges && (
                          <input
                            type="number"
                            step="0.01"
                            value={valueObj.additionalChargesAmount}
                            onChange={(e) => handleUpdatePreferenceValue(
                              prefIndex, 
                              valueIndex, 
                              'additionalChargesAmount', 
                              e.target.value
                            )}
                            placeholder="Amount"
                            className="charge-amount-input"
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}

            <button onClick={handleSubmit} className="submit-button">
              Save Group Preferences
            </button>
          </div>        
        </>
        )}
      </div>        
    </div>
  );
}; 
 
const styles = `
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: ${style.Color.colorLightBeige};
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${style.Color.colorLightBeige};
}

.modal-title {
  font-size: 24px;
  color: ${style.Color.colorRoyalBlueText};
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: ${style.Color.colorDarkGrey};
}

.preferences-container {
  color: ${style.Color.colorDarkGrey};
  background-color: ${style.Color.colorLightestBeige};
}

.preferences-container h2 {
  margin-top: 20px;
  color: ${style.Color.colorPurple};
}

.preferences-container h3 {
  margin-top: 20px;
  color: ${style.Color.colorRoyalBlueText};
}

.preference-section {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.value-label {
  font-weight: 500;
}

.charge-badge {
  background-color: ${style.Color.colorLimeGreen};
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.9em;
  margin-left: 8px;
}

.charge-amount-input.disabled {
  background-color: ${style.Color.colorLightBeige};
  cursor: not-allowed;
  color: ${style.Color.colorDarkGrey};
}

.preference-value {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: ${style.Color.colorWhite};
  border-radius: 3px;
  min-width: 150px;
}

.value-charges {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: auto;
}
.preferences-container select,
.preferences-container input[type="text"],
.preferences-container input[type="number"] {
  padding: 8px;
  border: 1px solid ${style.Color.colorLightBeige};
  border-radius: 8px;
  color: ${style.Color.colorDarkGrey};
}

.selected-preference {
  padding: 15px;
  background-color: ${style.Color.colorLightBeige};
  margin-bottom: 15px;
  border-radius: 4px;
}

.preference-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.preference-value-container {
  margin-bottom: 10px;
}

.preference-value-input {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  width: 100%;
}

.preference-value-input input[type="text"] {
  flex: 2;
}

.charge-amount-input {
  width: 120px !important;
  min-width: 80px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  user-select: none;
}

.cust-preference-name-input {
  flex: 1;
  font-size: 12px;
  font-weight: bold;
  background-color: ${style.Color.colorWhite};
}

.preference-values {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.preference-value-row {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 8px;
  background-color: ${style.Color.colorLightestBeige};
  border-radius: 4px;
}

.preference-value {
  flex: 1;
  padding: 4px 8px;
  background-color: ${style.Color.colorWhite};
  border-radius: 3px;
}

.value-charges {
  display: flex;
  align-items: center;
  gap: 10px;
}

.remove-button,
.add-value-button,
.add-preference-button {
  background-color: ${style.Color.colorLimeGreen};
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.remove-button {
  background-color: ${style.Color.colorRed};
  padding: 4px 8px;
  margin-left: auto;
}

.add-value-button,
.add-preference-button {
  margin: 10px 5px;
}

.submit-button {
  background-color: ${style.Color.colorPurple};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 50%;
  margin: 20px auto;
  display: block;
}

.loading,
.error {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

.error {
  color: ${style.Color.colorRed};
}
`;

// Add styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default DefinePreferences;