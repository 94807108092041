import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUpload, faTrash, faImage, faVideo } from '@fortawesome/free-solid-svg-icons';
import * as style from "../../../GlobalStyles";

// Modal component definition
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div style={modalOverlayStyle}>
      <div style={modalContentStyle}>
        <div style={modalHeaderStyle}>
          <h2 style={modalTitleStyle}>Upload Media</h2>
          <button style={closeButtonStyle} onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

const MediaUploadModal = ({ isOpen, onClose, onSave, currentImages = [], currentVideo = null }) => {
  const [images, setImages] = useState(currentImages);
  const [video, setVideo] = useState(currentVideo);
  const [videoFile, setVideoFile] = useState(currentVideo || null);
  const [error, setError] = useState(''); 

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    if (images.length + files.length > 7) {
      setError('Maximum 7 images allowed');
      return;
    }

    const newImages = files.map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));

    setImages([...images, ...newImages]);
    setError('');
  };

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check file size (max 100MB)
      if (file.size > 100 * 1024 * 1024) {
        setError('Video must be less than 100MB');
        return;
      }
      setVideo({
        file,
        preview: URL.createObjectURL(file)
      });
      setError('');
    }
  };

  const removeImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
  };

  const removeVideo = () => {
    setVideo(null);
    setVideoFile(null);
  };

  const handleSave = () => {
    onSave({ images, video });
    onClose();
  };

  const getVideoPreview = () => {
    if (!video) return null;
    
    // Handle case where video is a new File object
    if (video.file instanceof File) {
      return URL.createObjectURL(video.file);
    }
    
    // Handle case where video is an existing video from backend
    if (video.url) {
      return video.url;
    }
    
    // Handle case where video.preview exists
    if (video.preview) {
      return video.preview;
    }
    
    return null;
  };

  const getVideoType = () => {
    if (!video) return '';
    
    if (video.file instanceof File) {
      return video.file.type;
    }
    
    // For existing videos, default to a common video type if not specified
    return video.type || 'video/mp4';
  };

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div>
        {error && <div style={errorMessageStyle}>{error}</div>}
        <div style={uploadSectionStyle}>
          <h3 style={sectionTitleStyle}>Upload Additional Images (Max 7)</h3>
          <div style={imageGridStyle}>
            {images.map((img, idx) => (
              <div key={idx} style={imageContainerStyle}>
                <img src={img.preview || img.url} alt={`Preview ${idx}`} style={imagePreviewStyle} />
                <button style={removeButtonStyle}  onClick={() => removeImage(idx)}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            ))}
            {images.length < 7 && (
              <label style={uploadButtonStyle}>
                <input type="file" multiple accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }}/>
                <FontAwesomeIcon icon={faImage} style={uploadIconStyle} />
                <span>Add Images</span>
              </label>
            )}
          </div>
        </div>
        
        <div style={uploadSectionStyle}>
          <h3 style={sectionTitleStyle}>Upload Video (Optional)</h3>
          {video ? (
            <div style={videoContainerStyle}>
              <div style={videoPreviewStyle}>
                {getVideoPreview() && (
                    <video style={videoStyle} controls>
                      <source src={getVideoPreview()} type={getVideoType()} />
                      Your browser does not support the video tag.
                    </video>
                  )}
                <button  style={removeButtonStyle}  onClick={removeVideo}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
          ) : (
            <label style={uploadButtonStyle}>
              <input type="file" accept="video/*" onChange={handleVideoUpload} style={{ display: 'none' }}/>
              <FontAwesomeIcon icon={faVideo} style={uploadIconStyle} />
              <span>Add Video</span>
            </label>
          )}
        </div>
        
        <div style={buttonContainerStyle}>
          <button style={saveButtonStyle} onClick={handleSave}>
            Save Media
          </button>
        </div>
      </div>
    </Modal>
  );
};

// Styles
const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalContentStyle = {
  backgroundColor: style.Color.colorWhite,
  borderRadius: '8px',
  padding: '20px',
  width: '90%',
  maxWidth: '800px',
  maxHeight: '90vh',
  overflow: 'auto',
};

const modalHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
};

const modalTitleStyle = {
  fontSize: '24px',
  color: style.Color.colorDarkBlue,
  margin: 0,
};

const closeButtonStyle = {
  background: 'none',
  border: 'none',
  fontSize: '24px',
  cursor: 'pointer',
  color: style.Color.colorDarkGrey,
};

const errorMessageStyle = {
  backgroundColor: '#f8d7da',
  color: '#721c24',
  padding: '10px',
  borderRadius: '4px',
  marginBottom: '15px',
};

const uploadSectionStyle = {
  marginBottom: '20px',
};

const sectionTitleStyle = {
  fontSize: '18px',
  color: style.Color.colorDarkGrey,
  marginBottom: '10px',
};

const imageGridStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
  gap: '10px',
};

const imageContainerStyle = {
  position: 'relative',
  aspectRatio: '1',
  borderRadius: '4px',
  overflow: 'hidden',
};

const imagePreviewStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
};

const videoContainerStyle = {
  marginTop: '10px',
};

const videoPreviewStyle = {
  position: 'relative',
  width: '100%',
  maxHeight: '300px',
  borderRadius: '4px',
  overflow: 'hidden',
};

const videoStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
};

const uploadButtonStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '150px',
  border: '2px dashed #ccc',
  borderRadius: '4px',
  cursor: 'pointer',
  backgroundColor: '#f8f9fa',
  transition: 'all 0.3s ease',
};

const uploadIconStyle = {
  fontSize: '24px',
  marginBottom: '8px',
  color: style.Color.colorDarkGrey,
};

const removeButtonStyle = {
  position: 'absolute',
  top: '5px',
  right: '5px',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  border: 'none',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '20px',
};

const saveButtonStyle = {
  backgroundColor: style.Color.colorMintGreen,
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  padding: '10px 20px',
  cursor: 'pointer',
  fontWeight: 'bold',
};

export default MediaUploadModal;