import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import VerificationModal from '../Verification/VerificationModal';  
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import { useAuth } from '../../Common/InitialGears/AuthContext';
import { Color } from "../../../GlobalStyles";
import {TopMenuBar} from '../DashbordComponents/TopMenuBar';

const styles = {
    settingsContainer: {
        margin: '0 auto',
        padding: '20px',
        backgroundColor: Color.colorLightBeige,
        borderRadius: '10px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    settingsHeader: {
        fontSize: '28px',
        fontWeight: 'bold',
        color: Color.colorRoyalBlueText,
        marginBottom: '20px',
        textAlign: 'center',
    },
    settingsSection: {
        marginBottom: '30px',
        marginLeft: '500px',
        marginTop: '50px',
        maxWidth: '600px',
        backgroundColor: Color.colorWhite,
        padding: '20px',
        borderRadius: '8px', 
        alignItems: 'center',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    verifiedText: {
        color: Color.colorGreen,
        fontWeight: 'bold',
        fontSize: '18px',
    },
    verifyButton: {
        padding: '10px 20px',
        backgroundColor: Color.colorPurple,
        color: Color.colorWhite,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    updateCardButton: {
        padding: '10px 20px',
        backgroundColor: Color.colorPurple,
        color: Color.colorWhite,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    toggleContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'space-between',
    },
    toggleInput: {
        display: 'none',
    },
    toggleSlider: {
        position: 'relative',
        display: 'inline-block',
        width: '60px',
        height: '34px',
        backgroundColor: Color.colorPurple,
        borderRadius: '34px',
        transition: '0.4s',
        cursor: 'pointer',
    },
    toggleSliderChecked: {
        backgroundColor: Color.colorPurple,
    },
    toggleSliderBefore: {
        content: '""',
        position: 'absolute',
        height: '26px',
        width: '26px',
        left: '4px',
        bottom: '4px',
        backgroundColor: Color.colorWhite,
        borderRadius: '50%',
        transition: '0.4s',
    },
    toggleSliderCheckedBefore: {
        transform: 'translateX(26px)',
    },
    errorMessage: {
        color: Color.colorRed,
        fontSize: '16px',
        marginTop: '10px',
        textAlign: 'center',
    },
};

const UserSettingsPage = () => {
    const { queryLoginParam, userVerified ,updateAuthState} = useAuth(); 
    const navigate = useNavigate();
    const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [userData, setUserData] = useState(null);
    const [dontSendMessages, setDontSendMessages] = useState(false); 
    const [error, setError] = useState('');
    const [retryCount, setRetryCount] = useState(0);
    const [nextRetryTime, setNextRetryTime] = useState(null);
    const [verificationError, setVerificationError] = useState('');
    
    useEffect(() => { 
        fetchUserSettings();
    }, []);

    const fetchUserSettings = async () => {
        if (nextRetryTime && new Date() < nextRetryTime) {
            const timeLeft = Math.ceil((nextRetryTime - new Date()) / (1000 * 60 * 60));
            setError(`Too many failed attempts. Please try again in ${timeLeft} hours.`);
            return;
        }

        try {
            const settingsUrl = buildUrl('userSettings', `${queryLoginParam}`);
            const response = await getApi(settingsUrl);
            if (response){
                if(response.status==400){ 
                    handleError();
                }else{
                    resetErrorState();
                    setIsVerified(response.data.isVerified);
                    setUserData(response.data);
                    setDontSendMessages(response.data.dontSendMessages);
                }
            }
        } catch (error) { 
            handleError();
        }
    };

    const handleError = () => {
        const newRetryCount = retryCount + 1;
        setRetryCount(newRetryCount);
        if (newRetryCount >= 3) {
            const cooldownTime = new Date();
            cooldownTime.setHours(cooldownTime.getHours() + 40);
            setNextRetryTime(cooldownTime);
            setError(`Too many failed attempts. Please try again in 4 hours.`);
        } else {
            setError(`Something went wrong. Please try to verify again. (Attempt ${newRetryCount} of 3)`);
        }
    };

    const resetErrorState = () => {
        setError('');
        setVerificationError('');
        setRetryCount(0);
        setNextRetryTime(null);
    };

    const handleVerificationSuccess = (success) => {
        if (success) {
            setUserData(prevData => ({ ...prevData, verified: true }));
            updateAuthState({ userVerified: true }); 
            localStorage.setItem('userVerified', 'true');
            resetErrorState(); 
        } else {
            handleError();
        }
        setIsVerificationModalOpen(false);
    };

    const toggleDontSendMessages = async () => {
        const newValue = !dontSendMessages;
        try {
            const dontSendMessagesUrl = buildUrl('updateNotificationPrefernces', `${queryLoginParam}`);
            const requestBody =  { dontSendMessages: newValue };
            const response = await postApi(dontSendMessagesUrl, requestBody); 
            if(response){
                setUserData(prevData => ({ ...prevData, do_not_disturb: newValue }));
                resetErrorState();
            }
        } catch (error) {
            //console.error('Error updating message settings:', error); 
            setDontSendMessages(!newValue);
            handleError();
        }
    };

    return (
        <div >
        <TopMenuBar onBackClick={() => navigate(-1)} showHomeButton={true} title={'My Preferences'} showSearch={false} showAddress={false} showMenuButton={true} /> 
        <div style={styles.settingsContainer}>     
            <div style={styles.settingsSection}>
                <h2>Account Verification</h2>
                {error && (
                <div>
                    <p style={styles.errorMessage}>{error}</p>
                </div>
            )}
            
            {userData && userData.verified ? (
                <div>
                            <p style={styles.verifiedText}>Your account is verified ✓</p>
                            <button style={styles.updateCardButton} onClick={() => setIsVerificationModalOpen(true)}>
                                Update my Card details
                            </button>
                </div>
                ) : (
                    retryCount < 3 && nextRetryTime === null && (
                            <button style={styles.verifyButton} onClick={() => setIsVerificationModalOpen(true)}>
                                Verify Account
                            </button>
                        )
                )}
            </div>

            <div style={styles.settingsSection}>
                <h2>Notification Preferences</h2>
                <label style={styles.toggleContainer}>
                    <span>Don't Send Messages to phone</span>
                    <input type="checkbox" checked={dontSendMessages} onChange={toggleDontSendMessages} style={styles.toggleInput} disabled={nextRetryTime !== null}/>
                    <span style={{...styles.toggleSlider,...(dontSendMessages ? styles.toggleSliderChecked : {}),}}>
                        <span style={{...styles.toggleSliderBefore,...(dontSendMessages ? styles.toggleSliderCheckedBefore : {}),}}/>
                    </span>
                </label>
            </div>

            <VerificationModal isOpen={isVerificationModalOpen} onClose={() => {setIsVerificationModalOpen(false);setVerificationError('')}} onVerificationSuccess={handleVerificationSuccess}/>
        </div>
        </div>
    );
};

export default UserSettingsPage;