import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp, Package, Truck, FileText, MapPin, Clock } from 'lucide-react';
import { TopMenuBar } from '../Buyers/DashbordComponents/TopMenuBar';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Common/InitialGears/AuthContext';
import { getApi, buildUrl } from "../Common/BackendCalls/apiUtils";
import { orderTrackingStyles, combineStyles } from './OrderTrackingStyles';

const UserOrderTracking = () => {
  const navigate = useNavigate();
  const { queryLoginParam } = useAuth();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [expandedSection, setExpandedSection] = useState(null);

  useEffect(() => {
    fetchMyOrders();
  }, []);

  const fetchMyOrders = async () => {
    try {
      const url = buildUrl('myGroupWishes', `${queryLoginParam}&type=orders`);
      const response = await getApi(url);
      
      if (response.ok) {
        setOrders(response.data.data.wishes || []);
      } else {
        setError('Failed to fetch orders');
      }
    } catch (error) {
      setError('Failed to fetch orders');
      //console.error("Error fetching orders:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getProductNames = (order) => {
    if (!order.shipping_details || order.shipping_details.length === 0) return '';
    
    const products = order.shipping_details[0].product;
    if (!products || products.length === 0) return '';
    
    return products.map(p => p.name).join(', ');
  };

  const toggleOrderExpansion = (orderId) => {
    setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
    setExpandedSection(null);
  };

  const toggleSection = (sectionName) => {
    setExpandedSection(expandedSection === sectionName ? null : sectionName);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  if (error) {
    return (
      <div style={orderTrackingStyles.errorMessage}>
        {error}
      </div>
    );
  }

  if (isLoading) {
    return (
      <div style={orderTrackingStyles.loadingContainer}>
        Loading your orders...
      </div>
    );
  }

  return (
    <>
      <TopMenuBar 
        showBackButton={false} 
        showHomeButton={true} 
        showMenuButton={true} 
        onBackClick={() => navigate(-1)} 
        title="My Orders" 
        showSearch={false} 
        showAddress={false} 
      />
      
      <div style={orderTrackingStyles.orderTrackingContainer}>
        {isLoading ? (
          <div style={orderTrackingStyles.loadingContainer}>
            Loading your orders...
          </div>
        ) : error ? (
          <div style={orderTrackingStyles.errorMessage}>
            {error}
          </div>
        ) : (
          <div style={orderTrackingStyles.ordersContainer}>
            {orders.length === 0 ? (
              <div style={orderTrackingStyles.emptyOrdersContainer}>
                <Package style={orderTrackingStyles.emptyOrdersIcon} size={48} />
                <p style={orderTrackingStyles.emptyOrdersText}>No orders found</p>
              </div>
              ) : (
                orders.length === 0 ? (
                <div style={orderTrackingStyles.emptyOrdersContainer}>
                  <Package style={orderTrackingStyles.emptyOrdersIcon} size={48} />
                  <p style={orderTrackingStyles.emptyOrdersText}>No orders found</p>
                </div>
              ) : (
                orders.map((order) => (
                  <div key={order.wish_id} style={orderTrackingStyles.orderCard}>
                    <div 
                      style={combineStyles(
                        orderTrackingStyles.orderHeader,
                        expandedOrderId === order.wish_id && orderTrackingStyles.orderHeaderExpanded
                      )}
                      onClick={() => toggleOrderExpansion(order.wish_id)}
                    >
                      <div style={orderTrackingStyles.orderHeaderContent}>
                        <h3 style={orderTrackingStyles.orderTitle}>
                          {getProductNames(order)}
                        </h3>
                        <p style={orderTrackingStyles.productName}>
                          Estimated Delivery on {order.shipping_details[0]?.estimated_delivery ? 
                              formatDate(order.shipping_details[0].estimated_delivery) : 
                              'Date not available'}
                        </p>
                      </div>
                      {expandedOrderId === order.wish_id ? 
                        <ChevronUp style={orderTrackingStyles.orderHeaderIcon} /> : 
                        <ChevronDown style={orderTrackingStyles.orderHeaderIcon} />
                      }
                    </div>

                    {expandedOrderId === order.wish_id && order.shipping_details && 
                      order.shipping_details.map((shipment, index) => (
                      <div key={shipment.shipment_id} style={orderTrackingStyles.shipmentContainer}>
                        {/* Shipment Header */}
                        <div style={orderTrackingStyles.shipmentHeader}>
                          <div style={orderTrackingStyles.shipmentHeaderContent}>
                            <div style={orderTrackingStyles.shipmentHeaderInfo}>
                              <Truck style={orderTrackingStyles.shipmentIcon} size={20} />
                              <span style={orderTrackingStyles.shipmentTitle}>
                                Shipment {index + 1}
                              </span>
                            </div>
                            <span style={orderTrackingStyles.shipmentCarrier}>
                              {shipment.carrier} - {shipment.shipping_method}
                            </span>
                          </div>
                        </div>

                        {/* Tracking Information */}
                        <div style={orderTrackingStyles.trackingContainer}>
                          <div style={orderTrackingStyles.statusContainer}>
                            <div style={orderTrackingStyles.statusHeader}>
                              <Clock style={orderTrackingStyles.statusIcon} size={16} />
                              <span style={orderTrackingStyles.statusTitle}>
                                Current Status
                              </span>
                            </div>
                            <div style={orderTrackingStyles.statusContent}>
                              <p style={orderTrackingStyles.statusDescription}>
                                {shipment.current_status.description}
                              </p>
                              <p style={orderTrackingStyles.statusTimestamp}>
                                Last updated: {formatDate(shipment.current_status.last_updated)}
                              </p>
                            </div>
                          </div>

                          {/* Tracking History */}
                          {shipment.tracking_history && (
                            <div style={orderTrackingStyles.historyContainer}>
                              <div style={orderTrackingStyles.historyHeader}>
                                <MapPin style={orderTrackingStyles.historyIcon} size={16} />
                                <span style={orderTrackingStyles.historyTitle}>
                                  Tracking History
                                </span>
                              </div>
                              <div style={orderTrackingStyles.historyContent}>
                                {shipment.tracking_history.map((event, idx) => (
                                  <div key={idx} style={orderTrackingStyles.historyEvent}>
                                    <p style={orderTrackingStyles.eventDescription}>
                                      {event.description}
                                    </p>
                                    <p style={orderTrackingStyles.eventDetails}>
                                      {event.location} - {formatDate(event.timestamp)}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}

                          {/* Invoice Information */}
                          {shipment.invoice && (
                            <div style={orderTrackingStyles.invoiceContainer}>
                              <div 
                                style={orderTrackingStyles.invoiceHeader}
                                onClick={() => toggleSection('invoice')}
                              >
                                <div style={orderTrackingStyles.invoiceHeaderContent}>
                                  <FileText style={orderTrackingStyles.invoiceIcon} size={16} />
                                  <span style={orderTrackingStyles.invoiceTitle}>
                                    Invoice Details
                                  </span>
                                </div>
                                {expandedSection === 'invoice' ? 
                                  <ChevronUp size={16} /> : 
                                  <ChevronDown size={16} />
                                }
                              </div>
                              
                              {expandedSection === 'invoice' && (
                                <div style={orderTrackingStyles.invoiceContent}>
                                  <div style={orderTrackingStyles.invoiceRow}>
                                    <span style={orderTrackingStyles.invoiceLabel}>
                                      Invoice Number:
                                    </span>
                                    <span style={orderTrackingStyles.invoiceValue}>
                                      {shipment.invoice.invoice_number}
                                    </span>
                                  </div>
                                  <div style={orderTrackingStyles.invoiceRow}>
                                    <span style={orderTrackingStyles.invoiceLabel}>
                                      Date:
                                    </span>
                                    <span style={orderTrackingStyles.invoiceValue}>
                                      {formatDate(shipment.invoice.date)}
                                    </span>
                                  </div>
                                  <div style={orderTrackingStyles.invoiceItems}>
                                    {shipment.invoice.items.map((item, idx) => (
                                      <div key={idx} style={orderTrackingStyles.invoiceItem}>
                                        <div style={orderTrackingStyles.itemRow}>
                                          <span style={orderTrackingStyles.itemDescription}>
                                            {item.description}
                                          </span>
                                          <span style={orderTrackingStyles.itemTotal}>
                                            {formatCurrency(item.item_total)}
                                          </span>
                                        </div>
                                        <div style={orderTrackingStyles.itemDetails}>
                                          Qty: {item.quantity} × {formatCurrency(item.unit_price)}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <div style={orderTrackingStyles.invoiceSummary}>
                                    <div style={orderTrackingStyles.summaryRow}>
                                      <span style={orderTrackingStyles.summaryLabel}>
                                        Subtotal:
                                      </span>
                                      <span style={orderTrackingStyles.summaryValue}>
                                        {formatCurrency(shipment.invoice.subtotal)}
                                      </span>
                                    </div>
                                    <div style={orderTrackingStyles.summaryRow}>
                                      <span style={orderTrackingStyles.summaryLabel}>
                                        Tax:
                                      </span>
                                      <span style={orderTrackingStyles.summaryValue}>
                                        {formatCurrency(shipment.invoice.tax_amount)}
                                      </span>
                                    </div>
                                    <div style={combineStyles(
                                      orderTrackingStyles.summaryRow,
                                      orderTrackingStyles.summaryTotal
                                    )}>
                                      <span style={orderTrackingStyles.summaryTotalLabel}>
                                        Total:
                                      </span>
                                      <span style={orderTrackingStyles.summaryTotalValue}>
                                        {formatCurrency(shipment.invoice.total_amount)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              ))}
            </div>
        )}
      </div>
    </>
  );
};

export default UserOrderTracking;