import React, { useState, useEffect,useRef,useCallback } from 'react';
import sceneImage from '../../assets/images/scene1.png';
import bannerImage from '../../assets/images/banner1.png';
import {Users, Star,  Heart, ShoppingCart, TrendingUp } from 'lucide-react';
import Config from "../../config";  
import CategoriesGrid from './CategoriesGrid';
import HowItWorksSection from './HowItWorksSection';
import TestimonialsSection from './TestimonialsSection';
import ReferralRequestPanel from './ReferralRequested'; 
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import Login from '../Common/InitialGears/Login';
import Register from '../Common/InitialGears/Register'; 

const colorStyles = {
    primary: {
      gradient: 'from-amber-600 via-rose-800 to-[#80666a]',
      text: 'text-amber-700',
      hover: 'hover:text-rose-500',
      background: 'bg-gradient-to-r from-[#80666a] via-rose-500 to-amber-500',
      button: 'bg-gradient-to-r from-[#80666a] to-rose-800 hover:from-[#80666a] hover:to-rose-800',
      buttonText: 'text-white',
      secondaryButton: 'border-rose-300 text-amber-700 hover:bg-amber-50',
      icon: 'text-amber-600 group-hover:text-rose-500',
    }
  };

  const TransitionLayout = ({ children, activePanel, setActivePanel }) => {
    const renderPanel = () => {
      switch (activePanel?.type) {
        case 'login':
          return <Login 
            onClose={() => setActivePanel(null)} 
            setLoginType={(type) => {
              if (type === 'login') {
                setActivePanel({ type: 'login', subType: 'email' });
              } else {
                setActivePanel({ type: type });
              }
            }}
            loginType={activePanel.subType || 'email'}
          />;
        case 'register':
          return <Register 
            onClose={() => setActivePanel(null)} 
            setLoginType={(type) => setActivePanel({ type: type })}
          />;
        case 'referral':
          return <ReferralRequestPanel onClose={() => setActivePanel(null)} />;
        default:
          return null;
      }
    };
  
    const showSidePanel = activePanel !== null;
  
    return (
      <div className="flex min-h-screen w-full">
        <div className={`transition-all duration-500 ease-in-out ${
          showSidePanel ? 'w-2/3' : 'w-full'
        }`}>
          {children}
        </div>
  
        <div className={`fixed right-0 top-0 h-full transition-all duration-500 transform 
          ${showSidePanel ? 'w-1/3 translate-x-0' : 'w-0 translate-x-full'}
          bg-gradient-to-b from-gray-50 via-white to-gray-50 shadow-2xl overflow-hidden`}>
          <div className="h-full overflow-y-auto">
            {renderPanel()}
          </div>
        </div>
      </div>
    );
  };

const LandingPage = () => {
  const navigate = useNavigate(); 
  const [activePanel, setActivePanel] = useState(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0); 
  const [isIntersecting, setIsIntersecting] = useState({});
  const dealsRef = useRef(null); 
  const [categories, setCategories] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);  
  const [showLogin, setShowLogin] = useState(false);
  const [loginType, setLoginType] = useState('email'); 
  const [deals, setDeals] = useState([]); 
  const [showReferralModal, setShowReferralModal] = useState(false); 
  const authSectionRef = useRef(null);
  const [showReferral, setShowReferral] = useState(false);

  //Footer refs 
  const howItWorksRef = useRef(null);
  const testimonialsRef = useRef(null); 
  const categoriesRef = useRef(null); 

  // Smooth scroll handler
  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  // Footer navigation handler
  const handleFooterNavigation = (section) => {
    switch (section) { 
      case 'howItWorks':
        scrollToSection(howItWorksRef);
        break;
      case 'testimonials':
        scrollToSection(testimonialsRef);
        break;     
      default:
        break;
    }
  };
 
  // Scroll-based header visibility
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsHeaderVisible(currentScrollY < lastScrollY || currentScrollY < 50);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const stats = [
    { value: '100%', label: 'Happy Buyers', icon: Users },
    { value: '40%', label: 'Avg. Savings', icon: TrendingUp },
    { value: 'World Class', label: 'Manufactures', icon: ShoppingCart },
    { value: 'Top', label: 'Rated Service', icon: Star }
  ];

  const calculateSavings = (listPrice, rangePrice) => {
    if (!listPrice || !rangePrice) return "0%";
    const savings = ((listPrice - rangePrice) / listPrice) * 100;
    return `${Math.round(savings)}%`;
  };
  
  const calculateRating = (averageRating, ratingCounts) => {
    if (averageRating) return averageRating;
    // Default to 4.5 if no ratings
    return 4.5;
  };
  
  const calculateTimeLeft = (expiresAt) => {
    if (!expiresAt) return "No deadline";
    const now = new Date();
    const expiry = new Date(expiresAt);
    const diffTime = Math.abs(expiry - now);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    return `${diffDays} days ${diffHours} hours`;
  };

  const fetchCategories = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try { 
      const categoriesUrl = `${Config.apiUrl}getCategories`;
      const headers = { 'Content-Type': 'application/json' };
      const response = await fetch(categoriesUrl, { headers });
      const data = await response.json();  

      if (Array.isArray(data)) {
        const newCategoriesList = data.map((category) => ({
          id: category.id,
          image: category.image_url,  // Changed from image object to direct URL
          name: category.name,
          target_user_type: category.target_user_type,  // Added this field
          description: category.description,
          is_active: category.is_active,
          sub_category: category.sub_category
        })); 
        
        setCategoriesList(newCategoriesList); 
        setCategories(['All Categories', ...data.map(category => category.name)]);
      } else {
        throw new Error('Invalid data format received from server');
      }
    } catch (error) { 
      setError('Failed to load categories. Please try again later.');
      setCategoriesList([]); 
      setCategories(['All Categories']);
    } finally {
      setIsLoading(false);
    }
  }, []);
 
  useEffect(() => {
    fetchCategories(); 
  }, [fetchCategories]);

  const testimonials = [
    {
      id: 1,
      name: "Sarah Johnson",
      role: "Home Designer",
      content: "BacksApp helped me save 45% on bulk furniture orders for my clients. BacksApp is incredible!",
      avatar: "/api/placeholder/64/64"
    },
    {
      id: 2,
      name: "Michael Chen",
      role: "Car Enthusiast",
      content: "Found amazing deals on auto accessories. The community is supportive and the buying process is seamless.",
      avatar: "/api/placeholder/64/64"
    },
    {
      id: 3,
      name: "Emma Davis",
      role: "Restaurant Owner",
      content: "Transformed how I source equipment for my restaurant. The savings have been game-changing for my business.",
      avatar: "/api/placeholder/64/64"
    }
  ];

  const howItWorks = [
    {
        title: "Create Your Wish",
        description: "Tell us what you want to buy and we'll find others interested in the same product",
        icon: Heart
      },
    {
        title: "Join a Interesting Deals",
        description: "Browse active deals and join the ones that interest you",
        icon: Users
      },
    {
      title: "Reach Target",
      description: "Unlock the deal when we meet the supply",
      icon: TrendingUp
    },
    {
      title: "Save Big",
      description: "Enjoy wholesale prices on premium products",
      icon: ShoppingCart
    }
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsIntersecting(prev => ({
            ...prev,
            [entry.target.id]: entry.isIntersecting
          }));
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll('.delayed-animation').forEach((el) => {
      observer.observe(el);
    });

    return () => observer.disconnect();
  }, []);

 

  const renderAuthContent = () => {
    if (loginType === 'register') {
      return <Register onClose={() => setShowLogin(false)} setLoginType={setLoginType} loginType={loginType} />;
    }
    return <Login onClose={() => setShowLogin(false)} setLoginType={setLoginType} loginType={loginType} />;
  };

//onClick={() => {setShowLogin(!showLogin); setLoginType('email');}}
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <header className="fixed w-full bg-white/95 backdrop-blur-sm shadow-sm z-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            {/* Logo */}
            <div className="flex-shrink-0">
              <div className="text-xl font-bold bg-gradient-to-r bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] bg-clip-text text-transparent cursor-pointer">
                BacksApp
              </div>
            </div>
 
            <div className="flex items-center gap-4">
            <button 
              onClick={() => setActivePanel({ type: 'login', subType: 'email' })}
              className="px-4 py-2 text-amber-900 hover:bg-amber-50 rounded-lg transition-all duration-300"
            >
              Sign In
            </button>
            <button onClick={() => setActivePanel({ type: 'register' })} className="px-4 py-2 bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] text-white rounded-lg hover:opacity-90 transition-all duration-300">
              New Account
            </button>
            </div>
          </div>
        </div>
      </header>
      
      <TransitionLayout activePanel={activePanel} setActivePanel={setActivePanel}>
       <div className="w-full"> 
        <div className={`w-full transition-all duration-500 ${showLogin ? 'w-2/3' : 'w-full'}`}>
          {/* Hero Section */}
          <section className="relative min-h-[50vh] flex items-center pt-16  overflow-hidden">
              <div className="absolute inset-0 opacity-100">
              <img src={sceneImage}  alt="Background scene"  className="absolute inset-0 w-full h-full object-cover bg-center"/>
              </div>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
              <div className="flex flex-col md:flex-row justify-between items-center gap-12">
                <div className="max-w-xl backdrop-blur-sm bg-white/40 p-8 rounded-lg border border-white/40">
                  <h1 className="font-serif text-4xl font-bold mb-6 leading-tight ">
                   
                  </h1>
                  <p className="text-gray-900 text-lg mb-6">
                  Access exclusive wholesale pricing on premium products through BacksApp's AI-powered marketplace
                  </p> 
                </div>

                <div className="grid grid-cols-2 gap-4">
                  {stats.map((stat, index) => (
                    <div key={index} className="bg-white/80 backdrop-blur-sm p-6 rounded-lg border border-amber-100/20 group hover:bg-white/90 transition-all duration-300">
                      <stat.icon className="h-8 w-8 text-amber-600 mb-3 group-hover:text-amber-700" />
                      <div className="text-3xl font-bold text-amber-900 mb-1">{stat.value}</div>
                      <div className="text-gray-700">{stat.label}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>  
            </section>
            <section>  
            <div className="bg-gradient-to-r from-amber-50 to-rose-50 py-16">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col md:flex-row justify-between items-center gap-12">
                  {/* Left side - Existing AI Message */}
                  <div className="max-w-xl">
                    <h1 className="font-serif text-4xl font-bold mb-6 leading-tight">
                      <span className="bg-gradient-to-r from-amber-600 to-rose-800 bg-clip-text text-transparent">
                        AI Empowered Smart Shopping
                      </span>
                      <br />
                      <span className="bg-gradient-to-r from-amber-700 to-rose-700 bg-clip-text text-transparent">
                        For Better Savings
                      </span>
                    </h1>
                    <p className="text-amber-700 text-lg mb-6">
                      Join forces with other buyers to unlock wholesale prices on premium products
                    </p>
                    <button className="px-8 py-4 bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] text-white rounded-lg font-medium hover:opacity-90 transition-all duration-300">
                      Save more together!
                    </button>
                  </div>

                  {/* Right side - AI Chat Agent */}
                  <div className="w-full max-w-md bg-white rounded-xl shadow-lg overflow-hidden">
                    <div className="p-6 bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a]">
                      <div className="flex items-center gap-3">
                        <div className="w-10 h-10 bg-white/20 rounded-full flex items-center justify-center">
                          <Users className="w-6 h-6 text-white" />
                        </div>
                        <h2 className="text-xl font-semibold text-white">AI Deal Finder</h2>
                      </div>
                    </div>
                    
                    <div className="p-6">
                      <div className="space-y-4">
                        <div className="bg-amber-50 rounded-lg p-4">
                          <p className="text-gray-700">Ready to discover unbeatable deals? I'll help you:</p>
                          <ul className="mt-2 space-y-2">
                            <li className="flex items-center gap-2">
                              <TrendingUp className="w-4 h-4 text-rose-700" />
                              <span className="text-amber-900">Track prices and predict the best time to buy</span>
                            </li>
                            <li className="flex items-center gap-2">
                              <ShoppingCart className="w-4 h-4 text-rose-700" />
                              <span className="text-amber-900">Match you with deals for maximum savings</span>
                            </li>
                            <li className="flex items-center gap-2">
                              <Star className="w-4 h-4 text-rose-700" />
                              <span className="text-amber-900">Suggest highly-rated products within your budget</span>
                            </li>
                          </ul>
                        </div>

                        <div className="relative">
                          <input  type="text" placeholder="Tell me what you're looking for..."
                            className="w-full p-4 pr-12 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-amber-500"
                            readOnly onClick={() => setActivePanel({ type: 'referral' })}/>
                          <button className="absolute right-2 top-1/2 -translate-y-1/2 p-2 text-amber-600 hover:text-rose-600"
                            onClick={() => setActivePanel({ type: 'referral' })}>
                            <Heart className="w-5 h-5" />
                          </button>
                        </div>

                        <p className="text-sm text-gray-500 text-center">
                          Get your exclusive access code to start saving smart
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>                
          </section>
          <div >       
            {/* How It Works Section */}
            <div ref={howItWorksRef}>
              <HowItWorksSection howItWorks={howItWorks} colorStyles={colorStyles} />
            </div>

            {/* Categories Section with Hover Effects */}
            <div ref={categoriesRef}>
              <CategoriesGrid categories={categoriesList} />
            </div>

            {/* Call to Action Banner */}
            <section className="relative py-20 overflow-hidden"> 
                  <div className="absolute inset-0">
                      <img src={bannerImage} alt="Banner background" className="w-full h-full object-cover"/> 
                          <div className="absolute inset-0" style={{background: 'linear-gradient(to right, rgba(140, 80, 80, 0.7), rgba(138, 109, 81, 0.7), rgba(203, 138, 81, 0.3), rgba(140, 80, 80, 0.7), rgba(203, 109, 81, 0.7), rgba(140, 80, 80, 0.7), rgba(140, 80, 80, 0.7))'}} />
                  </div>

                  {/* Content Layer */}
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
                      <div className="flex flex-col md:flex-row items-center justify-between">
                          <div className="text-white max-w-xl fade-in-left">
                              <h2 className="text-3xl font-bold mb-4 text-white/text-rose-50">Double the Rewards, Double the Savings!</h2>
                              <p className="text-xl text-white/80 mb-8">
                                  Join forces with other smart shoppers - refer friends to earn rewards and team up for wholesale prices.
                                  Together, we save more!
                              </p>
                              
                          </div>
                                          
                          <div className="mt-8 md:mt-0" initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0 }} viewport={{ once: true }}>
                              <div className="bg-white/10 backdrop-blur-sm p-8 rounded-lg border border-white/20">
                                  <h3 className="text-2xl font-semibold text-white mb-4">Need a Referral Code?</h3>
                                  <p className="text-gray-200 mb-6">
                                      Get exclusive access and start saving with the AI enhanced purchases today!
                                  </p>
                                  <button className="w-full px-6 py-4 bg-white text-rose-800  rounded-lg font-medium shadow-lg hover:shadow-xl transition-all duration-300" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} onClick={() => {setActivePanel({ type: 'referral' });}}>
                                      Request Referral Code
                                  </button>
                              </div>
                               
                          </div>
                      </div>
                  </div>
              </section>

            {/* Testimonials Section */}
            <div ref={testimonialsRef}>
              <TestimonialsSection testimonials={testimonials} />
            </div> 
          </div>
          <div className={`fixed right-0 top-0 h-full py-16 bg-gradient-to-b from-amber-50 via-white to-amber-50 shadow-lg transition-all duration-500 transform ${showLogin ? 'translate-x-0 w-1/3' : 'translate-x-full w-0'}`}>
            {showLogin && renderAuthContent()}
          </div>
        </div>
        </div> 
    </TransitionLayout> 
    {/* Footer */}
    <Footer />                          
    </div> 
);
};
                        
export default LandingPage; 
                                                                       