import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import { useAuth } from '../../Common/InitialGears/AuthContext';
import { TopMenuBar } from '../DashbordComponents/TopMenuBar';
import { Search } from 'lucide-react';
import Config from "../../../config"; 

const ReferralRequested = () => {
  const navigate = useNavigate();
  const { queryLoginParam } = useAuth();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [filters, setFilters] = useState({
    status: '',
    dateFrom: '',
    dateTo: '',
    search: ''
  });

  useEffect(() => {
    fetchUsers();
  }, [filters]);

  const fetchUsers = async () => {
    setIsLoading(true);
    setErrorMessage('');
    try { 
      const queryParams = new URLSearchParams({ 
        status: filters.status || undefined,
        date_from: filters.dateFrom || undefined,
        date_to: filters.dateTo || undefined,
        search: filters.search || undefined
      }).toString();             
        const url = buildUrl('referralsRequested', queryLoginParam);  
        const response = await postApi(url, {
          status: filters.status || "All",
          date_from: filters.dateFrom || undefined,
          date_to: filters.dateTo || undefined
        });
         
      if (response && response.data) {
        setUsers(response.data.results);
        if (response.data.results.length === 0) {
          setErrorMessage('No verification requests found.');
        }
      } else {
        setErrorMessage('Failed to fetch verification requests');
      }
    } catch (error) {
      setErrorMessage('An error occurred while fetching verification requests');
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprove = async (userId) => {
    try {
      const url = buildUrl('approveReferralsRequested', queryLoginParam);  
      const response = await postApi(url, {id: userId});
      if (response && response.data) { 
        fetchUsers();
      }
    } catch (error) {
      setErrorMessage('Failed to approve invite');
    }
  };

  const renderFilters = () => (
    <div className="bg-white p-4 rounded-lg mb-4 flex flex-wrap gap-4 items-center">
      <div className="flex items-center">
        <label className="mr-2">Status:</label>
        <select className="p-2 border rounded" value={filters.status} onChange={(e) => setFilters({...filters, status: e.target.value})}>
          <option value="">All</option>
          <option value="PENDING">Pending</option>
          <option value="VERIFIED">Verified</option>
          <option value="APPROVED">Approved</option>
        </select>
      </div>
      
      <div className="flex items-center">
        <label className="mr-2">From:</label>
        <input type="date" className="p-2 border rounded" value={filters.dateFrom} onChange={(e) => setFilters({...filters, dateFrom: e.target.value})}/>
      </div>
      
      <div className="flex items-center">
        <label className="mr-2">To:</label>
        <input type="date" className="p-2 border rounded" value={filters.dateTo} onChange={(e) => setFilters({...filters, dateTo: e.target.value})}/>
      </div>
      
      <div className="flex items-center flex-grow">
        <div className="relative w-full">
          <input type="text" placeholder="Search by name, email, or phone..." className="p-2 pl-10 border rounded w-full" value={filters.search} onChange={(e) => setFilters({...filters, search: e.target.value})}/>
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>
    </div>
  );

  const renderUserList = () => {
    return users.map((user) => (
      <div  key={user.id} className="bg-white rounded-lg p-4 mb-4 shadow-sm hover:shadow-md transition-shadow" >
        <div className="flex justify-between items-start mb-4">
          <div>
            <h3 className="text-lg font-bold text-purple-700">
              {user.first_name} {user.last_name}
            </h3>
            <p className="text-gray-600 ml-6">{user.email}</p>
            <p className="text-gray-600 ml-6">{user.phone}</p>
          </div>
          {user.invite_details.status !== 'APPROVED' && (
            <button onClick={() => handleApprove(user.id)} className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 transition-colors" >
              Approve
            </button>
          )}
        </div>
        
        <div className="grid grid-cols-2 gap-4 ml-6">
          <div>
            <p className="text-gray-600">
              <span className="font-semibold">Verification Status:</span>{' '}
              {user.verification_status.is_verified ? 'Verified' : 'Not Verified'}
            </p>
            <p className="text-gray-600">
              <span className="font-semibold">Verification Date:</span>{' '}
              {user.verification_status.verification_date 
                ? new Date(user.verification_status.verification_date).toLocaleDateString() 
                : 'N/A'}
            </p>
            <p className="text-gray-600">
              <span className="font-semibold">Attempts:</span>{' '}
              {user.verification_status.verification_attempts}
            </p>
          </div>
          <div>
            <p className="text-gray-600">
              <span className="font-semibold">Invite Status:</span>{' '}
              {user.invite_details.status}
            </p>
            <p className="text-gray-600">
              <span className="font-semibold">Requested Date:</span>{' '}
              {new Date(user.invite_details.requested_date).toLocaleDateString()}
            </p>
            <p className="text-gray-600">
              <span className="font-semibold">Invite Code:</span>{' '}
              {user.invite_details.invite_code || 'Not Generated'}
            </p>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="min-h-screen bg-[#F5F5DC]">
      <TopMenuBar 
        showHomeButton={true} 
        title="User Verification Management" 
        showSearch={false} 
        showAddress={false} 
        showMenuButton={true} 
      />
      
      <div className="p-4">
        {renderFilters()}
        
        {isLoading ? (
          <div className="text-center text-lg mt-8">Loading...</div>
        ) : errorMessage ? (
          <div className="text-center text-red-600 text-lg mt-8">{errorMessage}</div>
        ) : (
          <div className="mt-4">
            {renderUserList()}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferralRequested;