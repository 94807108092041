import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ApprovalModalStyles from '../Buyers/GroupWishes/ApprovalModalStyles';

const ApprovalModal = ({ isOpen, onClose, onApprove }) => {
  const [isVerified, setIsVerified] = useState(false);
  const [verificationScore, setVerificationScore] = useState('');
  const [commissionType, setCommissionType] = useState('percentage'); // 'percentage' or 'fixed'
  const [commissionValue, setCommissionValue] = useState('');
  const [commissionPercentage, setCommissionPercentage] = useState('5'); // Default 5%
  const [error, setError] = useState('');

  const percentageOptions = Array.from({ length: 100 }, (_, i) => (i + 1).toString());

  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = ApprovalModalStyles;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  const handleApprove = () => {
    if (verificationScore === '' || isNaN(verificationScore) || verificationScore < 0 || verificationScore > 100) {
      setError('Please enter a valid verification score between 0 and 100.');
      return;
    }

    if (commissionType === 'percentage' && !percentageOptions.includes(commissionPercentage)) {
      setError('Please select a valid commission percentage.');
      return;
    }

    if (commissionType === 'fixed' && (commissionValue === '' || isNaN(commissionValue) || commissionValue <= 0)) {
      setError('Please enter a valid fixed commission amount.');
      return;
    }

    setError('');
    onApprove(isVerified, parseInt(verificationScore), {
      type: commissionType,
      value: commissionType === 'percentage' ? parseInt(commissionPercentage) : parseFloat(commissionValue)
    });
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="modal-title">Approve Group Wish</h2>
          <button onClick={onClose} className="close-button">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="approval-form">
          <div className="form-group">
            <label htmlFor="verified">Is the group wish verified?</label>
            <select id="verified"  value={isVerified ? 'yes' : 'no'} onChange={(e) => setIsVerified(e.target.value === 'yes')}>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="score">Verification Score (0-100):</label>
            <input id="score" type="number" min="0" max="100" value={verificationScore} onChange={(e) => setVerificationScore(e.target.value)}/>
          </div>

          <div className="form-group">
            <label htmlFor="commission-type">Commission Type:</label>
            <select
              id="commission-type"
              value={commissionType}
              onChange={(e) => setCommissionType(e.target.value)}
            >
              <option value="percentage">Percentage Based</option>
              <option value="fixed">Fixed Rate</option>
            </select>
          </div>

          {commissionType === 'percentage' ? (
            <div className="form-group">
              <label htmlFor="commission-percentage">Commission Percentage:</label>
              <select
                id="commission-percentage"
                value={commissionPercentage}
                onChange={(e) => setCommissionPercentage(e.target.value)}
              >
                {percentageOptions.map(percent => (
                  <option key={percent} value={percent}>{percent}%</option>
                ))}
              </select>
            </div>
          ) : (
            <div className="form-group">
              <label htmlFor="commission-value">Fixed Commission Amount:</label>
              <input
                id="commission-value"
                type="number"
                min="0"
                step="0.01"
                value={commissionValue}
                onChange={(e) => setCommissionValue(e.target.value)}
                placeholder="Enter amount"
              />
            </div>
          )}

          {error && <div className="error">{error}</div>}
          <button onClick={handleApprove} className="approve-button">
            Approve
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApprovalModal;