// authUtils.js
import { signOut } from 'firebase/auth';
import { auth } from '../../../firebaseConfig';

let currentAuthToken = null;
let updateAuthStateFunction = null;
let clearLocalStorageFunction = null;

export const initializeAuthUtils = (updateAuthState, clearLocalStorage) => {
  updateAuthStateFunction = updateAuthState;
  clearLocalStorageFunction = clearLocalStorage;
};

export const setAuthToken = (token) => {
  currentAuthToken = token;
  if (updateAuthStateFunction) {
    updateAuthStateFunction({ idTokenLoggedIn: token });
  }
};

export const clearAuthToken = () => {
  currentAuthToken = null;
  if (updateAuthStateFunction) {
    updateAuthStateFunction({ idTokenLoggedIn: null });
  }
};

export const getAuthToken = async (forceRefresh = false) => {
  const user = auth.currentUser;
  
  if (!user) {
    clearAuthToken();
    return null;
  }

  try {
    // If we have a token and don't need to force refresh, return it
    if (currentAuthToken && !forceRefresh) {
      return currentAuthToken;
    }

    // Get a fresh token
    const token = await user.getIdToken(true);
    setAuthToken(token);
    return token;
  } catch (error) {
    //console.error('Error getting auth token:', error);
    clearAuthToken();
    return null;
  }
};

export const refreshAuthToken = async () => {
  try {
    const token = await getAuthToken(true); // Force refresh
    if (token) {
      setAuthToken(token);
      return token;
    }
    return null;
  } catch (error) {
    //console.error('Error refreshing auth token:', error);
    clearAuthToken();
    return null;
  }
};

export const logoutUser = async () => {
  try {
    await signOut(auth);
    clearAuthToken();
    
    if (clearLocalStorageFunction) {
      await clearLocalStorageFunction();
    }
    
    if (updateAuthStateFunction) {
      updateAuthStateFunction({
        idTokenLoggedIn: null,
        user: null,
        isLoggedIn: false,
        userProfile: null
        // Add any other state resets needed
      });
    }
    
    return true;
  } catch (error) {
    //console.error('Error during logout:', error);
    throw error;
  }
};

// Helper to check if token is valid
export const isTokenValid = () => {
  return !!currentAuthToken;
};

// Use this in your API calls
export const getAuthHeaders = async () => {
  const token = await getAuthToken();
  return {
    'Authorization': token,
    'Content-Type': 'application/json'
  };
};