import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import * as globalStyles from '../../../GlobalStyles';

const ReminderSettingScreen = ({
  onSaveReminder,
  onCancel,
  initialReminderTime = new Date(),
  initialTitle = '',
  notificationId,
}) => {
  const [title] = useState(initialTitle);
  const [content] = useState('');
  const [reminderTime, setReminderTime] = useState(initialReminderTime);

  const handleSaveReminder = () => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const message = {
      title,
      content,
      reminderTime: reminderTime.toISOString(),
      localTime: reminderTime,
      userTimezone,
      notificationId,
    };
    onSaveReminder(message);
  };

  return (
    <div style={styles.overlayContainer}>
      <div style={styles.modalView}>
        <div style={styles.modalHeader}>
          <h2 style={globalStyles.modalText}>Set Reminder:</h2>
          <button style={styles.closeButton} onClick={onCancel}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        
        <div style={styles.contentContainer}>
          <p style={globalStyles.modalText}>{title}</p>
          
          <div style={styles.datePickerContainer}>
            <DatePicker
              selected={reminderTime}
              onChange={(date) => setReminderTime(date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
              customInput={<input style={styles.datePickerInput} />}
              popperProps={{
                positionFixed: true,
                modifiers: [
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: 'viewport',
                      padding: 8
                    }
                  }
                ]
              }}
            />
          </div>

          <button 
            style={{...globalStyles.button, ...styles.saveButton}} 
            onClick={handleSaveReminder}
          >
            Save Reminder
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  overlayContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  modalView: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '500px',
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '24px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  closeButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    background: 'none',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    color: globalStyles.Color.colorPurple,
    padding: '8px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  datePickerContainer: {
    position: 'relative',
    zIndex: 1001,
  },
  datePickerInput: {
    width: '120%',
    marginLeft: '65%',
    padding: '12px',
    fontSize: '16px',
    border: `1px solid ${globalStyles.Color.colorPurple}`,
    borderRadius: '8px',
    backgroundColor: 'white',
  },
  saveButton: {
    width: '100%',
    marginTop: '20px',
  },
};

export default ReminderSettingScreen;