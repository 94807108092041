import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUsers, 
  faUserCheck, 
  faUserXmark,
  faChartLine,
  faPencilAlt,
  faCheck,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import * as style from "../../GlobalStyles";

const UserStatsDisplay = ({ 
  verifiedUsers = [], 
  unverifiedUsers = [],
  bumpedVerifiedUsers = [],
  bumpedUnverifiedUsers = [],
  onBumpedUsersUpdate
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editBumpedVerified, setEditBumpedVerified] = useState(bumpedVerifiedUsers.length);
  const [editBumpedUnverified, setEditBumpedUnverified] = useState(bumpedUnverifiedUsers.length);

  useEffect(() => {
    setEditBumpedVerified(bumpedVerifiedUsers.length);
    setEditBumpedUnverified(bumpedUnverifiedUsers.length);
  }, [bumpedVerifiedUsers.length, bumpedUnverifiedUsers.length]);

  const calculateTotalQty = (users) => {
    return users.reduce((total, user) => total + (user.quantity || 0), 0);
  };

  const handleSaveEdit = () => {
    onBumpedUsersUpdate(editBumpedVerified, editBumpedUnverified);
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setEditBumpedVerified(bumpedVerifiedUsers.length);
    setEditBumpedUnverified(bumpedUnverifiedUsers.length);
    setIsEditing(false);
  };

  const stats = {
    verified: {
      regular: {
        count: verifiedUsers.length,
        qty: calculateTotalQty(verifiedUsers)
      },
      bumped: {
        count: bumpedVerifiedUsers.length,
        qty: calculateTotalQty(bumpedVerifiedUsers)
      }
    },
    unverified: {
      regular: {
        count: unverifiedUsers.length,
        qty: calculateTotalQty(unverifiedUsers)
      },
      bumped: {
        count: bumpedUnverifiedUsers.length,
        qty: calculateTotalQty(bumpedUnverifiedUsers)
      }
    },
    totals: {
      verifiedCount: verifiedUsers.length + bumpedVerifiedUsers.length,
      verifiedQty: calculateTotalQty(verifiedUsers) + calculateTotalQty(bumpedVerifiedUsers),
      unverifiedCount: unverifiedUsers.length + bumpedUnverifiedUsers.length,
      unverifiedQty: calculateTotalQty(unverifiedUsers) + calculateTotalQty(bumpedUnverifiedUsers)
    }
  };

  const EditableStatSection = ({ title, icon, stats, bumpedCount, onBumpedChange }) => (
    <div className="stat-section">
      <p className="stat-label">{title}</p>
      <p className="stat-value">Users: {stats.regular.count}</p>
      <p className="stat-value">Quantity: {stats.regular.qty}</p>
      <p className="stat-value edit-container">
        Bumped Users: {
          isEditing ? (
            <input
              type="number"
              min="0"
              value={bumpedCount}
              onChange={(e) => onBumpedChange(parseInt(e.target.value) || 0)}
              className="bumped-input"
            />
          ) : stats.bumped.count
        }
      </p>
    </div>
  );

  const EditControls = () => (
    <div className="edit-controls">
      {!isEditing ? (
        <button className="edit-button" onClick={() => setIsEditing(true)}>
          <FontAwesomeIcon icon={faPencilAlt} />
        </button>
      ) : (
        <div className="edit-actions">
          <button className="save-button" onClick={handleSaveEdit}>
            <FontAwesomeIcon icon={faCheck} />
          </button>
          <button className="cancel-button" onClick={handleCancelEdit}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div className="stats-card">
      <div className="stats-header">
        <FontAwesomeIcon icon={faUsers} className="header-icon" />
        <h2 className="header-title">User Statistics</h2>
        <EditControls />
      </div>
      <div className="stats-content">
        <div className="stat-block verified">
          <div className="stat-header">
            <FontAwesomeIcon icon={faUserCheck} className="stat-icon" />
            <h3 className="stat-title">Verified Users</h3>
          </div>
          <div className="stat-content">
            <EditableStatSection
              title="Verified"
              icon={faUserCheck}
              stats={stats.verified}
              bumpedCount={editBumpedVerified}
              onBumpedChange={setEditBumpedVerified}
            />
          </div>
        </div>

        <div className="stat-block unverified">
          <div className="stat-header">
            <FontAwesomeIcon icon={faUserXmark} className="stat-icon" />
            <h3 className="stat-title">Unverified Users</h3>
          </div>
          <div className="stat-content">
            <EditableStatSection
              title="Unverified"
              icon={faUserXmark}
              stats={stats.unverified}
              bumpedCount={editBumpedUnverified}
              onBumpedChange={setEditBumpedUnverified}
            />
          </div>
        </div>

        <div className="stat-block totals">
          <div className="stat-header">
            <FontAwesomeIcon icon={faChartLine} className="stat-icon" />
            <h3 className="stat-title">Total Statistics</h3>
          </div>
          <div className="stat-grid">
            <div className="stat-section">
              <p className="stat-label">Verified Total</p>
              <p className="stat-value">Users: {stats.totals.verifiedCount}</p>
              <p className="stat-value">Quantity: {stats.totals.verifiedQty}</p>
            </div>
            <div className="stat-section">
              <p className="stat-label">Unverified Total</p>
              <p className="stat-value">Users: {stats.totals.unverifiedCount}</p>
              <p className="stat-value">Quantity: {stats.totals.unverifiedQty}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = `
.stats-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px;
    margin-top: 60px;
    overflow: hidden;
  }

  .stats-header {
    background-color: #f8f9fa;
    background-color: ${style.Color.colorWhite};
    padding: 16px;
    border-bottom: 1px solid #e9ecef;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .header-icon {
    font-size: 24px;
    color: ${style.Color.colorPurple};
  }

  .header-title {
    font-size: 20px;
    font-weight: 600;
    margin: 0; 
    color: ${style.Color.colorPurple};
  }

  .stats-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .stat-block {
    padding: 16px;
    border-radius: 8px;
    background-color: #f8f9fa;
  }

  .stat-block.verified {
    background-color: #d4edda;
  }

  .stat-block.unverified {
    background-color: #fff3cd;
  }

  .stat-block.totals {
    background-color: #cce5ff;
  }

  .stat-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
  }

  .stat-icon {
    font-size: 18px;
    color: #495057;
  }

  .stat-title {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    color: #212529;
  }

  .stat-content {
    margin-top: 8px;
  }

  .stat-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .stat-section {
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
  }

  .stat-label {
    font-size: 14px;
    color: #6c757d;
    margin: 0 0 4px 0;
  }

  .stat-value {
    font-size: 14px;
    font-weight: 500;
    color: #212529;
    margin: 4px 0;
  }

  @media (max-width: 768px) {
    .stat-grid {
      grid-template-columns: 1fr;
    }

    .stats-card {
      margin: 10px;
    }
  .edit-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .bumped-input {
    width: 60px;
    padding: 4px;
    border: 1px solid ${style.Color.colorMintGreen};
    border-radius: 4px;
    font-size: 14px;
  }

  .edit-controls {
    margin-left: auto;
  }

  .edit-button,
  .save-button,
  .cancel-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px 8px;
    font-size: 16px;
    color: ${style.Color.colorDarkGrey};
    transition: color 0.3s;
  }

  .edit-button:hover {
    color: ${style.Color.colorMintGreen};
  }

  .save-button {
    color: ${style.Color.colorMintGreen};
  }

  .save-button:hover {
    color: ${style.Color.colorDarkMintGreen};
  }

  .cancel-button {
    color: ${style.Color.colorDarkGrey};
  }

  .cancel-button:hover {
    color: ${style.Color.colorDarkerGrey};
  }

  .edit-actions {
    display: flex;
    gap: 8px;
  }
`;

// Add styles to document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default UserStatsDisplay;