import React, { useState, useEffect } from 'react';  
import { useNavigate } from 'react-router-dom';
import * as style from "../../../GlobalStyles";
import { Heart, Clock, Users, Star, ThumbsUp, ThumbsDown, Activity } from 'lucide-react';
import { useAuth } from '../../../components/Common/InitialGears/AuthContext'; 
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import { TopMenuBar } from '../DashbordComponents/TopMenuBar';

const FavouriteGroupWishes = () => {
  const { queryLoginParam } = useAuth();
  const navigate = useNavigate();
  const [groupWishes, setGroupWishes] = useState({
    user_group_wishes: [],
    connection_group_wishes: [],
    additional_group_wishes: []
  });
  const [filteredWishes, setFilteredWishes] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [sortOrder, setSortOrder] = useState('popularity-desc');
  const [expandedGroupId, setExpandedGroupId] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFavoriteProcessing, setIsFavoriteProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchGroupWishes();
  }, []);

  useEffect(() => {
    filterAndSortWishes();
  }, [searchText, groupWishes, sortOrder]);

  const fetchGroupWishes = async () => {
    try {
      const url = buildUrl('getFavoriteGroupWishes', queryLoginParam);
      const response = await getApi(url);
      
      if (response.ok) {
        setGroupWishes(response.data);
        setFilteredWishes(response.data.user_group_wishes);
      } else {
        setError('Failed to fetch group wishes');
      }
    } catch (error) {
      setError('Failed to fetch group wishes');
    } finally {
      setIsLoading(false);
    }
  };

  const filterAndSortWishes = () => {
    let filtered = groupWishes.user_group_wishes.filter(item => {
      const wish = item.wish;
      return wish.product_name.toLowerCase().includes(searchText.toLowerCase()) ||
             wish.deal_title.toLowerCase().includes(searchText.toLowerCase()) ||
             wish.description.toLowerCase().includes(searchText.toLowerCase());
    });

    filtered.sort((a, b) => {
      const wishA = a.wish;
      const wishB = b.wish;
      
      switch (sortOrder) {
        case 'popularity-desc':
          return wishB.total_buddies - wishA.total_buddies;
        case 'popularity-asc':
          return wishA.total_buddies - wishB.total_buddies;
        case 'date-desc':
          return new Date(wishB.created_at) - new Date(wishA.created_at);
        case 'date-asc':
          return new Date(wishA.created_at) - new Date(wishB.created_at);
        default:
          return 0;
      }
    });

    setFilteredWishes(filtered);
  };

  const toggleSortOrder = () => {
    const orders = ['popularity-desc', 'popularity-asc', 'date-desc', 'date-asc'];
    const currentIndex = orders.indexOf(sortOrder);
    setSortOrder(orders[(currentIndex + 1) % orders.length]);
  };

  const renderWishGroup = (item) => {
    const wish = item.wish;
    const isExpanded = expandedGroupId === wish.id;

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    };

    return (
      <div key={wish.id} style={styles.trendItem}>            
        <button onClick={() => setExpandedGroupId(isExpanded ? null : wish.id)} style={styles.trendItemButton}>
          <div style={styles.trendItemHeader}>
            <div style={styles.titleContainer}>
              <h3 style={styles.trendItemTitle}>{wish.product_name}</h3>
            </div>
            <span style={styles.wishCount}>{item.totalJoined} Customers have shown interest</span>
          </div>
        </button>

        {isExpanded && (
          <div style={styles.trendItemDetails}>
            {wish.image_utl && (
              <div style={styles.imageContainer}>
                <img src={wish.image} alt={wish.product_name} style={styles.productImage} />
              </div>
            )}
            
            {renderDescription(wish.description, isExpanded)}

            <div style={styles.statsContainer}>
              <div style={styles.statItem}>
                <Users className="w-4 h-4" style={styles.statIcon} />
                <span style={styles.statText}>{item.verifiedUsers} Verified Buyers</span>
              </div>
              <div style={styles.statItem}>
                <Clock className="w-4 h-4" style={styles.statIcon} />
                <span style={styles.statText}>Expires: {formatDate(wish.expires_at)}</span>
              </div>
               
            </div>

            <div style={styles.ratingContainer}>
              <div style={styles.ratingItem}>
                <ThumbsUp className="w-4 h-4" style={styles.ratingIcon} />
                <span style={styles.ratingText}>{wish.upvotes}</span>
              </div>
              <div style={styles.ratingItem}>
                <ThumbsDown className="w-4 h-4" style={styles.ratingIcon} />
                <span style={styles.ratingText}>{wish.downvotes}</span>
              </div>
              {wish.list_price && (
                <div style={styles.priceTag}>
                  <span style={styles.priceLabel}>List Price:</span>
                  <span style={styles.priceValue}>{wish.currency} {wish.list_price}</span>
                </div>
              )}
            </div>
            
            <div style={styles.actionButtons}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFavorite(wish.id);
                }}
                disabled={isFavoriteProcessing}
                style={styles.actionButton}
              >
                <Heart className="w-4 h-4 mr-2" />
                Unwish
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigateToProgress(wish.id);
                }}
                style={styles.actionButton}
              >
                <Activity className="w-4 h-4 mr-2" />
                View Progress
              </button>
            </div>

            {errorMessage && (
              <div style={styles.errorMessage}>
                {errorMessage}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };
  
  const renderDescription = (description, isExpanded) => {
    if (!description) return null;
  
    const formatDescription = (text) => {
      const sections = text.split(/\r\n\r\n/);
      
      return sections.map((section, index) => {
        if (section.includes(':')) {
          const [title, ...points] = section.split(/\r\n/);
          return (
            <div key={index} style={styles.descriptionSection}>
              <h4 style={styles.sectionTitle}>{title}</h4>
              <ul style={styles.sectionPoints}>
                {points.map((point, i) => {
                  const trimmedPoint = point.replace(/^[-•]?\s*/, '');
                  if (trimmedPoint) {
                    return <li key={i}>{trimmedPoint}</li>;
                  }
                  return null;
                })}
              </ul>
            </div>
          );
        } else {
          return <p key={index} style={styles.descriptionParagraph}>{section}</p>;
        }
      });
    };
  
    return (
      <div style={styles.descriptionContainer}>
        <div style={styles.descriptionContent}>
          {formatDescription(description)}
        </div>
      </div>
    );
  };

  const toggleFavorite = async (wishId) => {
    if (isFavoriteProcessing || !wishId) return;
    setIsFavoriteProcessing(true);
    
    try {
      const favoriteUrl = buildUrl('removeFromfavoriteGroupWish', queryLoginParam);
      const response = await postApi(favoriteUrl, {
        group_wish_id: wishId
      });

      if (response.ok) {
        await fetchGroupWishes();
      } else {
        setErrorMessage('Failed to remove from favorites.');
      }
    } catch (error) {
      setErrorMessage('Error updating favorites. Please try again.');
    } finally {
      setTimeout(() => {
        setIsFavoriteProcessing(false);
      }, 500);
    }
  };

  const navigateToProgress = (wishId) => {
    const wishItem = groupWishes.user_group_wishes.find(item => item.wish.id === wishId);
    if (wishItem) { 
      const progressData = {
        ...wishItem,
        is_favorite: true, 
        wish: {
          ...wishItem.wish,
          is_favorite: true   
        },
        group_wish_buddy_status: wishItem.group_wish_buddy_status,
        user_already_joined: wishItem.user_already_joined || false
      };

      navigate(`/groupWishProgress`, {
        state: {
          wish: progressData.wish,
          progressData: progressData,
          joined: progressData.user_already_joined || false,
          is_favorite: true  // Explicitly pass favorite status
        }
      });
    }
  };

  const getSortButtonText = () => {
    switch (sortOrder) {
      case 'popularity-desc':
        return 'Most Popular ↓';
      case 'popularity-asc':
        return 'Least Popular ↑';
      case 'date-desc':
        return 'Newest First ↓';
      case 'date-asc':
        return 'Oldest First ↑';
      default:
        return 'Sort';
    }
  };

  if (error) {
    return <div style={styles.error}>{error}</div>;
  }

  if (isLoading) {
    return <div style={styles.loading}>Loading...</div>;
  }

  return (
    <div style={styles.container}>
      <TopMenuBar 
        showBackButton={false} 
        showHomeButton={true} 
        showMenuButton={true} 
        onBackClick={() => navigate(-1)} 
        title={'Details'} 
        showSearch={false} 
        showAddress={false} 
      /> 
      <div style={styles.header}>
        <h2 style={styles.pageTitle}>Favorite Group Wishes</h2>
      </div>

      <div style={styles.content}>
        <div style={styles.searchContainer}>
          <input 
            type="text" 
            placeholder="Search wishes..." 
            value={searchText} 
            onChange={(e) => setSearchText(e.target.value)} 
            style={styles.searchInput}
          />
          <button onClick={toggleSortOrder} style={styles.sortButton}>
            {getSortButtonText()}
          </button>
        </div>

        <div style={styles.wishList}>
          {filteredWishes.map(renderWishGroup)}
        </div>
      </div>
    </div>
  );
};
 
const styles = {
  container: {
    minHeight: '100vh',
    padding: '20px',
    backgroundColor: '#fff',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    backgroundColor: style.Color.colorLightBeigeStrong,
    marginBottom: '20px',
    borderRadius: '10px',
  },
  pageTitle: {
    fontSize: '24px',
    fontWeight: 'bold',    
    color: style.Color.colorMediumblue,
  },
  content: {
    backgroundColor: style.Color.colorLightBeigeStrong,
    borderRadius: '10px',
    padding: '20px',
  },
  searchContainer: {
    display: 'flex',
    gap: '10px',
    marginBottom: '20px',
  },
  searchInput: {
    flexGrow: 1,
    padding: '10px',
    borderRadius: '8px',
    border: '1px solid #ddd',
    fontSize: '16px',
  },
  sortButton: {
    padding: '10px 20px',
    backgroundColor: style.Color.colorGreenGrey,
    color: style.Color.colorWhite,
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  wishList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  descriptionContainer: {
    marginTop: '15px',
    padding: '15px',
    backgroundColor: style.Color.colorWhite,
    borderRadius: '8px',
  },
  descriptionContent: {
    fontSize: '12px',
    lineHeight: '1.6',
    color: style.Color.colorDarkBlue,
  },
  descriptionSection: {
    marginBottom: '20px',
  },
  sectionTitle: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: style.Color.colorDarkslategray,
    marginBottom: '10px',
  },
  sectionPoints: {
    fontSize: '12px',
    listStyleType: 'none',
    paddingLeft: '20px',
    color: style.Color.colorRoyalBlueText,
    margin: 0,
  },
  descriptionParagraph: {
    marginBottom: '15px',
    textAlign: 'justify',
    fontSize: '12px',
    color: style.Color.colorDarkBlue,
  },
  listItem: {
    position: 'relative',
    paddingLeft: '15px',
    marginBottom: '8px',
    fontSize: '12px',
    '&:before': {
      content: '"•"',
      position: 'absolute',
      left: 0,
      color: style.Color.colorMintGreen,
    }
  },
  trendItemDetails: {
    marginTop: '16px',
    padding: '10px',
    backgroundColor: style.Color.colorWhite,
    borderRadius: '8px',
  },
  trendItem: {
    backgroundColor: '#FFFFFF',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  trendItemButton: {
    width: '100%',
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    textAlign: 'left',
  },
  trendItemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  trendItemTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: style.Color.colorPurple,
  },
  wishCount: {
    color: style.Color.colorRoyalBlueText,
  },
  trendItemDetails: {
    marginTop: '16px',
  },
  description: {
    color: '#666',
    marginBottom: '12px',
  },
  statsContainer: {
    display: 'flex',
    gap: '20px',
    marginBottom: '16px',
    padding: '12px',
    backgroundColor: style.Color.colorLightestBeige,
    borderRadius: '8px',
  },
  statItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  statIcon: {
    color: style.Color.colorPurple,
    width: '16px',
    height: '16px',
  },
  statText: {
    fontSize: '14px',
    color: style.Color.colorDarkBlue,
    fontWeight: '500',
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    padding: '12px',
    borderTop: `1px solid ${style.Color.colorLightGrey}`,
    borderBottom: `1px solid ${style.Color.colorLightGrey}`,
    backgroundColor: style.Color.colorWhite,
  },
  ratingItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  },
  ratingIcon: {
    color: style.Color.colorMintGreen,
    width: '16px',
    height: '16px',
  },
  ratingText: {
    fontSize: '14px',
    color: style.Color.colorDarkBlue,
    fontWeight: '500',
  },
  priceTag: {
    marginLeft: 'auto',
    padding: '6px 12px',
    backgroundColor: style.Color.colorLightestBeige,
    borderRadius: '6px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  priceLabel: {
    fontSize: '14px',
    color: style.Color.colorDarkGrey,
  },
  priceValue: {
    fontSize: '14px',
    color: style.Color.colorDarkBlue,
    fontWeight: '600',
  },
  trendItemActionButton: {
    display: 'flex',
    alignItems: 'center',
    color: style.Color.colorRoyalBlueText,
    background: 'none',
    border: 'none',
    padding: '8px 0',
    cursor: 'pointer',
    marginBottom: '12px',
  },
  chartContainer: {
    height: '200px',
  },
  modalOverlay: {
    position: 'fixed',
    inset: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
  },
  modalContent: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    padding: '24px',
    width: '100%',
    maxWidth: '768px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  modalTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  closeButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: '#666',
  },
  modalChartContainer: {
    height: '320px',
  },
  error: {
    padding: '16px',
    color: '#DC2626',
  },
  titleContainer: {
    flex: 1,
    marginRight: '16px',
  },
  categoryTags: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    marginTop: '8px',
  },
  categoryTag: {
    padding: '4px 8px', 
    color: style.Color.colorDarkGreenText,
    borderRadius: '4px',
    fontSize: '12px',
  },
  imageContainer: {
    width: '50%',
    height:'50%',
    marginBottom: '16px',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  productImage: {
    width: '100%',
    maxHeight: '200px',
    objectFit: 'cover',
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginTop: '12px',
    padding: '8px 0',
    borderTop: '1px solid #eee',
  },
  ratingItem: {
    display: 'flex',
    alignItems: 'center',
    color: '#666',
  },
  priceTag: {
    marginLeft: 'auto',
    padding: '4px 8px',
    backgroundColor: '#E5E7EB',
    borderRadius: '4px',
    fontSize: '14px',
    color: '#374151',
  },
  subCategories: {
    marginTop: '12px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flexWrap: 'wrap',
  },
  subCategoryLabel: {
    fontSize: '14px',
    color: '#6B7280',
  },
  subCategoryTag: {
    padding: '2px 6px',
    backgroundColor: '#F3F4F6',
    borderRadius: '4px',
    fontSize: '12px',
    color: '#4B5563',
  },
  actionButtons: {
    display: 'flex',
    gap: '12px',
    marginTop: '16px',
    borderTop: '1px solid #eee',
    paddingTop: '16px',
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    backgroundColor: style.Color.colorGreenGrey,
    color: style.Color.colorWhite,
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '14px',
  },
  errorMessage: {
    color: '#DC2626',
    fontSize: '14px',
    marginTop: '8px',
    padding: '8px',
    backgroundColor: '#FEE2E2',
    borderRadius: '4px',
  },
  loading: {
    padding: '20px',
    textAlign: 'center',
    color: '#666',
  },
};

export default FavouriteGroupWishes;