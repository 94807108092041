import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import * as style from "../../../GlobalStyles";

const RatingSlider = ({ onRate, onClose }) => {
  const [starRating, setStarRating] = useState(0); 
  const [starAnimation, setStarAnimation] = useState(0);

  const handleStarRating = useCallback((value) => {
    setStarRating(value);
    setStarAnimation(value * 20); 
  }, []);

  useEffect(() => {
    let timer;
    if (starRating > 0) {
      timer = setTimeout(() => {
        onRate(starRating);
        onClose();
      }, 1500);
    }
    return () => clearTimeout(timer);
  }, [starRating, onRate, onClose]);

  const ratingValues = [1, 2, 3, 4, 5];

  const renderRatingButtons = useCallback(() => {
    return ratingValues.map((value) => (
      <button key={`star-${value}`} className="rate-button" onClick={() => handleStarRating(value)}>
        <FontAwesomeIcon icon={faStar} color={starRating >= value ? '#FFD700' : '#D3D3D3'} />
      </button>
    ));
  }, [starRating, handleStarRating]);

  return (
    <div className="rating-slider-container">
      <button className="close-button" onClick={onClose}> 
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <h2 className="rating-title">Rate this Group Wish</h2>
      <div className="sliders-wrapper">
        <div className="slider-container">
          <div className="slider star-slider" style={{ width: `${starAnimation}%` }} />
          <div className="rate-buttons-container">
            {renderRatingButtons()}
          </div>
        </div>
      </div>
      <p className="instruction">
        {starRating > 0 ? 'Rating will be submitted shortly...' : 'Click to rate'}
      </p> 
    </div>
  );
};

const styles = `
  .rating-slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${style.Color.colorLightBeige};
    padding: 30px;
    border-radius: 12px;
    position: relative;
    max-width: 100%;
    width: 450px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: ${style.Color.colorDarkGrey};
  }
  .rating-title {
    font-size: 24px;
    color: ${style.Color.colorRoyalBlueText};
    margin-bottom: 20px;
  }
  .sliders-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    background-color: ${style.Color.colorLightestBeige};
    border-radius: 25px;
    overflow: hidden;
    position: relative;
  }
  .slider {
    position: absolute;
    height: 100%;
    transition: width 0.3s ease-in-out;
  }
  .star-slider {
    background-color: rgba(255, 215, 0, 0.3);
  }
  .rate-buttons-container {
    display: flex;
    justify-content: space-between;
    width: 300px;
    z-index: 1;
  }
  .rate-button {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  .rate-button:hover {
    transform: scale(1.1);
  }
  .instruction {
    margin-top: 20px;
    font-size: 16px;
    color: ${style.Color.colorDarkGrey};
  }
`;

// Add styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default RatingSlider;