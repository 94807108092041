import React, { useState, useEffect } from 'react';
import Config from "../../../config"; 
import { useAuth } from '../../../components/Common/InitialGears/AuthContext'; 
import * as style from "../../../GlobalStyles"; 
import { FaEnvelope, FaCheck, FaTimes } from 'react-icons/fa';

const ReceivedInvites = () => {
  const { queryLoginParam, idTokenLoggedIn } = useAuth();
  const [receivedInvites, setReceivedInvites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchReceivedInvites();
  }, []);

  const fetchReceivedInvites = async () => {
    setIsLoading(true);
    setErrorMessage('');

    try {
      const apiUrl = `${Config.apiUrl}invitesIReceived?${queryLoginParam}`;
      const headers = {
        'Authorization': idTokenLoggedIn,
        'Content-Type': 'application/json',
      };

      const response = await fetch(apiUrl, {headers});
      const data = await response.json();

      if (response.ok) {
        setReceivedInvites(data.deal_invites || []);
        if (!data.deal_invites || data.deal_invites.length === 0) {
          setErrorMessage('No data found');
        }
      } else {
        setErrorMessage('No data found');
      }
    } catch (error) {
      setErrorMessage('No data found');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAcceptInvite = async (inviteId) => {
    // Implement the logic to accept an invite
    // console.log(`Accepting invite ${inviteId}`);
  };

  const handleDeclineInvite = async (inviteId) => {
    // Implement the logic to decline an invite
    // console.log(`Declining invite ${inviteId}`);
  };

  return (
    <div className="received-invites-container">
      <h2>Received Invites</h2>
      
      {isLoading && <div className="loading-spinner"></div>}
      {errorMessage && <p className="errorMessage">{errorMessage}</p>}
      
      {!isLoading && !errorMessage && receivedInvites.length > 0 && (
        <ul className="invites-list">
          {receivedInvites.map((invite) => (
            <li key={invite.deal_invite_id} className="invite-item">
              <h3>{invite.deal_name}</h3>
              <p><FaEnvelope /> From: {invite.sender}</p>
              <p>Status: {invite.recipient_status}</p>
              <p>Received on: {new Date(invite.invited_date).toLocaleDateString()}</p>
              <div className="invite-actions">
                <button onClick={() => handleAcceptInvite(invite.deal_invite_id)} className="accept-button">
                  <FaCheck /> Accept
                </button>
                <button onClick={() => handleDeclineInvite(invite.deal_invite_id)} className="decline-button">
                  <FaTimes /> Decline
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}

      <style jsx>{`
        .received-invites-container {
          padding: 20px;
          background-color: ${style.Color.colorMintGreen};
          border-radius: 8px;
        }
        
        h2 {
          color: ${style.Color.colorDarkBrown};
          margin-bottom: 20px;
        }
        
        .loading-spinner {
          border: 4px solid ${style.Color.colorLightBeige};
          border-top: 4px solid ${style.Color.colorPurple};
          border-radius: 50%;
          width: 40px;
          height: 40px;
          animation: spin 1s linear infinite;
          margin: 20px auto;
        }
        
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        
        .errorMessage {   
          color: ${style.Color.colorPurple};       
          text-align: center;
          margin: 20px 0;
        }
        
        .invites-list {
          list-style-type: none;
          padding: 0;
        }
        
        .invite-item {
          background-color: ${style.Color.colorLightestBeige};
          border-radius: 8px;
          padding: 15px;
          margin-bottom: 15px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        
        .invite-item h3 {
          color: ${style.Color.colorDarkBrown};
          margin-top: 0;
        }
        
        .invite-item p {
          margin: 5px 0;
          color: ${style.Color.colorRoyalBlueText};
        }
        
        .invite-actions {
          display: flex;
          justify-content: flex-end;
          margin-top: 10px;
        }
        
        .invite-actions button {
          padding: 8px 15px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-weight: bold;
          display: flex;
          align-items: center;
          gap: 5px;
        }
        
        .accept-button {
          background-color: ${style.Color.colorPurple};
          color: white;
          margin-right: 10px;
        }
        
        .decline-button {
          background-color: ${style.Color.colorLightBeige};
          color: ${style.Color.colorDarkBrown};
        }
      `}</style>
    </div>
  );
};

export default ReceivedInvites;