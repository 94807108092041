import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faDollarSign, faShippingFast, faUsers,faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import * as style from "../../GlobalStyles";

const PriceRangesSelection = ({
  priceRanges,
  onPriceRangeSelect,
  selectedActualRange,
  selectedNonActualRange,
  disabled
}) => {
  const actualPriceRanges = priceRanges.filter(range => range.actual_price_range);
  const nonActualPriceRanges = priceRanges.filter(range => !range.actual_price_range);
 
  const showActualRangeError = !disabled && actualPriceRanges.length > 0 && !selectedActualRange;
  const showNonActualRangeError = !disabled && nonActualPriceRanges.length > 0 && !selectedNonActualRange;


  const handlePriceRangeSelect = (rangeId, isActual) => {
    onPriceRangeSelect(rangeId, isActual);
  };

  const renderPriceRange = (range, isSelected, isActual) => (
    <div
      key={range.id}
      className={`price-range-item ${isSelected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
      onClick={() => !disabled && onPriceRangeSelect(range.id, isActual)}
      role="button"
      tabIndex={disabled ? -1 : 0}
    >
      <div className="price-range-header">
        <input
          type="radio"
          name={isActual ? "actualPriceRange" : "nonActualPriceRange"}
          checked={isSelected}
          onChange={() => handlePriceRangeSelect(range.id, isActual)}
        />
        <FontAwesomeIcon icon={faUsers} />
        <span>{range.minQuantity} - {range.maxQuantity} orders</span>
      </div>
      <div className="price-range-details">
        <div className="price-detail">
          <FontAwesomeIcon icon={faDollarSign} />
          <span>Price: ${parseFloat(range.rangePrice).toFixed(2)}</span>
        </div>
        
        <div className="price-detail">
          <FontAwesomeIcon icon={faShippingFast} />
          <span>Shipping: ${parseFloat(range.shippingCost_for_range).toFixed(2)}</span>
        </div>

        <div className="price-detail total">
          <span>Total: ${(parseFloat(range.rangePrice) + parseFloat(range.shippingCost_for_range)).toFixed(2)}</span>
        </div>
      </div>
      {isSelected && (
        <div className="selected-indicator">
          <FontAwesomeIcon icon={faCheck} />
        </div>
      )}
      <div className="price-range-footer">
        {range.description || 'No description available'}
      </div>
    </div>
  );

  return (
    <div className="price-ranges-wrapper">
      <div className="price-ranges-grid">
        {/* Actual Price Ranges Section */}
        {actualPriceRanges.length > 0 && (
          <div className="price-range-section">
            <h3 className="section-title">
              Actual Price Ranges 
              <span className="required">*</span>             
            </h3>
            {showActualRangeError && (
                <span className="error-message">
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  Please select an actual price range
                </span>
              )}
            <div className="price-ranges-container">
              {actualPriceRanges.map(range => 
                renderPriceRange(range, selectedActualRange === range.id, true)
              )}
            </div>
          </div>
        )}

        {/* Non-Actual Price Ranges Section */}
        {nonActualPriceRanges.length > 0 && (
          <div className="price-range-section">
            <h3 className="section-title">
              Customer Facing Price Ranges 
              <span className="required">*</span>              
            </h3>
            {showActualRangeError && (
                <span className="error-message">
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  Please select a customer facing price range
                </span>
              )}
            <div className="price-ranges-container">
              {nonActualPriceRanges.map(range => 
                renderPriceRange(range, selectedNonActualRange === range.id, false)
              )}
            </div>
          </div>
        )}
      </div>
 
      <style jsx>{`
        .price-ranges-wrapper {
          margin: 4px;
          padding: 0;
          width: 100%;
        }

        .price-ranges-grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 8px;
          width: 100%;
        }

        .price-range-section {
          background-color: ${style.Color.colorLightBeige};
          border-radius: 8px;
          padding: 16px;
          width: 100%;
        }
        
        .section-title {
          font-size: 18px;
          color: ${style.Color.colorPurple};
          margin-bottom: 15px;
          padding: 0 16px;
        }

        .required {
          color: red;
          margin-left: 4px;
        }
        
        .price-ranges-container {
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 100%;
          padding: 0 16px;
        }
        
        .price-range-item {
          background-color: ${style.Color.colorWhite};
          border-radius: 8px;
          padding: 15px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          position: relative;
          cursor: pointer;
          transition: all 0.3s ease;
          width: 100%;
        }
        
        .price-range-item:not(.disabled):hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
        }
        
        .price-range-item.selected {
          background-color: ${style.Color.colorAppleGreen};
          border: 2px solid ${style.Color.colorMintGreen};
        }
        
        .price-range-item.disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
        
        .price-range-item.disabled:hover {
          transform: none;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        
        .price-range-header {
          font-weight: bold;
          margin-bottom: 10px;
          color: ${style.Color.colorDarkBlue};
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .price-range-details {
          margin: 10px 0;
        }

        .price-detail {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 5px;
        }

        .price-detail.total {
          margin-top: 10px;
          font-weight: bold;
          color: ${style.Color.colorDarkBlue};
        }

        .selected-indicator {
          position: absolute;
          top: 10px;
          right: 10px;
          color: ${style.Color.colorMintGreen};
        }

        .price-range-footer {
          font-style: italic;
          color: ${style.Color.colorDarkGrey};
          font-size: 14px;
          margin-top: 8px;
        }
          
        .price-ranges-grid {
          grid-template-columns: 1fr;
        }

        .section-title {
          font-size: 18px;
          color: ${style.Color.colorPurple};
          margin-bottom: 15px;
          padding: 0 16px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 8px;
        }

        @media (max-width: 768px) {          
          .section-title {
            flex-direction: column;
            align-items: flex-start;
          }

          .error-message {
            margin-left: 40px;
            margin-top: 4px;
          }
        }
      `}</style>
    </div>
  );
};

export default PriceRangesSelection;