import React, { useState } from 'react';
import { ChevronDown, ChevronUp, UserCheck, UserX } from 'lucide-react';
import * as style from "../../GlobalStyles";

const WaitlistSection = ({ waitlistUsers = [], onConfirmBuyers }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleUserSelection = (userId) => {
    setSelectedUsers(prev => 
      prev.includes(userId)
        ? prev.filter(id => id !== userId)
        : [...prev, userId]
    );
  };

  const handleConfirmBuyers = () => {
    if (selectedUsers.length > 0) {
      onConfirmBuyers(selectedUsers);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md mt-4 p-4">
      <button  onClick={() => setIsExpanded(!isExpanded)} className="w-full flex items-center justify-between p-2 text-left font-semibold text-gray-700 hover:bg-gray-50 rounded-md" >
        <div className="flex items-center gap-2">
          <span>Waitlisted Users ({waitlistUsers.length})</span>
        </div>
        {isExpanded ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
      </button>

      {isExpanded && (
        <div className="mt-4">
          <div className="space-y-2">
            {waitlistUsers.map((waitlistUser) => (
              <div
                key={waitlistUser.id}
                className="flex items-center justify-between p-3 bg-gray-50 rounded-md"
              >
                <div className="flex items-center gap-3">
                  <input
                    type="checkbox"
                    checked={selectedUsers.includes(waitlistUser.id)}
                    onChange={() => handleUserSelection(waitlistUser.id)}
                    className="h-4 w-4 rounded border-gray-300"
                  />
                  <div>
                    <div className="flex items-center gap-2">
                      <span className="font-medium">
                        {waitlistUser.user.first_name} {waitlistUser.user.last_name}
                      </span>
                      {waitlistUser.user.verified ? (
                        <UserCheck className="h-4 w-4 text-green-500" />
                      ) : (
                        <UserX className="h-4 w-4 text-red-500" />
                      )}
                    </div>
                    <span className="text-sm text-gray-500">{waitlistUser.user.email}</span>
                  </div>
                </div>
                <div className="flex flex-col items-end">
                  <span className="text-sm font-medium">
                    Qty: {waitlistUser.quantity}
                  </span>
                  <span className="text-xs text-gray-500">
                    Joined: {new Date(waitlistUser.joined_at).toLocaleDateString()}
                  </span>
                </div>
              </div>
            ))}
          </div>

          {waitlistUsers.length > 0 ? (
            <button
              onClick={handleConfirmBuyers}
              disabled={selectedUsers.length === 0}
              className="mt-4 w-full bg-green-600 text-white py-2 px-4 rounded-md font-medium hover:bg-green-700 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors duration-200"
            >
              Confirm Selected Buyers ({selectedUsers.length})
            </button>
          ) : (
            <div className="text-center text-gray-500 py-4">
              No users in waitlist
            </div>
          )}
        </div>
      )}
    </div>
  );
};


const additionalStyles = `
  .waitlist-section {
    background-color: ${style.Color.colorWhite};
    border-radius: 8px;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .waitlist-header {
    padding: 15px;
    background-color: ${style.Color.colorLightBeige};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .waitlist-content {
    padding: 15px;
  }

  .waitlist-user {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid ${style.Color.colorLightGrey};
  }

  .waitlist-user:last-child {
    border-bottom: none;
  }

  .waitlist-user-checkbox {
    margin-right: 15px;
  }

  .waitlist-user-info {
    flex-grow: 1;
  }

  .waitlist-user-name {
    font-weight: bold;
    color: ${style.Color.colorDarkBlue};
  }

  .waitlist-user-email {
    font-size: 14px;
    color: ${style.Color.colorDarkGrey};
  }

  .waitlist-user-quantity {
    font-weight: bold;
    color: ${style.Color.colorPurple};
  }

  .waitlist-confirm-button {
    background-color: ${style.Color.colorMintGreen};
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    margin-top: 15px;
  }

  .waitlist-confirm-button:disabled {
    background-color: ${style.Color.colorLightGrey};
    cursor: not-allowed;
  }

  .waitlist-confirm-button:hover:not(:disabled) {
    background-color: ${style.Color.colorAppleGreen};
  }
`;
export default WaitlistSection;