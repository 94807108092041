import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Config from "../../../config"; 
import {useAuth} from '../../Common/InitialGears/AuthContext'; 
import { Color } from "../../../GlobalStyles"; 
import cancelDetails from '../../../assets/images/cancel.png';   
import GooglePlacesAutocomplete  from 'react-google-places-autocomplete';
import phoneCountryCodeToCountry from '../../Common/InitialGears/PhoneCountryCodeToCountry';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


const AddWishModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { queryLoginParam, idTokenLoggedIn, countryCodeLoggedIn } = useAuth();
  const [productName, setProductName] = useState('');
  const [brandPreference, setBrandPreference] = useState('');
  const [messages, setMessages] = useState([]);
  const [quantity, setQuantity] = useState('1');
  const [zipCode, setZipCode] = useState('');
  const [deliveryCity, setDeliveryCity] = useState('');
  const [state, setStateAbbreviation] = useState('');
  const [zipCodeError, setZipCodeError] = useState('');
  const [isValidZipCode, setIsValidZipCode] = useState(false);
  const [showZipCodeMessage, setShowZipCodeMessage] = useState(false);
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [loading, setLoading] = useState(false);
  const [remainingWishes, setRemainingWishes] = useState(0);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [productType, setProductType] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showProductTypeInput, setShowProductTypeInput] = useState(true);
  const [askBrandInput, setAskBrandInput] = useState(false);
  const [showBrandInput, setShowBrandInput] = useState(false);
  const [showSubmitSection, setShowSubmitSection] = useState(true);
  const scrollViewRef = useRef(null);
  const countryRestriction = phoneCountryCodeToCountry[countryCodeLoggedIn] || 'us';
  const [selectedPlace, setSelectedPlace] = useState(null);

  useEffect(() => {
    getWishesCount();
  }, []);

  const removeQuotes = (text) => {
    return text.replace(/['"]/g, '');
  };

  const handleQuantityChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '');
    setQuantity(numericValue === '0' ? '1' : numericValue);
  };

  const handleProductTypeInput = async () => {
    const sanitizedProductName = removeQuotes(productName);
    setMessages([...messages, { type: 'user', text: sanitizedProductName }]);
    try {
      const isInappropriate = await checkForInappropriateContent(sanitizedProductName);
      if (isInappropriate) {
        addBotMessage('Sorry, your input contains inappropriate content. Please try again with a different product name.');
        return;
      }
      const normalizedProductName = await normalizeProductName(sanitizedProductName);
      setProductName(normalizedProductName);
      if (brandPreference) {
        setAskBrandInput(false);
        setShowBrandInput(true);
      } else {
        setAskBrandInput(true);
        setShowProductTypeInput(false);
      }
    } catch (error) {
      addBotMessage('Sorry, we couldn\'t process your product name. Please try again.');
    }
  };

  const checkForInappropriateContent = async (text) => { 
    const result = await fetch('https://api.openai.com/v1/moderations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Config.openaiKey}`
      },
      body: JSON.stringify({
        input: text
      })
    });
  
    const json = await result.json();
    return json.results[0].flagged;
  };

  const normalizeProductName = async (name) => {
    const prompt = `Normalize the following product name for consistency and ensure it is in singular form: "${name}"`;
    const result = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Config.openaiKey}`
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [
          { role: "system", content: "You are a helpful assistant tasked with normalizing product names for consistency." },
          { role: "user", content: prompt }
        ],
        temperature: 0.2
      })
    });

    const json = await result.json();
    return json.choices[0].message.content.trim().replace(/^Normalized product name:\s*/i, '');
  };

  const handleShowBrandInput = () => {
    setAskBrandInput(false);
    setShowBrandInput(true);
  };

  const resetBack = () => {
    setProductName('');
    setBrandPreference('');
    setCategory('');
    setSubCategory('');
    setProductType('');
    setQuantity('1');
    setAdditionalDetails('');
    setShowConfirmation(false);
    setSuccessMessage(null);
    setErrorMessage(null);
    setDeliveryCity('');
    setZipCode('');
    setStateAbbreviation('');
    setZipCodeError('');
    setIsValidZipCode(false);
    setShowZipCodeMessage(false);
    setShowProductTypeInput(true);
    setShowBrandInput(false);
    setShowSubmitSection(true);
    setAskBrandInput(false); 
    addBotMessage('Great! What else would you like to wish for?');    
  };

  const addBotMessage = (message) => {
    setMessages(prevMessages => [...prevMessages, { type: 'bot', text: message }]);
  };

  const handleSkip = async () => {
    setShowBrandInput(false); 
    setAskBrandInput(false);
    handleSubmit();
  };

  const extractProductName = (input) => {
    const cleanedInput = input.toLowerCase()
      .replace(/i need a/i, '')
      .replace(/i want a/i, '')
      .replace(/can i get a/i, '')
      .replace(/looking for a/i, '')
      .trim();
  
    const words = cleanedInput.split(' ');
    return words.length > 1 ? words.slice(-2).join(' ') : words[0];
  };
  
  const handleSubmit = async () => {
    setShowBrandInput(false);
    if(brandPreference){
      setMessages([...messages, { type: 'user', text: brandPreference }]);
    }
    if (remainingWishes === 0) {
      setMessages([...messages, { type: 'bot', text: 'Sorry, you have reached the maximum number of wishes allowed in 24 hours.' }]);
      return;
    }
    setLoading(true);
    const extractedProductName = extractProductName(productName);

    if (!extractedProductName) {
      setLoading(false);
      addBotMessage('Sorry, we couldn\'t understand your product. Please try again with a clear product name.');
      resetBack();
      if (remainingWishes > 1) {
        setShowProductTypeInput(true);
        setIsValidZipCode(false);
        setZipCode('');
        setDeliveryCity('');
      }
      return;
    }
    let prompt = `Classify the following product into a category, sub-category, and product type. Be as specific as possible, even for generic terms. If unsure, provide the most likely classification based on common usage:\nProduct: ${extractedProductName}`;
    
    if (productName) prompt += `\nProduct Name: ${productName}`;
    if (brandPreference) prompt += `\nBrand Preference: ${brandPreference}`;
    
    try {
      const result = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Config.openaiKey}`
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            { role: "system", content: "You are a helpful assistant tasked with classifying product details into categories, sub-categories, and product types. Focus on categorizing only high-value products." },
            { role: "user", content: prompt }
          ],
          temperature: 0.3
        })
      });
       
      const json = await result.json(); 
      const allResponses = json.choices.map(choice => choice.message.content).join('\n');
      const responseLines = allResponses.split('\n');  
      setRemainingWishes(remainingWishes - 1);
      if (responseLines.length >= 3) {
        setCategory(responseLines[0].replace('Category:', '').trim());
        setSubCategory(responseLines[1].replace('Sub-category:', '').trim());
        setProductType(responseLines[2].replace('Product Type:', '').trim());
        setShowConfirmation(true);
      } else {
        if(remainingWishes > 1){
          resetBack(); 
        }
        addBotMessage('Sorry, we couldn\'t classify your wish. Please try again.');                 
      }
    } catch (error) {  
      addBotMessage('Sorry, we couldn\'t handle your wish. Please try again.'); 
      resetBack(); 
      if(remainingWishes >= 1){
        setShowProductTypeInput(true);
        setIsValidZipCode(false);
      }
    } finally {
      countWishesAllowed();
      setLoading(false);
      scrollToLatestMessage();
    }
  };

  const nextAskDecider = async () => {
    if(remainingWishes >0) {
      resetBack();
      addBotMessage('Share with me a product you\'ve been eyeing and want to purchase at a discount.');
    }else{
      addBotMessage("Looks like you've used all your wishes for today. Come back tomorrow for more wishing!");
    }
  }

  const confirmDetails = async () => {
    setLoading(true);
    setShowProductTypeInput(false);
    setShowBrandInput(false);
    setShowSubmitSection(false);
    setAskBrandInput(false); 
     
    try {
      const addAIWishURL = `${Config.apiUrl}addAIWish?${queryLoginParam}`; 
      const headers = { 'Authorization': idTokenLoggedIn, 'Content-Type': 'application/json' ,  'Origin': window.location.origin };
      const requestBody = {
        category: category,
        subcategory: subCategory,
        productType: productType,
        product_name: productName,
        brand_preference: brandPreference,
        quantity: quantity,
        zipCode: zipCode,
        deliveryCity: deliveryCity,
        state: state,
        additional_details: additionalDetails.slice(0, 100)
      };   
      const response = await fetch(addAIWishURL, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestBody),
        mode: 'cors',  
      credentials: 'omit' 
      });
      let AIWishAddedResponse = await response.json();
        
      if (AIWishAddedResponse.matching_deals && AIWishAddedResponse.matching_deals.length > 0) {
        setSuccessMessage('Deals found! Redirecting to Deals Dashboard...'); 
        handleDealsFound(productName, AIWishAddedResponse.matching_deals);
        handleDealsFound(productName, AIWishAddedResponse.matching_deals);
        nextAskDecider(); 
        setErrorMessage(null); 
      } else {
        setErrorMessage(null);
        setSuccessMessage(AIWishAddedResponse.msg);
        addBotMessage(AIWishAddedResponse.msg || 'Your wish has been added successfully.');
        getWishesCount(); 
        nextAskDecider();          
        setProductName('');
        setBrandPreference('');
      }
    } catch (error) { 
      setSuccessMessage(null); 
      addBotMessage('Sorry, we couldn\'t handle your wish. Please try again.');
      setShowSubmitSection(true);
      setShowProductTypeInput(true);
      setIsValidZipCode(false);
    } finally {
      setLoading(false);
      setShowConfirmation(false); 
      scrollToLatestMessage();
    }
  };

  const getWishesCount = async () => {
    try {
      let wishesCountURL = `${Config.apiUrl}getWishesAllowed?${queryLoginParam}`;
      const headers = { 'Authorization': idTokenLoggedIn, 'Content-Type': 'application/json' };
      const wishesCountResponse = await fetch(wishesCountURL, {headers} );

      const responseData = await wishesCountResponse.json();
      if (wishesCountResponse.ok) {
        if (responseData.remaining_wishes !== undefined) {
          setRemainingWishes(responseData.remaining_wishes);
        }
      } else {
        setErrorMessage("Something went wrong. Please try later"); 
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try later"); 
    }
  };

  const countWishesAllowed = async () => {
    try {
      let wishesCountURL = `${Config.apiUrl}countWishesAllowed?${queryLoginParam}`;
      const headers = { 'Authorization': idTokenLoggedIn, 'Content-Type': 'application/json' };
      const body = {}; 
      const wishesCountResponse = await fetch(wishesCountURL, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
        mode: 'cors',  
      credentials: 'omit' 
      });
      const responseData = await wishesCountResponse.json();
      if (wishesCountResponse.ok) {
        if (responseData.remaining_wishes !== undefined) {
          setRemainingWishes(responseData.remaining_wishes);
        }
      } else {
        setErrorMessage("Something went wrong. Please try later"); 
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try later"); 
    }
  };

  const handleDealsFound = (productName, matchingDeals) => {
    if (matchingDeals && matchingDeals.length > 0) {
      addBotMessage(`Deals found for ${productName}!`);
      const message = `Click here to view`;
      addBotMessage(
        <DealLink onClick={() => navigate('/deal-dashboard', { state: { category: category, fromAIWish: true, matching_deals: matchingDeals } })}>
          Click here to view "{productName}" deals
        </DealLink>
      );
    } else {
      addBotMessage(`No deals found for ${productName} at the moment. We'll notify you when deals become available.`);
    }
  };

  const handleGooglePlacesSelect = async (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setSelectedPlace(selectedOption);
      const placeId = selectedOption.value.place_id;
      try {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${Config.googleMapsAPIKEY}`);
        const data = await response.json();
        if (data.results && data.results.length > 0) {
          const addressComponents = data.results[0].address_components;
          const postalCodeComponent = addressComponents.find(component => component.types.includes('postal_code'));
          const cityComponent = addressComponents.find(component => component.types.includes('locality'));
          const stateComponent = addressComponents.find(component => component.types.includes('administrative_area_level_1'));

          if (postalCodeComponent) {
            setZipCode(postalCodeComponent.long_name);
            setIsValidZipCode(true);
            setShowZipCodeMessage(false);
          } else {
            setIsValidZipCode(false);
            setShowZipCodeMessage(true);
          }

          if (cityComponent) {
            setDeliveryCity(cityComponent.long_name);
          }

          if (stateComponent) {
            setStateAbbreviation(stateComponent.short_name);
          }
        } else {
          setIsValidZipCode(false);
          setShowZipCodeMessage(true);
        }
      } catch (error) {
        //console.error('Error fetching place details:', error);
        setIsValidZipCode(false);
        setShowZipCodeMessage(true);
      }
    } else {
      setIsValidZipCode(false);
      setShowZipCodeMessage(true);
    }
  };

  const scrollToLatestMessage = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (!isOpen) return null;
  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>Tell a Wish</ModalTitle>
          <CloseButton onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </CloseButton>
        </ModalHeader>
        <ModalBody>
          <NoteContainer>
            <NoteText>
              You can make up to 3 wishes in the next 24 hours. ({remainingWishes} remaining)
            </NoteText>
            <NoteText>
              Enter your Product Type in the box
            </NoteText>
          </NoteContainer>
          <ChatContainer>
            <ChatHistory ref={scrollViewRef}>
              {messages.length === 0 ? (
                <EmptyChatMessage>Start sharing your wish by entering a product below.</EmptyChatMessage>
              ) : (
                messages.map((message, index) => (
                  <MessageWrapper key={index} type={message.type}>
                    <MessageContainer type={message.type}>
                      {typeof message.text === 'string' ? (
                        <MessageText type={message.type}>{message.text}</MessageText>
                      ) : (
                        message.text
                      )}
                    </MessageContainer>
                  </MessageWrapper>
                ))
              )}
            </ChatHistory>
          </ChatContainer>
          {showConfirmation && (
            <ConfirmationContainer>
              <ConfirmationHeadingText>Confirming your wish:</ConfirmationHeadingText>
              <ConfirmationRow>
                <ConfirmationSubHeadingText>It's a/an</ConfirmationSubHeadingText>
                <SpacerSpan>&nbsp;</SpacerSpan>
                <ConfirmationText> {category}</ConfirmationText>
              </ConfirmationRow>
              <ConfirmationRow>
                <ConfirmationSubHeadingText>Can be considered as a </ConfirmationSubHeadingText>
                <SpacerSpan>&nbsp;</SpacerSpan>
                <ConfirmationText>{subCategory}</ConfirmationText>
              </ConfirmationRow>
              <ConfirmationRow>
                <ConfirmationSubHeadingText>Specially a </ConfirmationSubHeadingText>
                <SpacerSpan>&nbsp;</SpacerSpan>
                <ConfirmationText>{productType}</ConfirmationText>
              </ConfirmationRow>
              <QuantityContainer>  
                <ConfirmationSubHeadingText>Quantity: </ConfirmationSubHeadingText>
                <QuantityInput
                  type="number"
                  value={quantity}
                  onChange={handleQuantityChange}
                  min="1"
                  max="999"
                />
              </QuantityContainer>          
              <QuantityContainer>
                <ConfirmationSubHeadingText>Ship To Zipcode: </ConfirmationSubHeadingText>
                <GooglePlacesAutocomplete
                  apiKey={Config.googleMapsAPIKEY}
                  selectProps={{
                    value: zipCode,
                    onChange: handleGooglePlacesSelect,
                    placeholder: 'Enter your ZIP code',
                  }}
                  autocompletionRequest={{
                    types: ['(regions)'],
                    componentRestrictions: { country: [countryRestriction] }
                  }}
                />
              </QuantityContainer>
              {zipCodeError && <ErrorText>{zipCodeError}</ErrorText>}
              {showZipCodeMessage && <ErrorText>Invalid ZIP code</ErrorText>}
              <AdditionalDetailsInput
                placeholder="Additional Details (up to 100 characters)"
                value={additionalDetails}
                onChange={(e) => setAdditionalDetails(e.target.value.slice(0, 100))}
                maxLength={100}
              />
              <ButtonContainer>
                <CancelButton onClick={cancelDetails} disabled={loading}>
                  Cancel
                </CancelButton>
                {isValidZipCode && (
                  <ConfirmButton onClick={confirmDetails} disabled={loading}>
                    Confirm
                  </ConfirmButton>
                )}
              </ButtonContainer>
            </ConfirmationContainer>
          )}
          {showSubmitSection && (
            <SubmitSection>
              {showProductTypeInput && (
                <InputContainer>
                  <ChatInput 
                    type="text" 
                    placeholder="Product Type" 
                    value={productName} 
                    onChange={(e) => setProductName(e.target.value.slice(0, 25))} 
                    maxLength={25}
                  />
                  <SubmitButton onClick={handleProductTypeInput}>
                    Send
                  </SubmitButton>
                </InputContainer>
              )}
              {askBrandInput && (
                <InputContainer>
                  <SubmitButton onClick={handleShowBrandInput}>
                    Specific Brand
                  </SubmitButton>
                  <SubmitButton onClick={handleSkip}>
                    Skip & Continue
                  </SubmitButton>
                </InputContainer>
              )}
              {showBrandInput && (
                <InputContainer>
                  <ChatInput 
                    type="text" 
                    placeholder="Brand" 
                    value={brandPreference} 
                    onChange={(e) => setBrandPreference(e.target.value.slice(0, 25))} 
                    maxLength={25} 
                  />
                  <SubmitButton onClick={handleSubmit}>
                    Send
                  </SubmitButton>
                </InputContainer>
              )}
            </SubmitSection>
          )}
        </ModalBody>
      </ModalContent>
      {loading && (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      )}
    </ModalOverlay>
  );
};

// Styled components
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: ${Color.colorCoolBeige};
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: ${Color.colorLightestBeige};
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: ${Color.colorLightBeige};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const ModalTitle = styled.h2`
  margin: 0;
  color: ${Color.colorDarkGrey};
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: ${Color.colorDarkGrey};
`;

const ModalBody = styled.div`
  flex: 1;
  padding: 16px;
  overflow-y: auto;
`;
const EmptyChatMessage = styled.p`
  color: ${Color.colorDarkGrey};
  text-align: center;
  margin: auto;
`;

const SpacerSpan = styled.span`
  margin: 0 4px;
 ` ;


const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: ${Color.colorLightBeige};
  position: relative;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: ${Color.colorDarkGrey};
  z-index: 1;
`;

const PageTitle = styled.h1`
  font-size: 24px;
  color: ${Color.colorDarkGrey};
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
`;

const PlaceholderDiv = styled.div`
  width: 50px;
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const NoteContainer = styled.div`
  background-color: ${Color.colorLightBeige};
  padding: 8px 16px;
`;

const ChatContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${Color.colorFillBeige};
  overflow: hidden;
`;

const ChatHistory = styled.div`
  flex: 1;
  padding: 16px;
  overflow-y: auto;
`; 

const NoteText = styled.p`
  font-size: 14px;
  color: ${Color.colorDarkGrey};
`;

const MessageContainer = styled.div`
  margin: 8px 0;
  max-width: 80%;
  padding: 8px 12px;
  border-radius: 16px;
  align-self: ${props => props.type === 'bot' ? 'flex-start' : 'flex-end'};
  background-color: ${props => props.type === 'bot' ? Color.colorLightBeige : Color.colorBlueGrey};
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.type === 'user' ? 'flex-end' : 'flex-start'};
  margin-bottom: 8px;
`;
const MessageText = styled.p`
  font-size: 16px;
  color: ${props => props.type === 'bot' ? Color.colorDarkGrey : Color.colorRoyalBlueText};
`;

const DealLink = styled.a`
  color: ${Color.colorPurple};
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.3s ease;

  &:hover {
    color: ${Color.colorDarkPurple};
  }
`;

const ConfirmationContainer = styled.div`
  background-color: ${Color.colorLightBeige};
  padding: 16px;
  border-radius: 8px;
  margin: 16px;
`;

const ConfirmationHeadingText = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: ${Color.colorDarkGrey};
  margin-bottom: 8px;
`;

const ConfirmationRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ConfirmationSubHeadingText = styled.p`
  font-size: 14px;
  color: ${Color.colorDarkGrey};
  margin-bottom: 8px;
`;

const ConfirmationText = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${Color.colorDarkGrey};
  margin-bottom: 8px;
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
`;

const QuantityInput = styled.input`
  border: 1px solid ${Color.colorMixGrey};
  border-radius: 8px;
  padding: 8px 12px;
  margin-left: 2%;
  background-color: ${Color.colorLightestBeige};
  width: 60px;
  text-align: center;
`;

const AdditionalDetailsInput = styled.textarea`
  width: 100%;
  height: 80px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: ${Color.colorWhite};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  background-color: ${Color.colorPurple};
  padding: 8px 16px;
  border-radius: 16px;
  color: ${Color.colorWhite};
  font-size: 16px;
`;

const CancelButton = styled(Button)``;

const ConfirmButton = styled(Button)`
  margin-left: 30%;
`;

const SubmitSection = styled.div`
  background-color: ${Color.colorLightBeige};
  padding: 8px 16px;
`;

const InputContainer = styled.div`
  display: flex;
  margin-top: 16px;
`;

const ChatInput = styled.input`
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 0 16px;
  background-color: ${Color.colorLightestBeige};
`;

const SubmitButton = styled(Button)`
  margin: 0 30px;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const LoadingSpinner = styled.div`
  animation: spin 1s linear infinite;
  border: 2px solid #f3f3f3;
  border-top: 2px solid ${Color.colorPurple};
  border-radius: 50%;
  width: 32px;
  height: 32px;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ErrorText = styled.p`
  color: ${Color.colorRed};
  font-size: 14px;
  margin-top: 5px;
`;

const styles = {
header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    backgroundColor: Color.colorLightBeige,
    position: 'relative',
  },
  backButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    color: Color.colorDarkGrey,
    zIndex: 1,
  },
  pageTitle: {
    fontSize: '24px',
    color: Color.colorDarkGrey,
    margin: 0,
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    width: '100%',
  },
  placeholder: {
    width: '50px',  
  },
}
export default AddWishModal;