import React, { useState } from 'react';

const MediaGallery = ({ mainImageUrl, additionalMedia = [] }) => {
  const [selectedMedia, setSelectedMedia] = useState({ url: mainImageUrl, type: 'IMAGE' });
  
  // Combine main image with additional media
  const allMedia = [
    { uuid: 'main', media_url: mainImageUrl, media_type: 'IMAGE' },
    ...additionalMedia
  ];

  const handleMediaSelect = (media) => {
    setSelectedMedia({
      url: media.media_url,
      type: media.media_type
    });
  };

  return (
    <div className="flex flex-col w-full">
      {/* Main display area */}
      <div className="w-full aspect-square bg-gray-100 rounded-lg mb-4 overflow-hidden flex items-center justify-center">
        {selectedMedia.type === 'VIDEO' ? (
          <video 
            className="w-full h-full object-contain"
            controls
            src={selectedMedia.url}
          >
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            src={selectedMedia.url}
            alt="Selected media"
            className="w-full h-full object-contain"
          />
        )}
      </div>

      {/* Thumbnail strip */}
      <div className="w-full overflow-x-auto">
        <div className="flex space-x-2 p-2">
          {allMedia.map((media, index) => (
            <div
              key={media.uuid || index}
              onClick={() => handleMediaSelect(media)}
              className={`flex-shrink-0 w-16 h-16 rounded-md overflow-hidden cursor-pointer border-2 
                ${selectedMedia.url === media.media_url ? 'border-blue-500' : 'border-transparent'}`}
            >
              {media.media_type === 'VIDEO' ? (
                <div className="relative w-full h-full">
                  <video 
                    src={media.media_url}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30">
                    <div className="w-6 h-6 rounded-full border-2 border-white flex items-center justify-center">
                      <div className="w-0 h-0 border-t-4 border-t-transparent border-l-8 border-l-white border-b-4 border-b-transparent ml-1"></div>
                    </div>
                  </div>
                </div>
              ) : (
                <img
                  src={media.media_url}
                  alt={`Thumbnail ${index}`}
                  className="w-full h-full object-cover"
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MediaGallery;