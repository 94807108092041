import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const AdditionalMediaDisplay = ({ 
  additionalMedia = [], 
  onRemoveMedia,
  className = ''
}) => {
  if (!additionalMedia || additionalMedia.length === 0) {
    return null;
  }

  const handleRemove = (uuid) => {
    if (onRemoveMedia) {
      onRemoveMedia(uuid);
    }
  };

  return (
    <div className={`grid grid-cols-2 md:grid-cols-3 gap-4 p-4 ${className}`}>
      {additionalMedia.map((media) => (
        <div key={media.uuid} className="relative group">
          {media.media_type === 'IMAGE' ? (
            <div className="relative aspect-square">
              <img 
                src={media.media_url} 
                alt={media.title || 'Additional media'} 
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          ) : media.media_type === 'VIDEO' ? (
            <div className="relative aspect-video">
              <video 
                src={media.media_url}
                controls
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          ) : null}
          
          <button
            onClick={() => handleRemove(media.uuid)}
            className="absolute top-2 right-2 p-1 bg-black bg-opacity-50 rounded-full text-white 
                       opacity-0 group-hover:opacity-100 transition-opacity duration-200"
          >
            <FontAwesomeIcon icon={faTrash} className="h-4 w-4" />
          </button>
        </div>
      ))}
    </div>
  );
};

export default AdditionalMediaDisplay;