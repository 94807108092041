import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Config from "../../../config"; 
import { useAuth } from '../../../components/Common/InitialGears/AuthContext'; 
import * as style from "../../../GlobalStyles";  

const SentInvites = () => {
  const navigate = useNavigate();
  const { queryLoginParam, idTokenLoggedIn } = useAuth();
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [groupWishBasedData, setGroupWishBasedData] = useState([]);
  const [userBasedData, setUserBasedData] = useState([]);
  const [expandedItemId, setExpandedItemId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState('group_wish');

  useEffect(() => {
    fetchInvites();
  }, [idTokenLoggedIn]);

  const fetchInvites = async () => {
    setLoading(true);
    let apiUrl = `${Config.apiUrl}externalInvitesSent?${queryLoginParam}`;
    const headers = {
      'Authorization': idTokenLoggedIn,
      'Content-Type': 'application/json',
    };
  
    try {
      const response = await fetch(apiUrl, { headers });
      const fetchedData = await response.json(); 
      if (!response.ok) {
        throw new Error('Failed to fetch invites');
      }

      setGroupWishBasedData(fetchedData.group_wish_based_data || []);
      setUserBasedData(fetchedData.user_based_data || []);
      setLoading(false);
    } catch (error) {
      //console.error('Error fetching invites:', error);
      setLoadingError(true);
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleItem = (itemId) => {
    setExpandedItemId(expandedItemId === itemId ? null : itemId);
  };

  const parseAndFormatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
  };

  const renderStatus = (item) => {
    const formattedStartDate = parseAndFormatDate(item.invited_date);
    let statusText = '';
    let StatusIcon = null;

    switch (item.recipient_status) {
      case "ON_THE_WAY":
        statusText = "On the way";
        StatusIcon = () => <span className="text-lg">📤</span>;
        break;
      case "READ":
        statusText = "Read";
        StatusIcon = () => <span className="text-lg">📖</span>;
        break;
      case "DELIVERED":
        statusText = "Delivered";
        StatusIcon = () => <span className="text-lg">✅</span>;
        break;
      case "DELIVERY_FAILED":
        statusText = "Delivery Failed";
        StatusIcon = () => <span className="text-lg">❌</span>;
        break;
      case "ACCEPTED":
        statusText = "Accepted";
        StatusIcon = () => <span className="text-lg">👍</span>;
        break;
      default:
        statusText = item.recipient_status;
        StatusIcon = () => <span className="text-lg">❓</span>;
    }

    return (
      <div className="flex flex-col">
        <div className="flex items-center gap-2 ml-8">
          <div className="flex items-center gap-2">
            <StatusIcon />
            <span className="text-sm" style={{ color: style.Color.colorDarkBeige }}>
              {statusText}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full p-4 rounded-lg" style={{ backgroundColor: style.Color.colorLightBeige }}>
      <h1 className="text-2xl font-bold mb-6" style={{ color: style.Color.colorDarkBrown }}>
        Group Wish Invites I Sent
      </h1>
      
      {loading ? (
        <div className="flex justify-center py-8">
          <div className="w-8 h-8 border-4 rounded-full animate-spin" 
            style={{ 
              borderColor: style.Color.colorMidBeige,
              borderTopColor: style.Color.colorDarkBeige 
            }}>
          </div>
        </div>
      ) : loadingError ? (
        <div className="text-center py-4" style={{ color: style.Color.colorRed }}>
         No data found.
        </div>
      ) : (
        <>
          <div className="flex justify-center gap-4 mb-6">
            <button
              onClick={() => setViewMode('group_wish')}
              className="px-4 py-2 rounded-lg font-medium transition-colors"
              style={{
                backgroundColor: viewMode === 'group_wish' ? style.Color.colorPurple : style.Color.colorLightBeige,
                color: viewMode === 'group_wish' ? 'white' : style.Color.colorDarkBrown
              }}
            >
              Group Wish View
            </button>
            <button
              onClick={() => setViewMode('user')}
              className="px-4 py-2 rounded-lg font-medium transition-colors"
              style={{
                backgroundColor: viewMode === 'user' ? style.Color.colorPurple : style.Color.colorLightBeige,
                color: viewMode === 'user' ? 'white' : style.Color.colorDarkBrown
              }}
            >
              User View
            </button>
          </div>

          <div className="flex gap-2 mb-6">
            <input
              type="text"
              placeholder={viewMode === 'group_wish' ? "Search by group wish name" : "Search by user name"}
              value={searchTerm}
              onChange={handleSearch}
              className="flex-1 px-4 py-2 rounded-lg border"
              style={{ 
                borderColor: style.Color.colorMidBeige,
                backgroundColor: 'white'
              }}
            />
            <button 
              onClick={fetchInvites}
              className="px-4 py-2 rounded-lg transition-colors"
              style={{ backgroundColor: style.Color.colorPurple, color: 'white' }}
            >
              🔍
            </button>
          </div>

          <div className="flex flex-col gap-4">
            {groupWishBasedData.length > 0 && viewMode === 'group_wish' && (
              <h2 className="text-xl font-semibold ml-2" style={{ color: style.Color.colorDarkBrown }}>
                General
              </h2>
            )}
            
            {(viewMode === 'group_wish' ? groupWishBasedData : userBasedData).map((item) => (
              <div key={item.id || item.group_wish_id} className="rounded-lg p-4"
                style={{ backgroundColor: style.Color.colorLightBeige }}>
                <button 
                  onClick={() => toggleItem(item.id || item.group_wish_id)}
                  className="w-full text-left"
                >
                  <div className="flex items-center justify-between p-4 rounded-lg"
                    style={{ backgroundColor: style.Color.colorWhite }}>
                    <span className="text-lg font-medium" style={{ color: style.Color.colorRoyalBlueText }}>
                      {item.name || item.group_wish_name || 'Unnamed'}
                    </span>
                    <span style={{ color: style.Color.colorPurpleBlue }}>
                      {expandedItemId === (item.id || item.group_wish_id) ? '▲' : '▼'}
                    </span>
                  </div>
                </button>
                
                {expandedItemId === (item.id || item.group_wish_id) && (
                  <div className="mt-4">
                    {(item.receivers || [item]).map((receiver) => (
                      <div key={receiver.user_id} className="mb-4 p-4 rounded-lg" style={{ backgroundColor: style.Color.colorWhite }}>
                        
                        <div className="flex flex-col">
                          <div className="flex items-center gap-2 ml-8 mb-2">
                            <span className="text-lg">📅</span>
                            <span className="text-sm" style={{ color: style.Color.colorDarkBeige }}>
                              {parseAndFormatDate(receiver.invited_date)}
                            </span>
                          </div>
                          <div className="flex items-center gap-2 ml-8">
                            {renderStatus(receiver)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SentInvites;