// firebaseConfig.js
import { initializeApp } from "firebase/app"; 
import { getAuth, initializeAuth, browserLocalPersistence } from "firebase/auth";

const firebaseConfigDev = {
    apiKey: "AIzaSyB3gc3akEfOHogBu4I95w1S-WLAx6ntXfo",
    authDomain: "dev-backsapp.firebaseapp.com",
    projectId: "dev-backsapp",
    storageBucket: "dev-backsapp.firebasestorage.app",
    messagingSenderId: "712607729226",
    appId: "1:712607729226:web:e0c0e11db31b6a578f48b3",
    measurementId: "G-YF52MZV3B1"
};


const firebaseConfigProd = {
    apiKey: "AIzaSyD9ELN-nrcISMDRjyteno91zg_DbfIRY6Y",
    authDomain: "backsapp-6a943.firebaseapp.com",
    projectId: "backsapp-6a943",
    storageBucket: "backsapp-6a943.appspot.com",
    messagingSenderId: "238418349747",
    appId: "1:238418349747:web:c6a6bf8c3abfc6b7836ac6",
    measurementId: "G-QCW5PB84T6",
    databaseURL: "https://backsapp-5ccd0-default-rtdb.firebaseio.com/",
};

const firebaseConfigQa = {
    apiKey: "AIzaSyDVcHZok6DYzSEqvrTfHY15fbAzj8TlVpY",
    authDomain: "qa-backsapp.firebaseapp.com",
    projectId: "qa-backsapp",
    storageBucket: "qa-backsapp.appspot.com",
    messagingSenderId: "941302270590",
    appId: "1:941302270590:web:754bfda0c35c4c612ce62f",
    measurementId: "G-01EPNXZ32K",
    databaseURL: "https://backsapp-5ccd0-default-rtdb.firebaseio.com/",
};


let app;
let auth;

// Initialize Firebase with configuration
export const initializeFirebaseApp = async () => {
    const env = process.env.REACT_APP_ENV || 'qa';
    const config = {
        dev: firebaseConfigDev,
        qa: firebaseConfigQa,
        prod: firebaseConfigProd
    };

    try {
        if (!app) {
            const firebaseConfig = config[env] || firebaseConfigDev;
            app = initializeApp(firebaseConfig);
        }
        
        if (!auth) {
            auth = initializeAuth(app, {
                persistence: browserLocalPersistence,
            });
        }

        return auth;
    } catch (error) { 
        throw error;
    }
};

// Function to ensure Firebase is initialized
export const ensureFirebaseInit = async () => {
    if (!auth) {
        return await initializeFirebaseApp();
    }
    return auth;
};

// Function to get Firebase Auth instance
export const getFirebaseAuth = () => {
    if (!auth) {
        throw new Error('Firebase Auth not initialized. Call ensureFirebaseInit first.');
    }
    return auth;
};

// Initialize Firebase on module load
initializeFirebaseApp() 

// Export auth instance
export { auth }; 