import React, { useState, useRef, useEffect } from 'react';
import { signInWithEmailAndPassword, sendPasswordResetEmail, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth"; 
import { auth, getFirebaseAuth } from '../../../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Phone, UserPlus, Eye, EyeOff, ArrowLeft, Mail } from 'lucide-react';
import Config from "../../../config";
import { setAuthToken } from './authLoginUtils';

const LoginPage = ({ onClose, setLoginType, loginType }) => {
  // Email login states
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showReferralRequest, setShowReferralRequest] = useState(false);
  
  // Phone login states
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOTP] = useState(['', '', '', '', '', '']);
  const [showOTP, setShowOTP] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({ 
    code: 'US', 
    callingCode: '+1', 
    flag: 'https://flagcdn.com/us.svg' 
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const authRef = useRef(null);

  // Shared states
  const navigate = useNavigate();
  const { login } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  useEffect(() => {
    if (loginType === 'phone') {
      authRef.current = getFirebaseAuth();
      clearRecaptcha();
      fetchCountries();
    }
    return () => {
      if (loginType === 'phone') {
        clearRecaptcha();
      }
    };
  }, [loginType]);

  const fetchCountries = () => {
    fetch("https://restcountries.com/v3.1/all")
      .then(response => response.json())
      .then(data => {
        const formattedCountries = data
          .map(country => ({
            code: country.cca2,
            name: country.name.common,
            callingCode: country.idd.root + (country.idd.suffixes?.[0] || ''),
            flag: `https://flagcdn.com/${country.cca2.toLowerCase()}.svg`
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
        setCountries(formattedCountries);
      })
      .catch(() => setErrorMessage('Failed to load country data'));
  };

  const clearRecaptcha = () => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
      window.recaptchaVerifier = null;
    }
  };

  const setupRecaptcha = async () => {
    try {
      const auth = authRef.current;
      if (!auth) throw new Error("Authentication not initialized");

      clearRecaptcha();

      // First, ensure the container exists and is properly styled
      let container = document.getElementById('recaptcha-container');
      if (!container) {
        container = document.createElement('div');
        container.id = 'recaptcha-container';
        document.body.appendChild(container);
      }

      // Clear existing content and create new recaptcha div
      container.innerHTML = '';
      const recaptchaDiv = document.createElement('div');
      recaptchaDiv.id = 'recaptcha';
      
      // Set required styles
      container.style.position = 'fixed';
      container.style.top = '0';
      container.style.left = '0';
      container.style.zIndex = '1000';
      container.style.opacity = '0';
      
      container.appendChild(recaptchaDiv);

      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {
        size: 'invisible',
        callback: () => {},
        'expired-callback': () => {
          setErrorMessage('reCAPTCHA expired. Please try again.');
          clearRecaptcha();
        }
      });

      await window.recaptchaVerifier.render();
      return window.recaptchaVerifier;
    } catch (error) {
      setErrorMessage('Error setting up verification. Please try again.');
      return null;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (loginType === 'phone') {
      if (showOTP) {
        await handleVerifyOTP(e);
      } else {
        await handleSendOTP(e);
      }
    } else {
      await handleEmailLogin(e);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^[0-9]*$/.test(value)) {
      setPhoneNumber(value);
      setErrorMessage('');
    }
  };
  
  const handleSendOTP = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    let retryCount = 0;
    const maxRetries = 2;

    const attemptSendOTP = async () => {
      try {
        const { knownUser, isBacksAppStaff, isBacksAppManager, firstName, userVerified, isMerchant, isMerchantVerified, company } = 
        await verifyKnownMerchant(phoneNumber);
      
        if (knownUser) {
          const auth = authRef.current;
          if (!auth) throw new Error("Authentication not initialized");
          if (!phoneNumber) {
            setErrorMessage('Please enter a phone number');
            return false;
          }

          const cleanedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
          const formattedNumber = `${selectedCountry.callingCode}${cleanedPhoneNumber}`;

          const verifier = await setupRecaptcha();
          if (!verifier) throw new Error('Failed to set up reCAPTCHA');

          const userCredential = await signInWithPhoneNumber(auth, formattedNumber, verifier);
          //window.userCredential = userCredential;
          const user = userCredential.user;
          const accessToken = await user.getIdToken();
          const uid = user.uid;
          
          if (!uid || !accessToken) {
            setErrorMessage("Something went wrong");
            return;
          }
          if (!uid || !accessToken) {
            setErrorMessage("Something went wrong");
            return;
          }
          
          setAuthToken(accessToken);
          await setLoginParams(accessToken, uid, email, isBacksAppStaff, isBacksAppManager, firstName, userVerified, isMerchant, isMerchantVerified, company);
          navigate('/dashboard');
          setShowOTP(true);
          setErrorMessage('');
          return true;
        }else{
          setErrorMessage("We couldn't find an account with this email address");
        }
      } catch (error) {
        handleFirebaseError(error);
        return false;
      }
    };

    while (retryCount < maxRetries) {
      const success = await attemptSendOTP();
      if (success) return;
      
      retryCount++;
      if (retryCount < maxRetries) {
        // Clear existing reCAPTCHA before retry
        clearRecaptcha();
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait before retry
      }
    }

    // If all retries failed, show refresh message
    setErrorMessage('Please refresh the page and try again.');
  };


  // Phone login handlers
  const handlePhoneLogin = async (e) => { 
    try {
      const { knownUser, isBacksAppStaff, isBacksAppManager, firstName, userVerified, isMerchant, isMerchantVerified, company } = 
        await verifyKnownMerchant(phoneNumber);
      
      if (knownUser) {
        const userCredential = await signInWithPhoneNumber(auth, email, password);
        const user = userCredential.user;
        const accessToken = await user.getIdToken();
        const uid = user.uid;
        
        if (!uid || !accessToken) {
          setErrorMessage("Something went wrong");
          return;
        }
        
        setAuthToken(accessToken);
        await setLoginParams(accessToken, uid, email, isBacksAppStaff, isBacksAppManager, firstName, userVerified, isMerchant, isMerchantVerified, company);
        navigate('/dashboard');
      } else {
        setErrorMessage("We couldn't find an account with this email address");
      }
    } catch (error) {
      //setErrorMessage(`Failed to sign in. Error: ${error.message}`);
      handleFirebaseError(error);
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    
    const otpValue = otp.join('');
    if (otpValue.length !== 6) {
      setErrorMessage('Please enter a valid 6-digit OTP');
      return;
    }

    try {
      if (!window.confirmationResult) {
        setErrorMessage('Session expired. Please request OTP again.');
        setShowOTP(false);
        return;
      }

      const result = await window.confirmationResult.confirm(otpValue);
      // User signed in successfully
      const user = result.user;
      const accessToken = await user.getIdToken();
      const uid = user.uid;
      
      if (!uid || !accessToken) {
        setErrorMessage("Something went wrong");
        return;
      }
      
      setAuthToken(accessToken);
      // For phone login, we might need different parameters or additional verification
      const defaultParams = {
        isBacksAppStaff: false,
        isBacksAppManager: false,
        firstName: '',
        userVerified: true,
        isMerchant: false,
        isMerchantVerified: false,
        company: ''
      }; 
    } catch (error) {
      // Handle TOO_MANY_ATTEMPTS_TRY_LATER error
      if (error?.message?.includes('TOO_MANY_ATTEMPTS_TRY_LATER') || 
          error?.error?.message === 'TOO_MANY_ATTEMPTS_TRY_LATER') {
        setErrorMessage('Too many attempts. Please try again after some time.');
        setShowOTP(false);
        setOTP(['', '', '', '', '', '']);
        clearRecaptcha();
        return;
      }

      // Handle other specific errors
      if (error.code === 'auth/invalid-verification-code') {
        setErrorMessage('Invalid OTP. Please try again.');
      } else if (error.code === 'auth/code-expired') {
        setErrorMessage('OTP expired. Please request a new one.');
        setShowOTP(false);
      } else if (error.code === 'auth/too-many-requests') {
        setErrorMessage('Too many attempts. Please try again after some time.');
        setShowOTP(false);
        setOTP(['', '', '', '', '', '']);
        clearRecaptcha();
      } else {
        setErrorMessage('Failed to verify OTP. Please try again.');
      }
    }
  };

  const handleOTPChange = (index, value) => {
    if (value && !/^\d*$/.test(value)) return;

    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    if (value !== '' && index < 5) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      if (nextInput) nextInput.focus();
    } else if (value === '' && index > 0) {
      const prevInput = document.getElementById(`otp-${index - 1}`);
      if (prevInput) prevInput.focus();
    }
  };

  // Email login handlers
  const handleEmailLogin = async (e) => {
    e.preventDefault();
    try {
      const { knownUser, isBacksAppStaff, isBacksAppManager, firstName, userVerified, isMerchant, isMerchantVerified, company } = 
        await verifyKnownMerchant(email);
      
      if (knownUser) {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        const accessToken = await user.getIdToken();
        const uid = user.uid;
        
        if (!uid || !accessToken) {
          setErrorMessage("Something went wrong");
          return;
        }
        
        setAuthToken(accessToken);
        await setLoginParams(accessToken, uid, email, isBacksAppStaff, isBacksAppManager, firstName, userVerified, isMerchant, isMerchantVerified, company);
        navigate('/dashboard');
      } else {
        setErrorMessage("We couldn't find an account with this email address, register with us.");
       
      }
    } catch (error) { 
      handleFirebaseError(error);
    }
  };


  const setLoginParams = async (accessToken, uid, email, isBacksAppStaff, isBacksAppManager, firstName, userVerified, isMerchant, isMerchantVerified, company) => {
    const success = await login(accessToken, uid, email, isBacksAppStaff, isBacksAppManager, firstName, userVerified, isMerchant, isMerchantVerified, company);
    if (!success) {
      throw new Error("Failed to verify merchant");
    }
  };

  const verifyKnownMerchant = async (identifier) => { 
    const isEmail = identifier.includes('@'); 
    const queryParams = isEmail ? 
      `loginEmail=true&email=${encodeURIComponent(identifier)}` :
      `loginEmail=false&phone=${encodeURIComponent(identifier)}`; 
    const verifyKnownMerchant = `${Config.apiUrl}verifyKnownMerchant?${queryParams}`; 
    try {
      const response = await fetch(verifyKnownMerchant, {
        headers: { 'Content-Type': 'application/json' }
      }); 
      if (!response.ok) {
        if (response.status === 500) {
          navigate('/register');
        }
      } else {
        const data = await response.json(); 

        const defaultResponse = {
          knownUser: false,
          isBacksAppStaff: false,
          isBacksAppManager: false,
          firstName: '',
          userVerified: false,
          isMerchant: false,
          isMerchantVerified: false,
          company: null,
        };
    
        // Merge API response with default values
        return {
          ...defaultResponse,
          knownUser: Boolean(data.user_exists),
          isBacksAppStaff: Boolean(data.isBacksAppStaff),
          isBacksAppManager: Boolean(data.isBacksAppManager),
          firstName: data.firstName || '',
          userVerified: Boolean(data.userVerified),
          isMerchant: Boolean(data.isMerchant),
          isMerchantVerified: Boolean(data.isMerchantVerified),
          company: data.company,
        };
      }
    } catch (error) { 
      throw error;
    }
  };


  const handleFirebaseError = (error) => {
    let message = 'Something went wrong. Try again Later'; 
    switch (error.code) {
      case 'auth/invalid-phone-number':
        message = 'Invalid phone number format. Please check the number.';
        break;
      case 'auth/invalid-credential':
        message = 'User name/Password incorrect';
        break;
      case 'auth/quota-exceeded':
        message = 'Too many attempts. Please try again later.';
        break;
      case 'auth/too-many-requests':
        message = 'Too many attempts. Please try again later.';
        break;
      case 'auth/invalid-phone-number':
        message = 'Invalid phone number format. Please check the number.';
        break;
      case 'auth/invalid-app-credential':
        message = 'Please refresh the page and try again.';
        break;
      case 'auth/quota-exceeded':
        message = 'Too many attempts. Please try again later.';
        break;
      case 'auth/too-many-requests':
        message = 'Too many attempts. Please try again later.';
        break;
      default:
        message = error.message || 'An error occurred. Please try again.';
    }
    
    setErrorMessage(message);
    clearRecaptcha();
  };
 
  if (showForgotPassword) {
    return (
      <ForgotPasswordForm 
        setLoginType={setLoginType} 
        onClose={onClose} 
        onBack={() => setShowForgotPassword(false)} 
      />
    );
  }
  const handleLoginTypeSwitch = () => {
    setLoginType(loginType === 'phone' ? 'email' : 'phone');
    setShowOTP(false);
    setOTP(['', '', '', '', '', '']);
    setPhoneNumber('');
    setEmail('');
    setPassword('');
    setErrorMessage('');
    clearRecaptcha();
  };


  return (
    <div className="w-full h-full flex items-center justify-center px-4 py-8">
      <div className="bg-white rounded-xl shadow-lg p-8 w-full max-w-md relative">
        {/* Navigation buttons */}
        <div className="absolute top-4 right-4 flex gap-2">
          
             
            {/*
            <button onClick={handleLoginTypeSwitch} className="flex items-center gap-2 px-4 py-2 rounded-lg bg-white text-rose-800 hover:bg-rose-50 border border-rose-200 transition-all duration-300" >
            {loginType === 'phone' ? <Mail className="w-5 h-5" /> : <Phone className="w-5 h-5" />}
            <span>{loginType === 'phone' ? 'Email Login' : 'Phone Login'}</span>
          </button>
            */}
        </div>

        <div className="mt-12">
          {/* Header */}
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-2xl font-bold text-gray-500">
              {loginType === 'email' ? 'Welcome Back' : 
               showOTP ? 'Enter OTP' : 'Phone Login'}
            </h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700 p-2 hover:bg-gray-100 rounded-full transition-colors">
              <ArrowLeft className="w-5 h-5" />
            </button>
          </div>

          {/* Error Message */}
          {errorMessage && (
            <div className="bg-red-50 border border-red-200 text-red-600 text-sm rounded-lg p-3 mb-4">
              {errorMessage}
            </div>
          )}

          {/* Recaptcha Container */}
          <div id="recaptcha-container"></div>

          <form onSubmit={handleFormSubmit} className="space-y-6">
            {loginType === 'email' ? (
              /* Email Login Form */
              <>
                <div className="space-y-1">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    id="login-email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                    className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:border-amber-500 focus:ring-1 focus:ring-amber-500 transition-colors"
                  />
                </div>
                
                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <div className="relative">
                    <input
                      id="current-password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter your password"
                      required
                      className="w-full px-4 py-3 pr-12 bg-white border border-gray-300 rounded-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:border-amber-500 focus:ring-1 focus:ring-amber-500 transition-colors"
                    />
                    <button type="button" onClick={() => setShowPassword(!showPassword)} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none" >
                      {showPassword ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
                    </button>
                  </div>
                </div>

                <button type="submit" className="w-full bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a]  text-white py-3 rounded-lg font-medium hover:opacity-90 transition-colors" >
                  Sign In
                </button>

                {/* Forgot Password Link */}
                <button type="button"  onClick={() => setShowForgotPassword(true)} className="w-full text-center mt-4 text-sm text-amber-700 hover:text-rose-800 transition-colors" >
                  Forgot your password?
                </button>
              </>
            ) : (
              /* Phone Login Form */
              !showOTP ? (
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Phone Number
                  </label>
                  <div className="flex gap-2">
                    {/* Country Select */}
                    <div className="relative">
                      <button type="button" onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="px-3 py-3 bg-white border border-gray-300 rounded-lg text-gray-800 hover:bg-gray-50 transition-all duration-300 flex items-center gap-2" >
                        <img src={selectedCountry.flag} alt={selectedCountry.code} className="w-5 h-4" />
                        <span>{selectedCountry.callingCode}</span>
                      </button>

                      {/* Country Dropdown */}
                      {isDropdownOpen && (
                        <div className="absolute top-full mt-1 w-64 max-h-48 overflow-y-auto bg-white rounded-lg shadow-lg z-50">
                          {countries.map((country) => (
                            <button
                              key={country.code}
                              type="button"
                              onClick={() => {
                                setSelectedCountry(country);
                                setIsDropdownOpen(false);
                              }}
                              className="w-full flex items-center gap-2 px-3 py-2 hover:bg-gray-50 transition-colors"
                            >
                              <img src={country.flag} alt={country.code} className="w-5 h-4" />
                              <span className="text-sm text-gray-700">{country.name} ({country.callingCode})</span>
                            </button>
                          ))}
                        </div>
                      )}
                    </div>

                    {/* Phone Input */}
                    <input
                      type="tel"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      placeholder="Enter phone number"
                      className="flex-1 px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:border-rose-500 focus:ring-1 focus:ring-rose-500 transition-colors"
                      required
                    />
                  </div>
                </div>
              ) : (
                /* OTP Input */
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Enter verification code
                  </label>
                  <div className="flex justify-between gap-2">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        id={`otp-${index}`}
                        type="text"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleOTPChange(index, e.target.value)}
                        className="w-12 h-12 text-center bg-white border border-gray-300 rounded-lg text-gray-900 focus:outline-none focus:border-rose-500 focus:ring-1 focus:ring-rose-500 transition-colors"
                        required
                      />
                    ))}
                  </div>
                </div>
              )
            )}

            {/* Submit Button for Phone Login */}
            {loginType === 'phone' && (
              <>
                <button
                  type="submit"
                  className="w-full bg-gradient-to-r from-amber-600 to-rose-800 text-white py-3 rounded-lg font-medium hover:opacity-90 transition-colors flex items-center justify-center gap-2"
                >
                  <Phone size={20} />
                  <span>{showOTP ? 'Verify OTP' : 'Send OTP'}</span>
                </button>

                {showOTP && (
                  <button
                    type="button"
                    onClick={() => {
                      setShowOTP(false);
                      setOTP(['', '', '', '', '', '']);
                      clearRecaptcha();
                    }}
                    className="w-full text-center mt-4 text-sm text-rose-800 hover:text-amber-700 transition-colors"
                  >
                    Use a different phone number
                  </button>
                )}
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

// Forgot Password Form Component
const ForgotPasswordForm = ({ onBack, onClose }) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage('Password reset email sent. Please check your inbox.');
      setErrorMessage('');
      setTimeout(() => {
        onBack(); 
        onClose(); 
      }, 2000);
    } catch (error) {
      setErrorMessage(`Failed to send password reset email. Error: ${error.message}`);
      setSuccessMessage('');
    }
  };

  return (
    <div className="w-full h-full flex items-center justify-center px-4 py-8">
      <div className="bg-white rounded-xl shadow-lg p-8 w-full max-w-md relative">
        <div className="mt-4">
          {/* Header */}
          <div className="flex justify-between items-center mb-8">
            <button onClick={onBack} className="text-gray-500 hover:text-gray-700 p-2 hover:bg-gray-100 rounded-full transition-colors">
              <ArrowLeft className="w-5 h-5" />
            </button>
            <h2 className="text-2xl font-bold text-gray-500">Reset Password</h2>
            <div className="w-8" /> {/* Spacer for alignment */}
          </div>

          <p className="text-center text-gray-600 mb-6">
            Enter your email to receive a password reset link
          </p>

          {/* Messages */}
          {errorMessage && (
            <div className="bg-red-50 border border-red-200 text-red-600 text-sm rounded-lg p-3 mb-4">
              {errorMessage}
            </div>
          )}
          {successMessage && (
            <div className="bg-green-50 border border-green-200 text-green-600 text-sm rounded-lg p-3 mb-4">
              {successMessage}
            </div>
          )}

          {/* Form */}
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-1">
              <label htmlFor="reset-email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                id="reset-email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
                className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:border-amber-500 focus:ring-1 focus:ring-amber-500 transition-colors"
              />
            </div>

            <button 
              type="submit"
              className="w-full bg-gradient-to-r from-amber-600 to-rose-800 text-white py-3 rounded-lg font-medium hover:opacity-90 transition-colors"
            >
              Send Reset Link
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;