

export const Color = {
    colorTeal: "rgba(42, 105, 108, 0.75)",
    colorMediumblue: "#0209B1",
    colorWhite: "#fff",
    colorDarkGrey: "#6B7280",
    colorBlack: "#000",
    colorDarkslategray: "#0f4e52",
    colorGray: "rgba(0, 0, 0, 0.75)",
    colorMediumGrey: "#9CA3AF",
    colorPurple: "#5e1a7d",
    colorLimeGreen: "#bfdb70",
    colorPurpleBlue: "#505480",
    colorLightPurple: "#E6E6FA",
    colorThemeGrey: "#4B4B4B",
    colorLilac: "#8393f0",
    colorMintGreen: "#a6baa6",
    colorLilacIcon: "#C9B1D4",
    colorLilacIconShadow: "#C0C0C0",
    colorPinchBlue: "#d2e3e9",
    colorSilverTint: "#F2EFF3",
    colorTabs: "#6E5F8F",
    colorDarkGreenText: "#518792",
    colorCoffeeBrown: "#8a5115",
    colorCoffeeBrownShadow: "#bdb3a9",
    colorMixGrey: "#5b5b5b",
    colorAppleGreen: "#F1FDF9",
    colorBlueGrey: "#D8DFE9",
    colorGreenGrey: "#a2a8a7",
    colorMixGreyLight: "#d9d9d9",
    colorRoyalBlue: "#1E90FF",
    colorBaseBeige: "#b5ad93",
    colorLiteBeige: "#ebe6d6",
    colorLightBeige: "#F0EFE7D9",
    colorLightBeigeStrong: "#edebe1", 
    colorLightestBeige: "#EDEBE1D9",
    colorCoolBeige: "#f7f7f2",
    colorFillBeige: "#f6f6ea",
    colorMidBeige: "#8f8a79",
    colorDarkBeige: "#8a5307",
    colorDarkBrown: "#756b49",
    colorDarkBlue: "#80666a",
    colorProfileGreyGreen: "#837979",
    colorcomplimentGreenBeige: "#a3944d",
    colorGreen: '#10B981',
    colorDarkGreen: '#047857',
    colorLightestGrey: '#F3F4F6',
    colorYellow: '#FACC15',
    colorOrange: '#F97316',
    colorGolden: '#FFBD2E',
    colorLightestRed: "#FEE2E2",
    colorRed: "#EF4444",
    colorBackground: "#F9FAFB",
    colorVibrantBlue: '#4285f4',
    colorVibrantGreen: '#34a853',
    colorVibrantRed: '#ea4335',
    colorRoyalBlueText: '#41547d',
    colorLandingBrown1: "#C3AE92",
    colorLandingBrown2: "#C47818",
    colorDarkBlueBlack: "#1a1a2e",
    colorOffWhite: "#f0f0f0",
};


export const LandingContainer = {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff'
};
export const LandingContainerDetails = {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    borderRadius: 25,
    padding: 20,
    margin: 10,
    marginLeft: 15,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 6,
    width: '90%',
};
export const RegisterContainerDetails = {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    borderRadius: 25,
    padding: 20,
    margin: 10,
    marginLeft: "5%",
    marginTop: "20%",
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 6,
    width: '90%',
};
export const closeImage= {
    width: 25,
    height: 25,
    marginLeft: 12, 
  };
export const LandingTitle = {
    paddingTop: '10%',
    paddingLeft: '10%',
    fontSize: 24,
    fontWeight: 'bold',
    color: Color.colorCoffeeBrown,
};
export const LandingReferralContent = {
    borderRadius: 8,
    padding: 20,
    alignItems: 'center',
};
export const ReferralContainerDetails = {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: 25,
    padding: 20,
    margin: 10,
    marginLeft: "5%",
    marginTop: "40%",
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 6,
    width: '90%',
};
export const LandingSubTitle = {
    fontSize: 14,
    paddingTop: '3%',
    fontStyle: 'italic',
    color: Color.colorThemeGrey
};
export const LandingButtonContainer = {
    marginTop: 20,
    paddingTop: 5,
    paddingBottom: 5,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: 5,
    borderRadius: 10,
};


export const LandingButtonStyle = {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
}

export const FontSize = {
    size_3xs: 10,
    size_smi: 13,
    size_xs: 12,
    size_lg: 18,
};

export const Border = {
    br_5xl: 24,
};


export const listItem2 = {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    color: 'blue',
    fontSize: 16,
    flex: 1,
    paddingLeft: "25%",
    rightAligned: true,
};
export const listItem3 = {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    paddingLeft: "15%",
    color: 'blue',
    fontSize: 16,
    marginLeft: 'auto',
    marginRight: 10,
};

export const cardTitle = {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    color: Color.colorTabs,
    justifyContent: 'space-between',
    fontSize: 16,
};

export const card = {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    color: "#0209B1",
    justifyContent: 'space-between',
    borderRadius: 12,
};


export const profileList = {
    backgroundColor: "#ffffff",
    height: "10%",
    position: "relative",
    width: '15%',
    borderRadius: 12,
    marginLeft: "3%",
    marginRight: "3%",
    color: 'blue',
    fontSize: 16,
};

export const AuctionCard = {
    padding: 30,
    paddingLeft: 30,
    flexDirection: 'column',
    backgroundColor: "#ffffff",
    justifyContent: 'space-between',
    marginBottom: 10,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 15,
    shadowColor: "#a69eb0",
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.4,
    shadowRadius: 2,
    elevation: 2,
};

export const AuctionPicker = {
    width: "100%",
    height: 40,
    backgroundColor: '#fff',
    padding: 5,
    fontColor: "#4B4B4B",

};

export const AuctionPickerText = {
    color: "#222",

};

export const rightCardItem = {
    flexDirection: 'row',
    color: Color.colorPurple,
    justifyContent: 'space-between',
    paddingLeft: "92%",
};

export const inputContainer = {
    flexDirection: 'row',
    alignItems: 'center',
    width: '95%',
    marginLeft: '2%',
    marginRight: '2%',
    marginBottom: "8%",
};

export const cardNButton = {
    padding: "15%",
    flexDirection: 'row',
    alignItems: 'center',
    color: "#0209B1",
    paddingLeft: "3%",
};

export const cardText = {
    padding: 3,
    alignItems: 'center',
    color: Color.colorProfileGreyGreen,
    justifyContent: 'space-between',
};

export const AuctionCardTitle = {
    fontSize: FontSize.size_lg,
    letterSpacing: 1.1,
    lineHeight: 24,
    color: "#882691",
    marginLeft: 10,
    height: 30,
    position: "absolute",
    fontWeight: 500,
};

export const AuctionRowContainer = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 8,
};

export const AuctionCardAmount = {
    fontSize: FontSize.size_lg,
    letterSpacing: 1.1,
    lineHeight: 24,
    color: "#882691",
    textAlign: "center",
    width: 260,
    height: 30,
    left: "0%",
    top: "0%",
    position: "absolute",
};

export const AuctionBiddingContianer = {
    textAlign: "left",
    flexDirection: 'row',
    marginTop: 8,
};

export const responseButtonHolder = {
    backgroundColor: Color.colorProfileGreyGreen,
    height: 40,
    width: 100,
    borderRadius: 4,
    marginLeft: "40%",
};

export const scrollView = {
    marginTop: 10,
}
export const acceptButtonText = {
    color: '#FFFFFF',
    fontWeight: "bold",
    fontSize: 16,
    marginLeft: "5%",
};

export const hoveredCard = {
    borderWidth: 2,
    borderColor: 'blue',
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
};


export const inputField = {
    backgroundColor: '#EEEEEE',
    padding: 10,
    color: '#505050',
    height: 50
};

export const inputWrapper = {
    paddingBottom: 20,
};

export const saveBtn = {
    backgroundColor: '#003E7D',
    alignItems: 'center',
    padding: 12,
};

export const saveBtnText = {
    color: '#FFFFFF',
    fontSize: 18,
};

export const acceptBtn = {
    backgroundColor: '#003E7D',
    alignItems: 'center',
    padding: 12,
};

export const childPosition = {
    left: 10,
    top: 10,
    right: 10,
};

export const bubbleListPosition = {
    left: 40,
    position: "absolute",

};

export const bubbleListHeading = {
    left: 30,
    maxWidth: "100%",
};

export const groupLayout = {
    height: "auto",
    width: "auto",
};

export const AuctionGroupLayout = {
    flex: 1,
    height: "auto",
    width: "95%",
    marginLeft: "3%",
    backgroundColor: "#efeff2",
    paddingTop: "3%",
    paddingLeft: "1%",
    paddingRight: "1%",
};

export const groupChildLayout = {
    height: 848,
    width: "100%",
};

export const childLayout = {
    borderRadius: Border.br_5xl,
    backgroundColor: Color.colorWhite,
    position: "absolute",
};

export const bubbleInvitesTypo = {
    color: Color.colorTeal,
    lineHeight: 12,
    letterSpacing: 0.6,
    fontSize: FontSize.size_3xs,
    textAlign: "left",
    fontWeight: "600",
    position: "absolute",
};

export const dashboardChild = {
    backgroundColor: '#FFF7F0',
    borderRadius: 12,
    broderWidth: 0.5,
    height: 100,
    position: "absolute",
    width: 355,
};

export const dashboardHot = {
    backgroundColor: "#ffffff",
    top: "2%",
    height: "10%",
    width: "95%",
    marginBottom: "25%",
    position: "relative",
    borderRadius: 12,
    marginLeft: "3%",
    marginRight: "3%",
}
export const dashboardHotContents = {
    backgroundColor: "#ffffff",
    top: "10%",
    position: "relative",
    justifyContent: 'center',
    alignItems: 'center',

}

export const preferredLists = {
    backgroundColor: "#ffffff",
    top: "6%",
    height: "30%",
    position: "relative",
    width: '95%',
    borderRadius: 12,
    marginLeft: "3%",
    marginRight: "3%",
};

export const cateogriesTitle = {
    letterSpacing: 1.1,
    fontSize: FontSize.size_lg,
    textAlign: "left",
    fontWeight: "400",
    color: "#4B4B4B",
    left: "3%",
};

export const iconContainer = {
    margin: 8,
    paddingLeft: 5,
    paddingRight: 5,
};

export const cateogriesCircle = {
    top: "3%",
    left: "0%",
    width: "100%",
    height: "5%",
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    position: "relative",
};
export const bubbleListingTitle = {
    color: "#0209B1",
    lineHeight: 21,
    letterSpacing: 1.1,
    fontSize: FontSize.size_smi,
    textAlign: "left",
    fontWeight: "500",
    left: "35%",
    top: "10%",
    position: "absolute",
};


export const bubbleList1 = {
    color: "#0209B1",
    lineHeight: 21,
    letterSpacing: 1.1,
    fontSize: FontSize.size_smi,
    textAlign: "left",
    fontWeight: "600",
    left: "0%",
    top: "0%",
    position: "absolute",
};

export const bubbleList = {
    top: 420,
    width: 110,
    height: 11,
};

export const groupChild = {
    height: "auto",
    width: "auto",
    left: 0,
    top: 0,
};

export const activeBubbles = {
    top: 26,
    fontSize: 14,
    lineHeight: 16,
    left: 21,
    letterSpacing: 0.8,
};

export const groupChild3 = {
    top: 100,
    left: 286,
    borderRadius: 100,
    backgroundColor: "#d9d9d9",
    width: 3,
    height: 90,
    position: "absolute",
};

export const rectangleParent = {
    left: 0,
    top: 0,
    height: "auto",
    position: "relative",
};

export const dashboardInner = {
    top: 566,
    left: 22,
    position: "absolute",
};

export const shapeIcon = {
    width: 175,
    height: 173,
    position: "absolute",
};

export const component1Child = {
    width: "100%",
    backgroundColor: Color.colorWhite,
    left: "0%",
    borderRadius: Border.br_5xl,
    right: "0%",
    bottom: "0%",
    top: "0%",
    height: "100%",
};

export const component2Child = {
    width: "100%",
    backgroundColor: Color.colorWhite,
    left: "10%",
    borderRadius: Border.br_5xl,
    right: "0%",
    bottom: "0%",
    top: "0%",
    height: "100%",
};

export const activeInvites = {
    width: "100%",
    paddingTop: 40,
    left: "16.22%",
    alignItems: 'center',
    justifyContent: 'center',
    position: "absolute",

};
export const drafts = {
    width: "100%",
    paddingTop: 40,
    left: "45.22%",
    alignItems: 'center',
    justifyContent: 'center',
    position: "absolute",
};

export const component1 = {
    top: 475,
    width: 111,
    height: 40,
};

export const component2 = {
    top: 475,
    left: 220,
    width: 111,
    height: 40,
    position: "absolute",
};
export const vectorIcon1 = {
    width: "6.4%",
    right: "21.33%",
    bottom: "0.37%",
    left: "72.27%",
    height: "2.96%",
};
export const vectorIcon2 = {
    height: "2.83%",
    width: "7.01%",
    right: "12.45%",
    left: "80.53%",
    bottom: "0.49%",
};
export const dashboard = {

    height: 1020,
    overflow: "hidden",
    width: "100%",
};

export const dashboard_mini = {
    height: 180,
    overflow: "hidden",
    width: 375,
};

export const lisiting_board = {
    top: "20%",
    overflow: "hidden",
    width: 375,
};

export const dashboard_mini_small = {
    height: 60,
    overflow: "hidden",
    width: 375,
};

export const dashboard_miniMid = {
    height: 110,
    overflow: "hidden",
    width: 375,
};

export const dashboardMini_title = {
    fontSize: FontSize.size_lg,
    letterSpacing: 1.1,
    lineHeight: 24,
    color: Color.colorDarkBeige,
    textAlign: "center",
    marginLeft: 55,
    alignItems: "center",
    width: "90%",
    height: 30,
    marginRight: 41,
    marginTop: 23,
    left: "0%",
    top: "0%",
    position: "absolute",
};
export const title_mini = {
    fontSize: FontSize.size_smi,
    letterSpacing: 1.1,
    lineHeight: 24,
    color: Color.colorDarkBeige,
    textAlign: "center",
    marginLeft: 25,
    alignItems: "center",
    width: 260,
    height: 30,
    marginRight: 10,
    marginTop: 10,
    left: "0%",
    top: "0%",
    position: "absolute",
};

export const dashboardMini_titleMid = {
    fontSize: FontSize.size_lg,
    letterSpacing: 1.1,
    lineHeight: 18,
    textAlign: "center",
    marginLeft: 55,
    alignItems: "center",
    width: 260,
    height: 20,
    marginRight: 41,
    marginTop: 15,
    color: Color.colorDarkBrown,
    left: "0%",
    top: 15,
};

export const dashboardMini_title1 = {
    fontSize: FontSize.size_lg,
    letterSpacing: 0.5,
    lineHeight: 24,
    color: "#6A6B6F",
    textAlign: "left",
    marginLeft: 5,
    alignItems: "left",
    width: 260,
    height: 30,
    marginTop: 10,
    position: "absolute",
};

export const dashboardMini_title2 = {
    fontSize: FontSize.size_lg,
    letterSpacing: 0.5,
    lineHeight: 24,
    color: "#6A6B6F",
    textAlign: "center",
    marginLeft: 5,
    alignItems: "left",
    width: 260,
    height: 30,
    marginTop: 40,
    position: "absolute",
};

export const dashboardMini_title3 = {
    fontSize: FontSize.size_lg,
    letterSpacing: 0.5,
    lineHeight: 24,
    color: "#6A6B6F",
    textAlign: "right",
    marginLeft: 5,
    alignItems: "left",
    width: 260,
    height: 30,
    marginTop: 40,
    position: "absolute",
};

export const dashboardMini_title4 = {
    fontSize: FontSize.size_lg,
    letterSpacing: 0.5,
    lineHeight: 24,
    color: "#6A6B6F",
    textAlign: "left",
    left: 5,
    alignItems: "left",
    width: 60,
    height: 30,
    marginTop: 40,
    position: "relative",
};

export const dashboardMini_title5 = {
    fontSize: FontSize.size_lg,
    letterSpacing: 0.5,
    lineHeight: 24,
    color: "#6A6B6F",
    textAlign: "right",
    left: -30,
    width: 60,
    height: 30,
    marginTop: 40,
    position: "relative",
};

export const dashboardMini_title6 = {
    fontSize: FontSize.size_xs,
    color: "#704504",
};

export const dashboardMini_subtitle = {
    letterSpacing: 0.3,
    lineHeight: 14,
    textAlign: "center",
    marginLeft: 55,
    alignItems: "center",
    width: 260,
    height: 30,
    marginRight: 41,
    marginTop: 23,
    top: "40%",
    fontSize: 14,
    fontStyle: 'italic',
    marginBottom: 10,
    color: '#DB9630',
};

export const dashboardMini_subtitle_small = {
    fontSize: FontSize.size_3xs,
    letterSpacing: 0.3,
    lineHeight: 14,
    textAlign: "center",
    marginLeft: 55,
    alignItems: "center",
    width: 260,
    height: 30,
    marginRight: 41,
    marginTop: 23,
    top: "40%",
};

export const dashboardMini_actions = {
    fontSize: FontSize.size_xs,
    letterSpacing: 0.1,
    lineHeight: 5,
    color: "#A01E1E",
    marginLeft: 55,
    alignItems: "center",
    width: 100,
    height: 10,
    marginTop: 60,
    top: "40%",
    position: "absolute",
    left: 200,
};

export const flatList_actions = {
    fontSize: FontSize.size_xs,
    letterSpacing: 0.1,
    lineHeight: 16,
    color: "#A01E1E",
    marginLeft: 87,
    alignItems: "center",
    width: 100,
    height: 40,
    marginTop: 10,
    top: "12%",
    position: "absolute",
    left: 175,
};

export const plusCircleIconWrapper = {
    position: 'absolute',
    top: 10,
    left: 275,
    overflow: 'hidden',
    width: 25,
    height: 25,
};

export const plusCircleIcon = {
    width: '100%',
    height: '100%',
    color: Color.colorMidBeige,
};

export const squarIcon = {
    color: Color.colorBaseBeige,
    paddingBottom: "0.5%",
};

export const rowContainer = {
    flexDirection: 'row',
    alignItems: 'center',
};

export const inviteRowContainer = {
    flexDirection: 'row',
    justifyContent: 'space-between',
};

export const dataEntryframe = {
    flex: 1,
    padding: 12,
    top: 10,
};

export const labelContainer = {
    alignItems: 'left',
    marginBottom: 5,
    paddingTop:10,
};

export const label = {
    marginRight: 8,
    fontSize: 14,
    color: "#6A6B6F",
};

export const input = {
    position: "relative",
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 16,
    paddingLeft: 8,
    borderRadius: 10,
    fontSize: FontSize.size_smi,
    letterSpacing: 0.8,
    lineHeight: 18,
    textAlign: "left",
    backgroundColor: "#fff",
    color: "#0209B1",
};

export const WishInput = {
    position: "relative",
    height: "120%",
    width: "90%",
    borderColor: Color.colorDarkBeige,
    borderWidth: 1,
    marginBottom: 16,
    marginLeft: "5%",
    paddingLeft: 8,
    borderRadius: 8,
    letterSpacing: 0.8,
    lineHeight: 18,
    textAlign: "left",
    backgroundColor: "#fff",
    color: "#0209B1",
};

export const displayValues = {
    position: "relative",
    height: 40,
    marginBottom: 16,
    paddingLeft: 8,
    fontSize: FontSize.size_smi,
    letterSpacing: 0.8,
    lineHeight: 18,
    textAlign: "left",
    color: "#0C118E",
    top: 5,
};

export const inputArea = {
    position: "relative",
    minHeight: 250,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 16,
    paddingLeft: 8,
    borderRadius: 10,
    fontSize: FontSize.size_smi,
    letterSpacing: 0.8,
    lineHeight: 18,
    textAlign: "left",
    textAlignVertical: "top",
    backgroundColor: "#fff",
    color: "#0209B1",
};

export const inputDisabled = {
    ...input,
    backgroundColor: '#F4FDFE',
};

export const dropdown = {
    position: "relative",
    paddingLeft: 8,
    height: 30,
    width: 350,
    left: 1,
};

export const dropdownDynamicContainer = {
    marginBottom: 20,
    zIndex: 1,
};

export const iconWithTextContainer = {
    flexDirection: 'row',
};

export const iconText = {
    position: "relative",
    color: '#A01E1E',
    fontSize: 14,
    marginHorizontal: 4,
};

export const placeHolderStyle = {
    color: Color.colorWhite,
    fontWeight: "bold",
    FontSize: 16,
};

export const noResultsText = {
    color: Color.colorBaseBeige,
    FontSize: 12,
    paddingVertical: 12,
    textAlign: 'center',
};

export const buttonContainer = {
    flexDirection: 'row',
    justifyContent: 'center',
};

export const buttonContainer2 = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: "10%",
};

export const button = {
    position: "center",
    backgroundColor: Color.colorDarkBrown,
    padding: 5,
    borderRadius: 5,
    width: "90%",
    alignItems: 'center',
    justifyContent: 'center',
};

export const buttonText = {
    color: Color.colorWhite,
};

export const buttonsNRow = {
    flex: 1,
    marginHorizontal: 4,
};

export const datePickerIOS = {
    height: 40,
    borderColor: 'gray',
    backgroundColor: "#fff",
    color: "#0209B1",
    borderWidth: 1,
    paddingLeft: 8,
    borderRadius: 10,
    marginBottom: 16,
};

export const successMessage = {
    color: 'green',
    fontSize: 16,
    textAlign: 'center', marginBottom: 10,

};

export const errorMessage = {
    color: 'red',
    fontSize: 16,
    textAlign: 'center',
    marginTop: '22%',
};

export const bubbleSummaryBox = {
    height: 320,
    width: "100%",
    border: "#2F5CFB",
    borderWidth: 1,
    borderRadius: 12,
    top: "7.96%",
    bottom: "58.26%",
    left: "0%",
    backgroundColor: "#f5f7ff",
    right: "13.4%",
    position: "relative",
};
export const bubbleSummaryBoxLeftText = {
    alignItems: "center",
    color: "#0C118E",
    letterSpacing: 0.7,
    fontSize: FontSize.size_xs,
    textAlign: "left",
    position: "absolute",
    display: "flex",
    left: "2.5%",
    top: 10,
};

export const bubbleSummaryBoxRightText = {
    alignItems: "center",
    display: "flex",
    color: "#0C118E",
    letterSpacing: 0.7,
    fontSize: FontSize.size_xs,
    textAlign: "left",
    position: "absolute",
    left: "49.5%",
    top: 10,
};

export const bubbleSummaryChartBoxRightText = {
    alignItems: "center",
    display: "flex",
    color: "#0C118E",
    letterSpacing: 0.7,
    fontSize: FontSize.size_xs,
    textAlign: "left",
    position: "absolute",
    left: "59.5%",
    top: 120,
};

export const bubbleSummaryBoxContainer = {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 10,
    position: "relative",
};

export const bubbleSummaryBoxChartContainer = {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 10,
};

export const horizontalBar1 = {
    position: 'absolute',
    top: 65,
    left: 8,
    width: 311,
    height: 5,
    backgroundColor: 'transparent',
    borderBottomWidth: 1.5,
    borderBottomColor: '#A7B7A6',
};

export const horizontalBar2 = {
    position: 'absolute',
    top: 260,
    left: 185,
    width: 133,
    height: 5,
    backgroundColor: 'transparent',
    borderBottomWidth: 1.5,
    borderBottomColor: '#A7B7A6',
};

export const verticalBar1 = {
    position: 'absolute',
    top: 70,
    left: 4,
    width: "1.5%",
    height: "13%",
    backgroundColor: 'transparent',
    borderRightWidth: 1.5,
    borderRightColor: '#A7B7A6',
};

export const verticalBar2 = {
    position: 'absolute',
    top: 70,
    left: "91%",
    width: "1.5%",
    height: 195,
    backgroundColor: 'transparent',
    borderLeftWidth: 1.5,
    borderLeftColor: '#A7B7A6',
};

export const verticalBar3 = {
    position: 'absolute',
    top: 80,
    left: 4,
    width: "1.5%",
    height: "13%",
    backgroundColor: 'transparent',
    borderRightWidth: 1.5,
    borderRightColor: '#C6CDC5',
};

export const verticalBar4 = {
    position: 'absolute',
    top: 80,
    left: 4,
    width: "1.5%",
    height: "6%",
    backgroundColor: 'transparent',
    borderRightWidth: 1.5,
    borderRightColor: '#C2D4C1',
};

export const verticalBar5 = {
    position: 'absolute',
    top: 85,
    left: "4%",
    width: "1.5%",
    height: "8%",
    backgroundColor: 'transparent',
    borderRightWidth: 1.5,
    borderRightColor: '#F1FAF0',
};

export const bubbleTransactionBox = {
    height: 180,
    width: "100%",
    borderWidth: 1,
    borderRadius: 12,
    top: 325,
    bottom: 80,
    left: "0%",
    backgroundColor: "#FFFBF9",
    position: "relative",
};

export const transactiondetails = {
    bottom: -8,
};

export const buddyBox = {
    height: 450,
    width: "100%",
    borderWidth: 1,
    borderRadius: 12,
    top: 510,
    bottom: 80,
    left: "0.1%",
    backgroundColor: "#FFF7EE",
    position: "absolute",

};
export const displayAsList1 = {
    color: "#0C118E",
    letterSpacing: 0.2,
    fontSize: FontSize.size_xs,
    textAlign: "left",
    position: "absolute",
    left: -240,
    marginLeft: "1%",
    marginBottom: 1,
    top: 60,
};

export const displayAsList2 = {
    color: "#0C118E",
    letterSpacing: 0.2,
    fontSize: FontSize.size_xs,
    textAlign: "left",
    position: "absolute",
    marginLeft: 4,
    left: -122,
    marginBottom: 1,
    top: 60,
};

export const displayAsList3 = {
    color: "#0C118E",
    letterSpacing: 0.2,
    fontSize: FontSize.size_xs,
    textAlign: "right",
    position: "relative",
    left: -40,
    marginBottom: 1,
    top: 60,
};

export const displayAsList4 = {
    color: "#0C118E",
    letterSpacing: 0.2,
    fontSize: FontSize.size_xs,
    textAlign: "right",
    position: "absolute",
    left: -270,
    marginBottom: 1,
    top: 60,
};

export const displayAsList5 = {
    color: "#0C118E",
    letterSpacing: 0.2,
    fontSize: FontSize.size_xs,
    textAlign: "right",
    position: "relative",
    left: -20,
    marginBottom: 1,
    top: 60,
};

export const displayAsListTitle = {
    top: -10,
    position: "absolute",
    fontSize: FontSize.size_lg,
    letterSpacing: 0.8,
    lineHeight: 18,
    textAlign: "center",
    align: "center",
    color: "#0209B1",
    alignItems: "center",
    width: 260,
    height: 30,
    marginTop: 23,
    left: "-20%",
};
export const listRowContainer = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 5,
    backgroundColor: Color.colorLightestBeige,
    paddingTop: 5,
    width: '80%',
    paddingLeft: 10,
    marginLeft: "10%",
    marginTop: "5%",
    borderRadius: 10,
};

export const buddyBoxContainer = {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 10,
};

export const tabBarStyle = {
    tabBarStyle: {
        backgroundColor: '#E6E6E6',
    },
    tabBarActiveTintColor: '#0A0F87',
};

export const contactCon = {
    flex: 1,
    flexDirection: 'row',
    padding: 10,
    borderBottomWidth: 0.5,
    borderBottomColor: '#d9d9d9',
};

export const ContactsPage = {
    backgroundColor: '#d9d9d9',
};

export const ContactsListing = {
    flexDirection: 'row',
    padding: 5,
    marginBottom: 5,
};

export const whiteText = {
    color: 'white'
};
export const ContactsPlaceholder = {
    width: 55,
    height: 55,
    borderRadius: 27.5,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    backgroundColor: Color.colorMidBeige,
};

export const rightAligned = {
    marginLeft: "90%",
    marginVertical: "2%",
    position: "absolute",
};
export const contactEntry = {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderBottomWidth: 0.5,
    borderBottomColor: '#d9d9d9',
};
export const contactInfo = {
    flex: 1,
    paddingRight: 10,
};
export const contactName = {
    fontSize: 16,
    fontWeight: 'bold',
};
export const phoneNumber = {
    fontSize: 14,
    color: '#888',
};
export const emailAddress = {
    fontSize: 14,
    color: '#888',
};
export const checkboxContainer = {
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
};
export const checkboxColumn = {
    width: 50,
    alignItems: 'center',
    justifyContent: 'center',
};
export const checkboxWrapper = {
    marginVertical: 5,
};

export const contactDat = {
    flex: 1,
    paddingLeft: 5,
    justifyContent: 'space-between',
};

export const ContactsTxt = {
    fontSize: 18,
};

export const ContactsName = {
    fontSize: 16,
    fontWeight: '500',
    color: Color.colorMidBeige,
};

export const ContactsPhoneNumber = {
    color: '#888',
    flexDirection: 'row',
    marginVertical: 5,
}

export const EmailsPhoneNumber = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
};

export const ContactsPlusIcon = {
    fontSize: 16,
    paddingLeft: 100,
    marginLeft: 50,
};

export const searchBox = {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 10,
    marginBottom: "5%",
    height: "8%",
    marginLeft: 10,
    marginRight: 1,
    justifyContent: 'space-between',
};

export const dealsSearchBox = {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 10,
    padding: 10,
    marginBottom: "1%",
    height: 70,
    marginLeft: 10,
    marginRight: 1,
    marginTop: "10%",
    justifyContent: 'space-between',
}
export const searchIcon = {
    color: Color.colorMidBeige
};

export const searchInputContainer = {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 10,
    paddingHorizontal: 8,
    marginBottom: 16,
};


export const modalDropdownContainer = {
    position: 'absolute',
    top: 60,
    left: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 10,
    maxHeight: 200,
};

export const modalDropdownItem = {
    padding: 10,
    paddingHorizontal: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
};

export const modalDropdownItemText = {
    fontSize: 16,
};

export const modalDropdown = {
    maxHeight: 150,
    borderWidth: 1,
    borderColor: '#ccc',
};

export const inviteListHeading = {
    flex: 1,
    padding: 15,
    marginRight: 30,
    paddingBottom: 40,
    color: Color.colorDarkBrown,
    justifyContent: 'space-between',
};

export const gap = {
    marginBottom: 20,
}

export const gap2 = {
    marginTop: "10%",
    marginLeft: "10%",
}

export const inviteGroupIcon = {
    width: '100%',
    height: '100%',
    padding: 100,
    marginLeft: 30,
    color: "#A01E1E",
};

export const sameRowContainer = {
    flexDirection: 'row',

}; 
 
export const dropdownSmall = {
    height: 10,
    width: 120,
    backgroundColor: '#f6f6f6',
    borderWidth: 0,
    paddingHorizontal: 8,
};

export const ListContainer = {
    marginTop: 16,
    backgroundColor: "white",
    padding: 16,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
};
export const ListTitle = {
    fontSize: 16,
    fontWeight: "bold"
};

export const items = {
    overflow: "hidden"
};

export const messageContainer = {
    maxHeight: 100,
    padding: 10,
    backgroundColor: '#f0f0f0',
};

export const messageText = {
    fontSize: 14,
    color: '#333',
};

export const smallProfileImage = {
    width: 100,
    height: 100,
    borderRadius: 25,
};

export const profile_image_dashboard = {
    height: "25%",
    position: "absolute",
    width: "100%",
};

export const root = {
    height: 40,
    borderBottomWidth: StyleSheet.hairlineWidth,
    justifyContent: 'center'
};

export const bubbleContainer = {
    alignItems: 'center',
    margin: 10,
};
export const bubble = {
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: '#3498db',
    justifyContent: 'center',
    alignItems: 'center',
    // 3D effect
    shadowColor: '#000',
    shadowOffset: {
        width: 0,
        height: 4,
    },
    shadowOpacity: 0.8,
    shadowRadius: 3,
    elevation: 5, // For Android
};

export const bubbleText = {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 9,
};

export const InvitesTopBar = {
    backgroundColor: Color.colorBaseBeige,
    paddingBottom: 3,
};

export const LoginTitle = {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    paddingLeft: '30%',
    marginTop: '10%',
    color: Color.colorCoolBeige,
    height: 40,
};

export const RegisterTitle = {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    paddingLeft: '10%',
    marginTop: '15%',
    color: Color.colorCoolBeige,
    height: 40,
};

export const LoginInput = {
    flex: 1,
    marginVertical: 10,
    marginHorizontal: 5,
    borderBottomColor: "#111",
    borderBottomWidth: 1,
    fontSize: 15,
    color: "#fff",
    padding: 3,
    height: 50,
    width: 60,
};


export const LoginRegisterText = {
    marginTop: 16,
};

export const LoginRegisterLink = {
    color: 'blue',
};


export const list_text = {
    fontSize: FontSize.size_lg,
    color: "#4B4B4B",
    textAlign: "center",
    marginLeft: "5%",
    marginTop: "15%",
    marginBottom: "1%",
};

export const OuterContainer = {
    marginBottom: 10,
    borderRadius: 10,
};

export const InnerContainer = {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 2,
    paddingHorizontal: 3,
    backgroundColor: Color.colorBaseBeige,
    borderRadius: 10,
};

export const ListCardTitle = {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
};

export const RegistrationContainer = {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    width: '100%',
};

export const RegistrationInput = {
    width: '100%',
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 16,
    paddingHorizontal: 8,
    borderRadius: 4,
    color: Color.colorCoffeeBrown,
};
export const RegistrationReferralCodes = {
    width: '15%',
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    justifyContent: 'center',
    marginTop: "5%",
    marginBottom: "5%",
    marginHorizontal: "1%",
    paddingHorizontal: 8,
    borderRadius: 4,
    color: Color.colorCoffeeBrown,
};
export const RegistrationPhoneInput = {
    flex: 1,
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 16,
    paddingHorizontal: 8,
    borderRadius: 4,
    color: Color.colorCoffeeBrown,
}; export const RegistrationInputCC = {
    width: 80,
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 16,
    marginRight: 8,
    paddingHorizontal: 8,
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: Color.colorCoffeeBrown,
};
export const PlaidTheme = {
    dark: false,
    colors: {
        primary: '#FFFFFF',
        background: '#000000',
        card: '#FFFFFF',
        text: '#000000',
        border: '#000000',
        notification: '#FFFFFF',
    },
};

export const PlaidBody = {
    flex: 1,
    paddingHorizontal: 32,
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    backgroundColor: '#FFFFFF',
};
export const PlaidBaseText = {
    fontSize: 16,
    marginTop: 0,
    color: '#4B4B4B',
    textAlign: 'left',
};
export const PlaidTitleText = {
    fontSize: 20,
    textAlign: 'center',
    marginTop: 10,
    marginHorizontal: 10,
    color: '#245705',
};

export const PlaidBottom = {
    flex: 0.5,
    justifyContent: 'flex-end',
    backgroundColor: '#FFFFFF',
};

export const PlaidButtonContainer = {
    elevation: 2,
    backgroundColor: '#4B4B4B',
    width: '100%',
    paddingVertical: 16,
    paddingHorizontal: 16,
};
export const PlaidButtonText = {
    fontSize: 18,
    color: '#FFF',
    fontWeight: 'bold',
    alignSelf: 'center',
};
export const PlaidMidContainer = {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F0F0F0',
};
export const PlaidTop = {
    flex: 0.5,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.colorMidBeige,
};

export const PlaidMid = {
    flex: 3,
    backgroundColor: '#FFFFFF',
    justifyContent: 'center',
    alignItems: 'center',
};
export const TermsContainer = {
    padding: 16,
};

export const TermsPoint = {
    fontSize: 16,
    marginBottom: 10,
    marginLeft: 15,
};

export const TermsPointNumber = {
    fontWeight: 'bold',
};

export const TermsBottomText = {
    fontSize: 16,
    marginTop: 10,
    width: "90%"
};

export const TermsTitle = {
    fontSize: 18,
    marginBottom: 10,
    paddingLeft: 25,
    fontFamily: 'Raleway-Italic-VariableFont_wght',
}

export const TermsRadioButtonContainer = {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#4B4B4B',
};

export const TermsRadioButtonLabel = {
    marginLeft: -35,
    color: 'black',
};

//=======

export const WishListContainer = {
    flex: 1,
    backgroundColor: '#F0EAE2',
};
export const WishListCarousel = {
    height: 150,
    backgroundColor: '#E9E7E2',
};
export const WishListTrending = {
    height: 200,
    backgroundColor: '#DBD3D8',
};
export const WishListProduct = {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 10,
    // other styles for product item
};
export const WishListProductImage = {
    width: 100,
    height: 100,
};
export const WishListProductTitle = {
    flex: 1,
    // styles for product title
};
export const WishListAddButton = {
    padding: 10,
    backgroundColor: '#C2BDBA',
};
export const WishListAddButtonText = {
    fontSize: 24,
    color: '#FFFFFF',
};
export const WishListHeader = {
    fontSize: 18,
    fontWeight: 'bold', 
};

export const mainContainer = {
    flexDirection: 'row', 
    height: '200%',
    flex: 1,
    minHeight: '100vh',
    paddingTop: '80px', 
    position: 'relative'  
    
};
export const safeArea = {
    flex: 1,
};
export const scrollViewContainer = {
    paddingBottom: 20,

};
export const leftSection = { 
    borderColor: "#FFFFFF",
    borderRight: 5,
};
export const rightSection = {
   // width: width * 4 / 5,
};
export const itemContainer = {
    backgroundColor: Color.colorLightBeige,
    borderRadius: 25,
    padding: 20,
    margin: 10,
    marginLeft: 15,
    flexDirection: 'row',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 6,
    width: '90%',
};
export const itemImage = {
    width: 80,
    height: 80,
    borderRadius: 15,
};
export const itemTitle = {
    flex: 1,
    fontSize: 16,
    fontWeight: '600',
    marginLeft: 10,
    color: Color.colorDarkBeige,
};
export const bufferTop = {
    marginTop: '10%',
};
export const image = {
    width: '100%',
    height: '100%',
    borderRadius: 25,
};
export const centreImage = {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: "45%",
    marginTop: "10%",
};
export const leftImage = {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: "5%",
    marginTop: "2%",
};
export const rightImage = {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: "8%",
    marginTop: "2%",
};
export const LoginContainer = {
    width: '100%', paddingHorizontal: 22, paddingVertical: 60, marginTop: '5%'
};
export const logo = {
    alignSelf: 'center'
};

export const toggleButtonLeft = {
    position: 'relative',
    marginLeft: '-5%',
    padding: 10,
    zIndex: 10,
};

export const toggleButtonRight = {
    position: 'absolute',
    top: 10,
    left: 10,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
};

export const closeButton = {
    top: '10%',
    right: '-2%',

};
export const closeIcon = {
    width: 18,
    height: 20,
    top: '10%',
    right: '45%',
};

export const centeredView = {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    marginTop: '5%',
};

export const modalText = {
    marginTop: 10,
    marginBottom: 15,
    textAlign: "center",
    fontWeight: 'bold',
    color: '#0c343d',
};

export const modalTextLineIcon = {

    width: 25,
    height: 25,
};

export const WishItemTitle = {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 10,
    color: '#24547f',
};
export const itemDescription = {
    fontSize: 14,
    marginBottom: 6,
    color: '#5b5b5b',
};
export const itemDate = {
    fontSize: 12,
    color: '#666',
    marginTop: 5,
};

export const itemCounts = {
    fontSize: 12,
    color: '#666',
    marginRight: 3,
    marginTop: 15,
};

export const modalRadioButton = (isSelected) => ({
    color: isSelected ?
        Color.colorDarkGreenText : Color.colorMintGreen,
    fontWeight: isSelected ? 'bold' : 'normal',
});

export const modalRadioGroup = {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 20,
};
export const modalInput = {
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 10,
    borderRadius: 5,
};

export const profilesBackgroundImage = {
    flex: 1,
    resizeMode: 'cover',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
};
export const profilesDashboardChild = {
    position: "absolute",
    top: 100,
    left: 141,
    width: 100,
    height: 100,
};
export const backButtonText = {
    fontSize: 18,
    color: Color.colorWhite,
};

export const backButton= {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    color: Color.colorDarkGrey, 
    display: 'flex',  
  };

export const scrollContainer = {
    flexGrow: 1,
};
export const searchInput = {
    paddingHorizontal: 12,
    flex: 1,
    paddingVertical: 8,
};
export const section = {
    marginTop: 5,
};
export const sectionTitle = {
    fontSize: 18,
    fontWeight: 'bold',
    color: Color.colorMidBeige,
    marginBottom: 8,
    marginTop: 15,
};
export const squareIconWrapper = {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: "85%",
    marginVertical: "2%",
    position: "absolute",
    rightAligned: true,
};
export const iconSpacing = {
    marginHorizontal: 5,
};

export const dealTile = {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: Color.colorBaseBeige,
    borderRadius: 8,
    padding: 8,
    marginRight: 16,
    alignItems: 'center',
};
export const listItem = {
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    paddingVertical: 12,
    width: "90%",
    marginBottom: 8,
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
};

export const productImage = {
    width: 100,
    height: 100,
    marginBottom: 8,
};
export const productName = {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
};
export const actionButtons = {
    flexDirection: 'row',
    justifyContent: 'space-around',
};
export const actionButton = {
    backgroundColor: Color.colorBaseBeige,
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 16,
};
export const actionButtonText = {
    color: '#fff',
    fontWeight: 'bold',
};
export const modalContainer = {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
};
export const modalContent = {
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: 16,
    alignItems: 'center',
};
export const modalImage = {
    width: 200,
    height: 200,
    marginBottom: 16,
};
export const modalProductTitle = {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 8,
    color: Color.colorPurple,
};
export const modalProductName = {
    fontSize: 16,
    marginBottom: 8,
};
export const modalSellerCompany = {
    fontSize: 16,
    color: '#666',
    marginBottom: 8,
};
export const modalPrice = {
    fontSize: 16,
    marginBottom: 4,
};
export const modalDealCount = {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 8,
};
export const modalDealDescription = {
    fontSize: 16,
    marginBottom: 8,
};
export const expandButton = {
    color: 'blue',
    marginTop: 8,
    textDecorationLine: 'underline',
    alignSelf: 'flex-end',
    padding: 5,
};
export const modalDealLifecycle = {
    fontSize: 14,
    color: '#666',
    marginBottom: 4,
};
export const modalDealExpiryDate = {
    fontSize: 14,
    color: '#666',
    marginBottom: 16,
};
export const modalActions = {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 16,
    alignItems: 'center',
};
export const modalActionButton = {
    backgroundColor: Color.colorMidBeige,
    borderRadius: 8,
    paddingVertical: 12,
    paddingHorizontal: 16,
    marginHorizontal: 8,
    marginVertical: 4,
};
export const joinDealButton = {
    backgroundColor: '#4CAF50',
    flex: 1,
};

export const joinButton = {
    backgroundColor: '#4CAF50',
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
};
export const joinButtonText = {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
};
export const confirmationText = {
    fontSize: 14,
    marginBottom: 10,
    backgroundColor: Color.colorCoolBeige
};
export const expirationText = {
    marginTop: 10,
    fontSize: 14,
    color: Color.colorPurple,
};
export const confirmButton = {
    backgroundColor: Color.colorBaseBeige,
    padding: 10,
    borderRadius: 5,
    marginLeft: "15%",
    marginBottom: 5,
    width: "75%",
};
export const confirmButtonText = {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
};
export const modalTitle = {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
};
export const agreementContainer = {
    maxHeight: '70%',
};
export const agreementSubHeading = {
    fontSize: 14,
    lineHeight: 20,
};

export const agreementText = {
    fontSize: 14,
    lineHeight: 20,
    paddingLeft: 10,
};
export const skipDealButton = {
    backgroundColor: Color.colorBaseBeige,
};
export const modalActionButtonText = {
    color: '#fff',
    fontWeight: 'bold',
};
export const modalCloseButton = {
    backgroundColor: Color.colorBaseBeige,
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 16,
    position: 'absolute',
    top: 36,
    right: 16,
    padding: 8,
};
export const shadowButton = {
    shadowColor: '#000',
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
};
export const modalCloseButtonText = {
    color: '#fff',
    fontWeight: 'bold',
};
export const joinedDealCard = {
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: Color.colorBaseBeige,
    borderRadius: 8,
    marginTop: '2%',
    marginBottom: '3%',
    overflow: 'hidden',
    width: '95%',
    marginLeft: '3%',
    rightPadding: '3%',
};
export const joinedDealDetails = {
    flex: 1,
    padding: 8,
};
export const buyersNeeded = {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 4,
};
export const projectedExpiryDate = {
    fontSize: 14,
    marginBottom: 4,
};
export const dealLifecycle = {
    fontSize: 12,
    color: '#666',
    marginBottom: 8,
};

export const chartTitle = {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 12,
    color: Color.colorWhite,
};
export const chart = {
    marginVertical: 8,
    borderRadius: 16,
};

export const recommendedDealTile = {
    backgroundColor: Color.colorcomplimentGreenBeige,
    borderRadius: 6,
    paddingLeft: 16,
    paddingRight: 16,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
};
export const recommendedProductName = {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 8,
    marginTop: 5,
    textAlign: 'center',
    color: Color.colorWhite,
};
export const recommendedPrices = {
    flexDirection: 'row',
    alignItems: 'center',
};
export const recommendedMarketPrice = {
    fontSize: 14,
    color: 'gray',
    marginRight: 8,
};
export const recommendedDealPrice = {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'green',
};

export const emptyDealsTile = {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.colorBaseBeige,//'#f5f5f5',
    padding: 20,
};

export const emptyDealsText = {
    fontSize: 18,
    color: '#666',
    textAlign: 'center',
};

export const strikethrough = {
    textDecorationLine: 'line-through',
};

export const dealGraphTile = {
    backgroundColor: '#f5f5dc',
    height: 90,
    width: 90,
    marginBottom: 1,
    borderRadius: 10,
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 20,
    paddingBottom: 20,
    shadowColor: '#000',
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    alignItems: 'center',
    justifyContent: 'center',
};
export const dealTitle = {
    fontSize: 10,
    marginTop: 10,
    color: '#333',
    textShadowColor: '#fff',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 2,
};
export const dealDescription = {
    fontSize: 12,
    marginBottom: 10,
    color: Color.colorCoffeeBrown,
};
export const dealTileContainer = {
    width: "15%",
    height: "30%",
    alignItems: 'center',
    justifyContent: 'center',
};
export const dealPrice = {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#333',
};
export const pieChart = {
    height: "140%",
    width: "140%",
    marginBottom: "5%",
    marginTop: "10%",
};

export const chartLabels = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
};
export const chartLabel = {
    fontSize: 10,
    color: '#333',
};

export const LoginButtonContainer = {
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: {
        width: 0,
        height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 6,
};
export const LoginButtonGradient = {
    marginTop: '10%',
    marginLeft: '8%',
    marginRight: '8%',
    paddingVertical: '4%',
    paddingHorizontal: '10%',
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
};
export const LoginButtonText = {
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 'bold',
};

export const LoginSendOTP = {
    fontSize: 20, color: "#FFFFFF",
    paddingVertical: 16, fontWeight: 'bold',
    alignItems: 'center', justifyContent: 'center',
    paddingHorizontal: '35%',
    paddingLeft: "35%"
};
export const LoginGotoHome = {
    fontSize: 10, color: Color.colorMixGrey,
    paddingVertical: 16, fontWeight: 'bold',
    justifyContent: 'center',
    paddingLeft: "80%",
    paddingBottom: "8%"
};
export const RegisterButton = {
    fontSize: 20, color: Color.colorMixGrey,
    paddingVertical: 16, fontWeight: 'bold',
    alignItems: 'center', justifyContent: 'center',
    paddingHorizontal: '35%',
    paddingLeft: "35%"
};

export const LoginMobileInput = {
    flex: 1,
    marginTop: '15%',
    borderBottomColor: Color.colorWhite,
    borderBottomWidth: 2,
    paddingVertical: 5,
    marginLeft: '5%',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    fontSize: 16, fontWeight: 'bold',
    color: Color.colorWhite,
};
export const LoginMobileOTPInput = {
    flex: 1,
    marginTop: '15%',
    borderBottomColor: Color.colorWhite,
    borderBottomWidth: 2,
    paddingVertical: 5,
    marginLeft: '25%',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    fontSize: 16, fontWeight: 'bold',
    color: Color.colorWhite,
};
export const LoginInputCC = {
    width: '20%',
    marginTop: '15%',
    marginHorizontal: 5,
    borderBottomColor: Color.colorWhite,
    borderBottomWidth: 2,
    flexDirection: 'row',
    alignItems: 'center',
    height: 40,
    fontSize: 16, fontWeight: 'bold',
    color: Color.colorWhite,
};
export const LoginInputCCDigits = {
    width: 70, marginLeft: 1, color: Color.colorWhite,
}
export const LoginButton = {
    backgroundColor: '#CD853F',
    paddingVertical: 12,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    marginVertical: 32,
};


export const invitesListContainer = {
    marginTop: 10,
    marginBottom: 20,
    paddingHorizontal: 16,
    paddingVertical: 20,
};

export const invitesListContentContainer = {
    paddingBottom: 20,
};

export const toggleContainer = {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginVertical: 10,
};

export const invitesContainer = {
    marginHorizontal: 20,
    marginBottom: 20,
};

export const InvitesOuterContainer = {
    marginBottom: 10,
    width: "95%",
    backgroundColor: '#fff',
    borderRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
};

export const inviteTile = {
    padding: 10,
    paddingLeft: 10,
    paddingBottom: 15,
    backgroundColor: Color.colorLightestBeige,

};

export const inviteCardTitle = {
    fontSize: 18,
};

export const inviteListItem = {
    padding: 10,
    backgroundColor: '#fff',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
};

export const inviteCard = {
    marginVertical: 5,
};

export const ProfileDetailsContainer = {
    backgroundColor: 'rgba(75, 75, 75, 0.2)',
    borderRadius: 12,
    padding: 20,
    marginLeft: "2%",
    marginTop: "2%",
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 6,
    width: '96%',
    height: '10%'
};

export const container = {
    flex: 1,
    backgroundColor: '#fff',
};
export const invitesContainerMain = {
    flexGrow: 1,
    paddingHorizontal: 16,
    paddingVertical: 20,
};
export const backgroundImage = {
    flex: 1,
    resizeMode: 'cover',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
};
export const ProfileContainerDetails = {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    borderRadius: 25,
    paddingTop: "5%",
    marginLeft: "5%",
    marginTop: "5%",
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 6,
    width: '92%',
};
export const dashboardMini = {
    backgroundColor: 'rgba(224, 220, 206, 0.2)',
    height: "100%",
    width: "55%",
    position: "absolute",
    paddingLeft: "5%",
    borderRadius: 12,
};
export const profileImaging = {
    flex: 1,
    paddingLeft: "7%",
    paddingTop: "2%",
};
export const profileImage = {
    width: 150,
    height: 150,
    borderRadius: 60,
    borderWidth: 4,
    borderColor: '#fff',
    shadowColor: '#000',
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
};
export const editIcon = {
    position: 'absolute',
    marginTop: "75%",
    right: 10,
    borderRadius: 20,
    padding: 5,
    shadowColor: '#000',
    shadowOffset: {
        width: 0,
        height: 0.5,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 0,
    height: "5%",
};
export const welcomePicture = {
    marginTop: "45%",
    // alignItems: 'center',
};
export const welcomeProfilePicture = {
    color: Color.colorProfileGreyGreen,
    fontSize: 18,
    fontWeight: "600",
};
export const profileSections = {
    width: '90%',
    marginTop: 20,
};
export const sectionContainer = {
    marginBottom: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: 10,
    padding: 10,
    shadowColor: '#000',
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
};
export const proDetailsSectionContainer = {
    marginBottom: 10,
    height: '80%',
    width: '90%',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 10,
    marginLeft: '5%',
    shadowColor: '#000',
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
};
export const sectionHeader = {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
};
export const sectionIcon = {
    marginRight: 10,
    color: Color.colorMidBeige,
};
export const profileSectionTitle = {
    fontSize: 18,
    fontWeight: 'bold',
    color: Color.colorTabs,
};
export const sectionContent = {
    paddingLeft: 30,
};
export const sectionText = {
    fontSize: 16,
    color: "#41547d",
    marginBottom: 5,
};

export const profiles_container = {
    flex: 1,
    backgroundColor: Color.colorLightestBeige,
    paddingHorizontal: 20,
    paddingVertical: 10,
};
export const profile_lisiting_board = {
    padding: 20,
    borderRadius: 10,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginTop: "35%",
    width: "95%",
};
export const profiles_inputContainer = {
    backgroundColor: Color.colorWhite,
    borderRadius: 8,
    width: '100%'
};
export const profiles_input = {
    color: Color.colorDarkBrown,
};
export const profiles_buttonContainer = {
    flexDirection: 'row',
    justifyContent: 'space-between',
};
export const profiles_buttonsNRow = {
    flex: 1,
    marginHorizontal: 5,
};
export const profiles_button = {
    backgroundColor: Color.colorMidBeige,
};
export const profilesBGImage = {
    flex: 1,
    resizeMode: 'cover',
    opacity: 0.3
}
export const profileDetailsContainerDetails = {
    backgroundColor: 'rgba(71, 68, 58, 0.1)',
    borderRadius: 25,
    paddingTop: "5%",
    marginLeft: "5%",
    marginTop: "1%",
    // alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 6,
    width: '92%',
    height: "95%"
};

export const profileSectionContainer = {
    marginBottom: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    width: "100%"
};

export const policy_container = {
    flex: 1,
    backgroundColor: '#e0dcce', // colorLightBeige
};

export const policy_scrollContainer = {
    padding: 16,
};

export const policy_title = {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#342342', // colorDarkBlue
    marginBottom: 16,
    marginLeft: 20
};

export const policy_sectionTitle = {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#8a5307', // colorDarkBeige
    marginTop: 24,
    marginBottom: 8,
};
export const policy_paragraph = {
    fontSize: 16,
    color: '#756b49', // colorDarkBrown
    marginBottom: 8,
};
export const policy_agreeButton = {
    backgroundColor: '#8a5307', // colorDarkBeige
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderRadius: 8,
    marginTop: 24,
    alignSelf: 'center',
};
export const policy_agreedButton = {
    backgroundColor: '#8f8a79', // colorMidBeige
};
export const policy_agreeButtonText = {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#ffffff',
    textAlign: 'center',
};
export const imageTitle = {
    fontSize: 12,
    fontWeight: 'bold',
    color: Color.colorDarkGrey,
    marginTop: 10,
    textAlign: 'center',
};
export const successBanner = {
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginBottom: 20,
};
export const home_successMessage = {
    fontSize: 16,
    color: Color.colorPurple,
    textAlign: 'center',
};
export const helpIcon = {
    marginRight: "1%",
    color: Color.colorMidBeige
};
export const inputDescContainer = {
    flexDirection: 'row',
    alignItems: 'center',
    width: '95%',
    marginLeft: '2%',
    marginRight: '2%',
    marginBottom: "8%",
};
export const inputDescription = {
    flex: 1,
    minHeight: 150,
    borderColor: Color.colorDarkBeige,
    borderWidth: 1,
    marginBottom: 16,
    marginLeft: "5%",
    paddingLeft: 8,
    borderRadius: 8,
    letterSpacing: 0.8,
    lineHeight: 18,
    textAlign: "left",
    backgroundColor: "#fff",
    color: "#0209B1",
};
export const moreDetailsLink = {
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
};

export const moreDetailsText = {
    color: Color.colorDarkBrown,
    fontSize: 16,
    textDecorationLine: 'underline',
};

export const myWishSameRowContainer = {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 10,
    paddingHorizontal: 20,
};
export const myWishInputContainer = {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Color.colorWhite,
    borderRadius: 10,
    paddingHorizontal: 10,
    width: 80,
    flex: 1,
};
export const myWishInput = {
    flex: 1,
    height: 40,
    color: Color.colorDarkGrey,
    fontSize: 16,
};
export const myWishErrorBanner = {
    backgroundColor: Color.colorLightestRed,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 8,
    marginBottom: 10,
};
export const home_errorMessage = {
    color: Color.colorRed,
    fontSize: 14,
};
export const wishItem = {
    backgroundColor: Color.colorCoolBeige,
    padding: 16,
    borderRadius: 8,
    marginVertical: 8,
    marginHorizontal: 16,
    elevation: 2,
    shadowColor: Color.colorDarkGrey,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
};
export const wishItemHeader = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
};
export const wishItemTitle = {
    fontSize: 18,
    fontWeight: 'bold',
    color: Color.colorCoffeeBrown,
};
export const wishItemStatus = {
    flexDirection: 'row',
    alignItems: 'center',
};
export const trendText = {
    fontSize: 14,
    color: Color.colorGray,
    marginLeft: 8,
};
export const wishItemText = {
    fontSize: 14,
    color: Color.colorDarkGrey,
    marginVertical: 2,
};
export const myWishContainer = {
    flex: 1,
    backgroundColor: Color.colorBaseBeige,
};
export const wishItemDetailsContainer = {
    flexDirection: 'row',
    alignItems: 'center',
};
export const removeButton = {
    backgroundColor: Color.colorRed,
    color: Color.colorWhite,
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 4,
    fontSize: 14,
};

export const wishItemImage = {
    width: 80,
    height: 80,
    borderRadius: 8,
    marginRight: 12,
};

export const wishItemDetailsText = {
    flex: 1,
};

export const wishItemProductText = {
    fontSize: 14,
    color: '#9CA3AF',
    marginVertical: 2,
};

export const wishItemBrandText = {
    fontSize: 14,
    color: '#9CA3AF',
    marginVertical: 2,
};
export const wishItemSellerText = {
    fontSize: 14,
    color: '#9CA3AF',
    marginVertical: 2,
};

export const wishItemManufacturerText = {
    fontSize: 14,
    color: '#9CA3AF',
    marginVertical: 2,
};

export const wishItemAddedDateText = {
    fontSize: 14,
    color: '#9CA3AF',
    marginVertical: 2,
};

export const wishItemCommentsText = {
    fontSize: 14,
    color: '#9CA3AF',
    marginVertical: 2,
};

export const myWishNoResultsText = {
    fontSize: 20,
    color: Color.colorWhite,
    textAlign: 'center',
    marginTop: 20,
    position: 'relative',
    zIndex: 1,
};
export const removeReasonContainer = {
    position: 'absolute',
    top: "20%",
    left: 0,
    right: 0,
    zIndex: 2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 8,
    width: "90%",
    marginLeft: "15%",
};
export const dropdownContainer = {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    zIndex: 2,
    borderRadius: 8,
    height: 40,
    marginBottom: 15,
    backgroundColor: Color.colorLightBeige,
}
export const dropdownStyle = {
    backgroundColor: Color.colorBaseBeige,
    borderRadius: 8,
    overflow: 'scroll',
};
export const dropdownInput = {
    backgroundColor: Color.colorLightestBeige,
    borderRadius: 8,
    paddingHorizontal: 12,
    paddingVertical: 12,
};
export const dropdownLabel = {
    color: Color.colorDarkGrey,
    fontSize: 16,
};
export const dropdownItem = {
    paddingVertical: 12,
    paddingHorizontal: 16,
};
export const selectedItemContainer = {
    backgroundColor: Color.colorLightBeige,
};
export const selectedItemLabel = {
    color: Color.colorDarkGrey,
};
export const TrendsContainer = {
    flex: 1,
    backgroundColor: Color.colorLightestBeige,
};

export const topTrendSection = {
    backgroundColor: Color.colorMidBeige,
    padding: 16,
};

export const topTrendSectionBg = {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
};

export const topTrendSectionTitle = {
    fontSize: 20,
    fontWeight: 'bold',
    color: Color.colorDarkBrown,
    marginBottom: 16,
    marginLeft: "35%",
};

export const topTrendsContainer = {
    paddingVertical: 8,
};

export const topTrendItem = {
    backgroundColor: 'rgba(128, 105, 116, 0.8)',
    padding: 16,
    borderRadius: 12,
    margonLeft: 5,
    marginRight: 16,
    justifyContent: 'center',
    alignItems: 'center',
    width: 120,
};

export const topTrendContent = {
    alignItems: 'center',
};

export const topTrendTitle = {
    fontSize: 16,
    color: Color.colorDarkBlue,
    textAlign: 'center',
};

export const topTrendCount = {
    fontSize: 18,
    fontWeight: 'bold',
    color: "#0a1741",
};

export const TrendsHeader = {
    backgroundColor: Color.colorLightestBeige,
    padding: 24,
    paddingBottom: 16,
};

export const TrendsTitle = {
    fontSize: 24,
    fontWeight: 'bold',
    color: Color.colorDarkGrey,
    marginBottom: 16,
};

export const TrendSearchContainer = {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
};

export const TrendSearchInput = {
    flex: 1,
    backgroundColor: Color.colorBackground,
    borderRadius: 12,
    paddingVertical: 12,
    paddingHorizontal: 16,
    marginRight: 16,
    color: Color.colorRoyalBlueText,
    fontSize: 16,
};
export const TrendSortButton = {
    paddingVertical: 12,
    paddingHorizontal: 16,
    marginLeft: 8,
    backgroundColor: Color.colorBackground,
    borderRadius: 12,
};
export const TrendSelectedSortButton = {
    backgroundColor: Color.colorBaseBeige,
};
export const TrendSortButtonText = {
    color: Color.colorDarkBrown,
    fontSize: 16,
};
export const trendsList = {
    flex: 1,
    paddingHorizontal: 16,
};
export const trendsListContainer = {
    paddingVertical: 16,
};
export const trendItem = {
    backgroundColor: "#fff",
    padding: 16,
    borderRadius: 12,
    marginBottom: 16,
    shadowColor: '#000',
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
};
export const trendHeader = {
    alignItems: 'flex-start',
};
export const trendName = {
    fontSize: 18,
    color: Color.colorDarkBlue,
    marginBottom: 8,
};
export const trendDetails = {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: 8,
};
export const trendDetailLabel = {
    fontSize: 14,
    color: Color.colorDarkGrey,
    marginRight: 8,
};
export const trendDetailText = {
    fontSize: 14,
    color: "#0a1741",
    marginRight: 8,
};
export const trendGraph = {
    alignItems: 'center',
    marginTop: 16,
};
export const expandedTrendGraph = {
    backgroundColor: Color.colorCoolBeige,
    padding: 16,
    borderRadius: 12,
};
export const TrendLocationSection = {
    marginTop: 16,
};
export const TrendSectionTitle = {
    fontSize: 16,
    fontWeight: 'bold',
    color: Color.colorRoyalBlueText,
    marginBottom: 8,
};
export const TrendLocationItem = {
    backgroundColor: Color.colorCoolBeige,
    padding: 16,
    borderRadius: 8,
    marginBottom: 8,
    marginTop: 8,
};
export const expandedTrendLocationItem = {
    backgroundColor: Color.colorBackground,
};
export const TrendLocationHeader = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
};
export const TrendLocationName = {
    fontSize: 12,
    fontWeight: 'bold',
    color: Color.colorDarkGrey,
};
export const TrendLocationCount = {
    fontSize: 14,
    color: "#990000",
};
export const TrendLocationDetails = {
    alignItems: 'center',
};
export const trendComments = {
    marginTop: 16,
};
export const trendCommentText = {
    fontSize: 14,
    color: Color.colorDarkGrey,
};
export const trendGraphLabel = {
    fontSize: 14,
    color: Color.colorRoyalBlueText,
    marginTop: 8
};
export const trendIcon = {
    marginRight: 10,
    color: Color.colorVibrantGreen
};
export const trendIcon1 = {
    marginRight: 10,
    color: Color.colorVibrantBlue,
};
export const chart_container = {
    marginVertical: 16,
};
export const chart_listContainer = {
    marginBottom: 16,
};
export const chart_dealItem = {
    paddingHorizontal: 12,
    paddingVertical: 8,
    marginRight: 8,
    borderRadius: 8,
};
export const chart_selectedDeal = {
    borderWidth: 2,
    borderColor: '#fff',
};
export const chart_dealTitle = {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#fff',
};
export const chart_selectedDealTitle = {
    color: '#333',
};
export const chartContainer = {
    alignItems: 'center',
};
export const chart_detailsContainer = {
    flexDirection: 'row',
    alignItems: 'left',
    marginTop: 16,
};
export const chart_productImage = {
    width: 80,
    height: 80,
    borderRadius: 8,
    marginRight: 16,
};
export const chart_dealDetails = {
    flex: 1,
};
export const chart_dealInfo = {
    fontSize: 14,
    color: '#666',
    marginBottom: 4,
};
export const chart_dealDescription = {
    fontSize: 14,
    color: '#333',
    marginTop: 8,
};
export const notificationContainer = {
    width: "90%",
    marginLeft: "5%",
    alignItem: "center",
    marginTop: "2%",
};
export const notificationItem = {
    backgroundColor: Color.colorWhite,
    padding: 16,
    borderRadius: 8,
    marginVertical: 8,
    shadowColor: Color.colorBlack,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
    marginBottom: "1%", 
};

export const unreadNotification = {
    backgroundColor: Color.colorBaseBeige,
};

export const notificationHeader = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
};

export const notificationTitle = {
    fontSize: 16,
    fontWeight: 'bold',
    color: Color.colorPurple,
};

export const notificationActions = {
    flexDirection: 'row',
    alignItems: 'center',
};

export const notificationAction = {
    color: Color.colorWhite,
};

export const notificationsIcon = {
    marginRight: 2,
};
export const notificationsBadge = {
    borderRadius: 10,
    marginHorizontal: 10,
    marginVertical: 10,
};
export const notificationsBadgeText = {
    color: Color.colorDarkGrey,
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: -20,
    paddingLeft: -5,
};

export const notificationMessage = {
    fontSize: 14,
    color: Color.colorDarkBlue,
};

export const readNotification = {
    backgroundColor: Color.colorLightestBeige,
}

export const readNotificationTitle = {
    color: Color.colorDarkGrey,
}