import React from 'react';
import * as style from "../../../GlobalStyles";

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '32px',
  borderRadius: '8px',
  backgroundColor: '#ffffff',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  minHeight: '200px',
  width: '100%',
  maxWidth: '42rem',
  margin: '1rem auto'
};

const contentStyle = {
  textAlign: 'center'
};

const titleStyle = {
  fontSize: '1.25rem',
  fontWeight: '600',
  color: style.Color.colorDarkBlue,
  marginBottom: '0.75rem'
};

const messageStyle = {
  fontSize: '1rem',
  color: '#4B5563'
};

const EmptyStateMessage = () => { 
  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <h3 style={titleStyle}>
          No Wishes Found
        </h3>
        <p style={messageStyle}>
          Set your preferred categories from the Left panel to stay updated on the ongoing deals
        </p>
      </div>
    </div>
  );
};

export default EmptyStateMessage;